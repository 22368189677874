export default {
    // title 
    titleMarketUpdates: '市場動態',
    titleTrendingCompanies: '熱門公司',
    titleInvite: "邀請並獲得獎勵",
    titleOnboarding: "入門工具",


    // market
    tabNews: '新聞',
    tabJobPostings: '職位發佈',
    marketSort: "排序",
    marketFilter: "過濾",
    marketReset: '重置',
    marketFilterGalleries: '領域',
    marketFilterCategories: '分類',
    recent: "近期",
    earlier: "更早",
    minuteAgo: "{count} 分鐘之前",
    hourAgo: "{count} 小時之前",
    dayAgo: "{count} 天之前",
    weekAgo: "{count} 周之前",
    on: '來自',


    // trending companies

    // invite
    forReferrals: "推薦人：",
    inviteBtn: '邀請',
    copyLink: '複製連結',
    shareYourReferralLink: '分享您的推薦連結或輸入電子郵件邀請他人。 加入即可獲得獎勵！',
    forTeamInvites: "對於團隊邀請（獲得積分）：",
    assignTeamInvites: "指定團隊邀請",
    lookingToGrowYourTeam: "想發展你的團隊嗎？ 每次有人加入時，分配邀請並開始賺取積分！",
    addMultipleAddresses: "添加多個用逗號分隔的地址",
    greatProgress: "進步很大！ 繼續邀請解鎖全部{count}積分。",
    startInviting: '開始邀請並獲得高達 {count} 個積分！',
    allSet: "準備就緒！ 您已獲得 {count} 積分，感謝您的邀請！",

    // custom insight
    addYourOwnCustomInsights: "添加您自己的自定義見解",
    companyKeyMilestones: "公司的關鍵里程碑",
    listMajorCompetitors: "列出同行業的主要競爭對手",
    analyzeMarketPresence: "分析公司的市場佔有率",
    poweredByAi: "由Ai提供技術支援",
    grantedUponCompletion: '{count} 積分將在完成後獲得',

};