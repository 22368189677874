<template>
  <el-popover
    placement="right"
    trigger="click"
    popper-class="wallet-popper-sty"
    v-model="visible"
  >
    <div class="credit-wrapper">
      <div class="topup-credit">
        <div>
          <span class="name">Top-up Credit: </span
          >{{ account.topup_credit.toLocaleString() }}
          <i class="el-icon-loading" v-if="loading" />
        </div>
        <div class="auto-topup">
          <span>Auto top-up: {{account.is_auto_top_up_credits_enabled ? 'Enabled': 'Disabled'}}</span>
          <span class="edit" @click="topupEnable">
            Edit <i class="el-icon-arrow-right"></i>
          </span>
        </div>
      </div>
      <div class="credit-item">
        <span class="name"
          >Credit Usage <i class="el-icon-loading" v-if="loading"
        /></span>
        <span
          >{{
            (
              subscription.credit_refill - Number(account.credit)
            ).toLocaleString()
          }}/{{ subscription.credit_refill.toLocaleString() }}</span
        >
      </div>
      <el-progress
        :stroke-width="8"
        :percentage="creditUsagePercent"
        color="#2F5FF2"
        define-back-color="#F4F5F8"
      />
      <div class="last-calculated">
        Last calculated: {{ moment().utc().local().format("YYYY/MM/DD hh:mm:ss") }}
      </div>
    </div>
    <div class="icon-wrapper" slot="reference">
      <svg-icon icon-class="nav-wallet" class="nav-wallet"/>
      <span v-if="navCollapse" class="nav-name">{{ $t('navAccountCredit') }}</span>
    </div>
  </el-popover>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from "moment";

export default {
  computed: {
    ...mapState("account", ["account"]),
    ...mapState('user', ['currentId']),
    ...mapState('base', ['navCollapse', 'selectedNav']),
    creditUsagePercent() {
      return this.subscription
        ? (
            (this.subscription.credit_refill - Number(this.account.credit)) /
            this.subscription.credit_refill
          ).toFixed(2) * 100
        : 0;
    },
    subscription() {
      return this.account.subscription;
    },
  },
  data() {
    return {
      loading: false,
      visible: false,
    }
  },
  watch: {
    visible(val) {
      val && this.visibleChange();
    }
  },
  methods: {
    moment,
    ...mapActions("account", ["getProfile"]),
    async visibleChange() {
      try {
        this.loading = true;
        await this.getProfile({
          profile_id: this.currentId.profile_id,
          dontGetCommonData: true,
        });
      } catch (err) {
      } finally {
        this.loading = false;
      }
    },
    topupEnable() {
      if (this.$route.name === "currentUsage") {
        const scrollDom = document.getElementById("topup");
        scrollDom.scrollIntoView({ block: "start", behavior: "smooth" });
      } else {
        this.$router.push({ path: "/settings/current-usage?#topup" });
      }
      this.visible = false;
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-progress__text {
  display: none;
}
.icon-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  .nav-name {
    margin-left: 8px;
    color: $color-black-5;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.24px;
  }
  .svg-icon {
    width: 16px;
    height: 18px;
    stroke: $color-black-5;
  }
  &:hover {
    .nav-name {
      color: $color-blue-6;
    }
    .svg-icon {
      width: 16px;
      height: 18px;
      stroke: $color-blue-6;
    }
  }
}
.credit-wrapper {
  padding: 16px;
  color: $color-black-2;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.42px;
  .topup-credit {
    .name {
      font-weight: 600;
    }
    .auto-topup {
      margin-top: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $color-gray-1;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      .edit {
        color: $color-black-2;
        font-weight: 500;
        cursor: pointer;
        i {
          color: $color-black-2;
        }
      }
    }
  }
  .credit-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    margin: 16px 0 8px;
    .name {
      font-weight: 600;
    }
  }
  ::v-deep .el-progress {
    line-height: 0;
  }
  .last-calculated {
    margin-top: 16px;
    color: $color-gray-1;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
  }
}
</style>

<style lang="scss">
.wallet-popper-sty {
  padding: 0;
  width: 360px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(38, 54, 153, 0.04);
  &.ml-100 {
    margin-left: 100px;
  }
}
</style>
