import * as people from "@/api/people";

const state = {
  selected: {
    redeem_queries: [],
    search_term: '',
    people_groups: [],
    people_name: '',
    people_titles: [],
    departments: [],
    department_functions: [],
    management_levels: [],
    employees: [],
    verticals: [],
    vertical_categories: [],
    people_locations: [],
    company_locations: [],
    companies: [],
    technologies: [],
    categories: [],
    page: 1,
    per_page: parseInt(process.env.VUE_APP_PER_PAGE),
  },
  checkedList: [],
  isLoading: false, // table search loading,
  isShow: true, //控制filter是否隐藏
  lists: [], // 原始搜索结果
  total: {},
  peopleExportFields: [],
  peopleSelectedModule: '',
  multipleSelectedListIds: [], // multiple select ids
  savedList: {},
}
const mutations = {
  /*----------------已确认过代码部分开始---------*/
  setPeopleSelectedModule(state, payload) {
    state.peopleSelectedModule = payload
  },
  setCollapse(state) {
    state.isShow = !state.isShow
  },
  setLoading(state, val) {
    state.isLoading = val
  },
  setCheckedList(state, payload) {
    state.checkedList = payload;
  },
  setSavedList(state, payload) {
    state.savedList = payload;
  },
  setMultiPeopleAddSavedList(state, addedGroupInfo) {
    const ids = state.checkedList.map((item) => item.people_search_id);
    state.lists = state.lists.map((item) => {
      if (ids.includes(item.people_search_id)) {
        if (item.saved_lists) {
          const groupIds = item.saved_lists.map((group) => group.group_id);
          return groupIds.includes(addedGroupInfo.group_id) ? item : { 
            ...item,
            saved_lists: [...item.saved_lists, addedGroupInfo],
          }
        } else {
          return {
            ...item,
            saved_lists: [addedGroupInfo]
          }
        }
      } else {
        return item;
      }
    })
  },
  setPeopleRowAddSavedList(state, payload) {
    const { people_search_id, group } = payload;
    state.lists = state.lists.map((item) => {
      if (item.people_search_id === people_search_id) {
        if (item.saved_lists) {
          const groupIds = item.saved_lists.map((groupItem) => groupItem.group_id);
          return groupIds.includes(group.group_id) ? item : { 
            ...item,
            saved_lists: [...item.saved_lists, group],
          }
        } else {
          return {
            ...item,
            saved_lists: [group],
          }
        }
      } else {
        return item;
      };
    })
                //     row.saved_lists?.map((item) => {
                //     if (isAdd) {
                //         if (!savedData[item.group_id] || !savedData[item.group_id].members) {
                //             savedData[item.group_id] = {
                //                 name: item.name,
                //                 members: new Set(),
                //                 groupId: item.group_id
                //             }
                //         };
                //         savedData[item.group_id].members.add(row[keyName]);
                //     } else {
                //         savedData[item.group_id].members.has(row[keyName]) && savedData[item.group_id].members.delete(row[keyName]);
                //         if (savedData[item.group_id].members.size === 0) {
                //             delete savedData[item.group_id];
                //         }
                //     }
                // })
  },
  setMultiPeopleDeleteSavedList(state, groupInfo) {
    const savedList = structuredClone(state.savedList);
    delete savedList[groupInfo.groupId];
    state.savedList = savedList;
    const ids = state.checkedList.map((item) => item.people_search_id);
    state.lists = state.lists.map((item) => {
      return ids.includes(item.people_search_id) ? {
        ...item,
        saved_lists: item.saved_lists.filter((groupItem) => groupItem.group_id !== groupInfo.groupId),
      } : item;
    });
  },
  setPeopleRowDeleteSavedList(state, payload) {
    const { people_search_id, group } = payload;
    state.lists = state.lists.map((item) => {
      if (item.people_search_id === people_search_id) {
        return {
          ...item,
          saved_lists: item.saved_lists.filter((groupItem) => groupItem.group_id !== group.group_id),
        }
      } else {
        return item;
      }
    })
  },
  // 设置搜索结果
  setSearchPeopleList(state, payload) {
    if (payload.data) {
      payload.data.peoples && (state.lists = payload.data.peoples?.map((item) => ({
        ...item,
        loading: false,
        isAddOpen: false,
        isSaveOpen: false,
        isContactOpen: false,
        isPhoneOpen: false,
      })));
      payload.data.pagination && (state.total = payload.data.pagination)
    }
  },
  setPeopleExportFields(state, payload) {
    state.peopleExportFields = payload;
  },
  setMultipleSelectedListIds(state, payload) {
    state.multipleSelectedListIds = payload;
  },
  /*----------------已确认过代码部分结束---------*/

  /*-------------综合搜索----------------*/
  setSelected(state, val) {
    if (val.recall) {
      val = val.info;
    } 
    const { notReset, ...rest} = val;
    let temp = {
      profile_id: this.state.user.currentId.profile_id,
      ...state.selected,
      ...rest,
      page: val.notReset ? val.page: 1
    }
    state.selected = temp
  },
}
const actions = {
  /*-------------综合搜索----------------*/
  async searchPeopleList(context, val) {
    try {
      context.commit('setLoading', true);
      const result = await people.searchPeopleList(val)
      context.commit('setLoading', false);
      if (result && result.data) {
        context.commit('setSearchPeopleList', result);
        localStorage.getItem('CbApiInfo2') && localStorage.removeItem('CbApiInfo2')
        return result?.data
      }
    } catch (error) {
      // 422
      // 416
      context.commit('setLoading', false);
    }
  },
  /*-------------人员表格请求Email地址----------------*/
  async getEmail(context, val) {
    try {
      const result = await people.getEmail({ ...val, profile_id: this.state.user.currentId.profile_id })
      return result?.data
    } catch (err) {
      console.log(err)
      throw err
    }
  },
  /*-------------delete item from group----------------*/
  async deletePeople(context, val) {
    try {
      const result = await people.deletePeople({ ...val, profile_id: this.state.user.currentId.profile_id })
      return result
    } catch (err) {
      console.log(err)
      throw err
    }
  },
  /*-----根据groups返回domain ID------*/
  async getDomainIdByGroup(context, data) {
    try {
      const result = await people.getDomainIdByGroup({ ...data, profile_id: this.state.user.currentId.profile_id })
      return result?.data
    } catch (err) {
      console.log(err)
      throw err
    }
  },
  /*---------------export------------------*/
  async export(context, data) {
    try {
      const result = await people.exportCompany({ ...data, profile_id: this.state.user.currentId.profile_id })
      return result
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
