export default {
  serviceMenuOverview: "概覽",
  serviceMenuPipeline: "管道",
  serviceMenuAnalytics: "分析",
  serviceMenuResources: "資源",
  salesServices: "企業服務",
  chooseYourTypeofServicesToStreamlineAndEnhanceYourBusinessOperations:
    "選擇您的服務類型，以簡化和增強您的業務運營",
  dataPreparation: "數據處理",
  salesAssistant: "銷售助手",
  appointmentSetting: "預約設置",
  othersCustomService: "其他（自定義服務）",
  requestService: "請求服務",
  learnMore: "了解更多",
  serviceRequestStatus: "服務請求狀態",
  noServiceRequestsYet: "尚無服務請求",
  submitYourFirstRequestToUnlockThePower:
    "提交您的第一次請求，以解鎖我們銷售服務的力量！在此跟蹤您的請求狀態，提升您的銷售工作。讓我們開始吧！",
  serviceTableItemName: "名稱",
  serviceTableItemStatus: "狀態",
  serviceTableItemType: "類型",
  serviceTableItemProgress: "進度",
  serviceTableItemStart: "開始時間",
  serviceTableItemEnd: "預計結束時間",
  serviceTableItemPriority: "優先級",
  addASubject: "添加主題",
  created: "已創建",
  closed: "已關閉",
  paused: "已暫停",
  working: "工作中",
  completed: "已完成",
  resuming: "恢復中",
  reviewing: "審核中",
  others: "其他",
  data_preparation: "數據處理",
  sales_assistant: "銷售助手",
  appointment_setting: "預約設置",
  appointment_booking: "預約預訂",
  low: "低",
  normal: "正常",
  urgent: "緊急",
  high: "高",
  requiredCredits: "所需信用點數",
  serviceCreated: "已創建",
  QandARecord: "問答記錄",
  serviceAttachments: "附件",
  discussionBoard: "討論板",
  wannaAddSomething: "想添加一些東西嗎？",
  serviceEdit: "編輯",
  serviceDelete: "刪除",
  addDescription: "添加描述",
  DragDropor: "拖放或",
  chooseFile: "選擇文件",
  toUpload: "上傳",
  ourDataPreparationService:
    "我們的數據處理服務提供全面的解決方案，旨在優化您的銷售數據管理。我們專注於簡化流程，使您能夠增強現有數據集，通過數據清理確保準確性，並創建完全符合您需求的自定義列表。這種方法使您獲得靈活性和效率，以有效管理您的數據。",
  moreoverWithOurDedicatedDataManagement:
    "此外，通過我們專門的數據管理服務Pubrio，過程變得更加輕鬆。您可以無縫地附加文件並增強您的數據集，添加額外信息。Pubrio不僅提供增強功能，還可以根據您的具體標準創建全新的列表。這項個性化服務確保您的數據集精確符合您的目標和行業標準，讓您掌控自己的數據環境。",
  flexibilityToAttach:
    "附加現有列表的靈活性：或指示Pubrio根據指定標準查找新列表。",
  comprehensiveDataEnrichment:
    "全面的數據增強：添加缺失的詳細信息，增強數據集的完整性。",
  dataCleansingServicesToEnsureAccuracy:
    "數據清理服務以確保準確性：包括刪除重複項和錯誤修正，以確保準確性和質量。",
  abilityToInstructPubrio:
    "指示Pubrio的能力：根據精心定義的標準策劃全新的列表。",
  tailoringDatasetsPrecisely: "精確調整數據集：使其與特定目標和行業標準對齊。",
  dataEnrichmentWithExistingList:
    "使用現有列表進行數據增強：輕鬆通過將當前列表上傳到Pubrio來增強您的列表。我們將填補缺失的詳細信息，例如電子郵件地址和電話號碼，以滿足您的要求。例如，提供您的客戶數據庫，我們將用必要的信息更新它。",
  customListGenerationIf:
    "自定義列表生成：如果您沒有列表，讓Pubrio為您查找一個。只需告訴我們您的標準，如職位或行業，我們將創建完美的列表。例如，如果您需要特定區域內頂級技術高管的名單，我們將為您處理。",
  dataCleansingForExistingLists:
    "現有列表的數據清理：通過Pubrio的數據清理服務確保您的數據準確可靠。將您的列表發送給我們，我們將刪除重複項並修正任何錯誤。例如，如果您有一份最近潛在客戶的名單，我們將確保它為您的營銷活動做好準備。",
  empoweringYourSalesEeavors:
    "通過我們的銷售助手服務，賦能您的銷售努力，我們提供全面支持，涵蓋多個關鍵領域，以確保您的外展和溝通策略精心調整以取得成功。無論您是想創作引人入勝的內容、無縫組織活動、開發量身定制的演示文稿，還是完善銷售和溝通策略，我們專門的團隊都在這裡幫助您提升銷售工作並提高品牌在市場上的存在感。",
  craftingCompellingContent:
    "創作引人入勝的內容：有效的溝通是吸引受眾注意力的關鍵。我們的團隊擅長創作針對您的品牌和受眾偏好的引人入勝內容。從引人深思的LinkedIn帖子到信息豐富的博客文章和引人注目的社交媒體更新，我們確保您的信息與目標市場共鳴。",
  seamlessEventAndWebinarPreparation:
    "無縫的活動和網絡研討會準備：活動和網絡研討會是與受眾互動和生成潛在客戶的強大工具。我們的銷售助手服務處理所有細節，從計劃和推廣到執行。無論是舉辦信息豐富的網絡研討會還是組織網絡活動，我們都確保您的活動留下深刻印象。",
  tailoredContentDevelopmentAndPresentation:
    "量身定制內容開發和演示：用引人入勝的演示和個性化的信息吸引受眾。我們的團隊專門創建視覺吸引人的演示文稿並製作針對特定受眾細分市場的信息。無論您是在向投資者推介還是培養潛在客戶，我們都確保您的內容脫穎而出。",
  strategicSalesAndCommunicationFrameworks:
    "戰略銷售和溝通框架：通過戰略指導和有效的溝通腳本簡化銷售流程。我們的銷售助手服務為您的團隊提供推動轉換和促進有意義聯繫所需的工具和策略。",
  craftingCompellingContent: "創作引人入勝的內容",
  companyLinkedInPosts: "公司 LinkedIn 貼文（頻率：每週或每月）",
  personalLinkedInPosts:
    "個人 LinkedIn 貼文（頻率：靈活，根據偏好和內容可用性）",
  websiteBlogPosts: "網站部落格文章（頻率：通常每週或每兩週）",
  socialMediaPosts: "社交媒體貼文（頻率：每日或根據內容策略）",
  emailMarketingCampaigns: "電子郵件行銷活動（頻率：每週或每兩週）",
  seamlessEventAndWebinarPreparation: "無縫的活動和網路研討會準備",
  webinarAndWorkshopPreparation: "網路研討會和工作坊準備",
  networkingAndOfflineEvent: "網絡和線下活動協調",
  tailoredContentDevelopment: "量身定制的內容開發和呈現",
  companyDeckPreparation: "公司簡報準備（用於演示、推介等）",
  highlyPersonalizedMessage: "高度個性化的訊息創建（針對特定受眾群體量身定制）",
  strategicSales: "戰略銷售和溝通框架",
  salesCallScripts: "銷售電話腳本",
  emailOutreachTemplate: "電子郵件外展模板",
  salesChannelStrategies: "銷售渠道策略",
  ourAppointmentSettingService:
    "我們的約會安排服務旨在提升您的業務，促進關鍵聯繫，增強行業曝光，並培養有價值的潛在客戶。我們專注於個性化的行業曝光、無縫的研討會和網路研討會協調、熱絡新潛在客戶，以及對新受眾的吸引性接觸。",
  individualIndustryAppointment: "個別行業約會曝光和反應",
  tailoredOutreach:
    "量身定制的外展策略針對行業內的關鍵決策者和影響者，確保您的品牌獲得精準曝光。",
  strategicFollowups:
    "戰略性跟進幫助我們評估潛在客戶的反應、解決他們的疑慮，並促進更深入的互動。",
  eachInteraction:
    "每次互動都根據收件人的特徵和偏好進行個性化設計，以最大化影響力。",
  seminarWebinarCoordination: "研討會與網絡研討會協調",
  ourExpertTeamHandles:
    "我們的專業團隊負責所有研討會和網絡研討會的協調工作，從最初的規劃到活動結束後的跟進。",
  weMeticulouslyPlan:
    "我們精心策劃和執行每一場活動，展示您的專業知識，確保參與者獲得流暢而引人入勝的體驗。",
  byLeveragingAdvancedTechnologyPlatforms:
    "通過利用先進的技術平台，我們增強互動性和參與度，最大化活動的影響力。",
  newProspectWarmUp: "新潛在客戶預熱",
  proactiveEngagementStrategies:
    "採用主動參與策略來熱身新潛在客戶，為成功的商業對話鋪平道路。",
  tailoredCommunicationStrategies:
    "部署量身定制的溝通策略，以建立良好的關係、信任感和對您產品的興趣。",
  throughStrategicNurturing:
    "通過戰略性培養，我們保持潛在客戶的參與度，為轉換做好準備，提高成功約會的機會。",
  newAudienceApproach: "新受眾接觸策略",
  weEmployInnovativeStrategies:
    "我們採用創新策略來接觸新受眾，擴大您的業務範圍，超越傳統渠道。",
  targetedOutreachCampaigns:
    "根據深入的受眾研究和細分制定針對性的外展活動，以確保相關性和效果。",
  ourApproachIsContinuouslyOptimized:
    "我們的方法根據反饋和見解不斷優化，確保我們在接觸和吸引新受眾方面始終走在前列。",
  individualIndustry: "個別行業會議預約及跟進",
  personalizedAppointmentSetting:
    "與行業利益相關者進行個性化約會安排，以促進商機。",
  strategicFollowupCommunication:
    "進行戰略性跟進溝通以促進進一步參與，增強轉換率。",
  comprehensivePlanning: "全面規劃和執行活動，以確保每個細節都能達成預期效果。",
  utilizationOfAdvancedTechnologies: "利用先進技術增強互動性。",
  newProspectWarmUp: "新潛在客戶預熱",
  proactiveEngagementStrategy: "針對潛在客戶的主動參與策略。",
  customizedCommunication: "量身定制的溝通以保持興趣。",
  newAudienceApproach: "新受眾接觸策略",
  innovativeOutreach: "為新受眾設計的創新外展活動。",
  continuousRefinement: "根據反饋和見解不斷完善。",
  customService: "定制服務",
  tailoredToMeet:
    "我們的定制服務旨在滿足您的獨特需求，提供靈活性和適應性，以應對各種要求。無論您需要協助進行網絡研討會參與者追蹤、線下活動推廣、廣告增強或其他專業任務，我們的專業團隊都能高效且有效地幫助您實現目標。",
  webinarParticipantsTracking: "網絡研討會參與者追蹤",
  comprehensiveTrackingOfWebinar:
    "全面追蹤網絡研討會參與者，以分析參與程度並收集有價值的見解。",
  customizedReportsAndAnalytics:
    "量身定制的報告和分析，以評估網絡研討會的有效性並優化未來策略。",
  seamlessIntegration: "與現有系統無縫集成，以簡化數據管理和報告。",
  offlineEventPromotion: "線下活動推廣",
  strategicPlanning: "戰略規劃和執行線下活動推廣活動，以最大化出席率和參與度。",
  utilizationOfTargetedMarketingChannels:
    "利用針對性的營銷渠道和推廣策略，有效接觸目標受眾。",
  creativeApproaches:
    "根據每個活動的獨特特徵量身定制創意方法，確保最大影響力和投資回報率。",
  adsEnhancement: "廣告增強",
  optimizationOfAdCampaigns:
    "優化各平台上的廣告活動，以提高可見性、參與度和轉換率。",
  ABTesting: "A/B測試和性能分析，以識別最有效的廣告創意、信息傳遞和目標策略。",
  continuousMonitoring:
    "持續監控和調整廣告活動，以適應市場動態變化並最大化結果。",
  customizedSolutions: "定制解決方案",
  ourCustomService:
    "我們的定制服務超越預定義功能，旨在滿足您的具體需求和偏好。",
  whetherYouNeedAssistance:
    "無論您需要數據分析、內容創建、戰略規劃或業務其他方面的協助，我們都能根據您的需求量身定制服務。",
  ourFlexibleApproach:
    "我們靈活的方法使我們能夠與您密切合作，開發定制解決方案，實現可見成果並推動業務增長。",
  setupAndImplementation: "網絡研討會參與者追蹤系統的設置和實施。",
  customizedReporting:
    "量身定制的報告和分析，以衡量網絡研討會的表現和觀眾參與度。",
  developmentAndExecution: "針對性的線下活動推廣活動的開發和執行。",
  coordinationOfPromotionalActivities:
    "推廣活動的協調，包括印刷材料、直郵和地方廣告。",
  evaluationAndOptimization: "對數字平台上的廣告活動進行評估和優化。",
  creativeDevelopment: "創意開發、目標細化和性能監控，以最大化投資回報率。",
  tailoredServices: "量身定制的服務，以滿足特定需求和目標。",
  collaborationWithOurTeam:
    "與我們的團隊合作，開發滿足您獨特需求的定制解決方案。",
  serviceGetStarted: "開始使用",
  keyFeatures: "主要特點",
  dataEnrichment: "數據增強",
  dataCleansing: "數據清理",
  customListGeneration: "自定義列表生成",
  serviceOfferings: "服務項目",
  introDataPreparation:
    "我們的數據處理服務提供全面的解決方案，旨在優化您的銷售數據管理。我們專注於簡化流程，使您能夠增強現有數據 ...",
  introSalesAssitant:
    "銷售助手服務超越了一般的行政支持，專注於銷售過程中不可或缺的任務。無論您是想創作引人入勝的內容 ...",
  introAppointmentSetting:
    "這項服務採取個性化和實地的方法，根據提供的信息將客戶與其目標受眾聯繫，增強行業曝光，並培養 ...",
  introOtherService:
    "'其他'服務允許客戶通過提交有關其獨特需求的詳細信息來請求定制服務，提供靈活性和適應性，以應對各種要求 ...",
  questionCommonPagePriorityLevelTitle: "您對此任務的優先級別是什麼？",
  questionCommonPagePriorityLevelDesc:
    "說明您的任務優先級有助於我們及時滿足您的需求。您可以在提交後更改它。",
  questionCommonPageTimelineTitle: "考慮訂閱期限或時間表嗎？",
  questionCommonPageTimelineDesc:
    "告訴我們您首選的時間框架以及計劃開始的時間。",
  questionCommonPageServiceRequestTitle:
    "準備開始了嗎？首先，給您的申請一個獨特的名稱",
  questionCommonPageServiceRequestDesc:
    "我們將用它來跟蹤並在您的儀表板上更新進度。",
  questionCommonPageServiceDurationTitle: "您的服務期將持續多久？",
  quesitonCommonPageInvestmentBudget:
    "您每月在業務發展和項目上的投資預算是否超過HKD 12000？",
  questionCommonPageHearAboutUs: "您從哪裡聽說我們的？",
  questionCommonPageUploadTitle:
    "請在此處上傳您的Excel/CSV文件以進行數據增強或數據清理",
  questionCommonPageUploadDesc:
    "如果您選擇創建自定義列表生成，可以跳過或點擊'下一步'而不上傳。",
  questionDataPreparationPageInterestedDataOptimizationTitle:
    "您對哪些服務感興趣以進行數據優化？",
  questionDataPreparationPageInterestedDataOptimizationDesc:
    "您需要在下一步中附加一個CSV文件，以選擇數據增強或數據清理。",
  questionDataPreparationPageDataAttributesTitle:
    "選擇您希望納入文件的特定數據屬性（如適用）",
  questionDataPreparationPageDataAttributesDesc: "選擇一個或多個",
  questionDataPreparationPageNumberOfLeadsTitle:
    "您希望為您的業務生成或獲取多少潛在客戶？",
  questionDataPreparationPageNumberOfLeadsDesc:
    "指定數量或範圍，以便我們為您定制服務。",
  website_url: "網站網址",
  contact_first_name: "聯絡人名字",
  contact_last_name: "聯絡人姓氏",
  contact_title: "聯絡人職稱",
  contact_location: "聯絡人地點",
  contact_work_email: "聯絡人工作電子郵件",
  contact_personal_email: "聯絡人個人電子郵件",
  contact_work_phone: "聯絡人工作電話",
  contact_personal_phone: "聯絡人個人電話",
  company_size: "公司規模",
  company_industry: "公司行業",
  company_location: "公司地點",
  company_founded_year: "公司成立年份",
  company_keywords: "公司關鍵詞",
  company_ranking_in_global: "公司在全球的排名",
  company_financial_status: "公司財務狀況",
  company_office_address: "公司辦公地址",
  website_total_visit: "網站總訪問量",
  company_funding_status: "公司融資狀況",
  slugServiceName: "服務名稱",
  slugServiceDesc: "服務描述",
  errEnterServiceName: "請輸入服務名稱",
  errEnterDescription: "請輸入服務描述",
  errSelectAService: "請選擇一項服務",
  errSelectSpecificDataAttributes: "請選擇特定數據屬性",
  errInputTheQuantity: "請輸入數量",
  errSelectPriorityLevel: "請選擇您的優先級別",
  errSelectTimeline: "請選擇時間表",
  errInputContent: "請輸入內容",
  errInvestmentBudget: "請選擇是或否",
  errServiceDuration: "請選擇服務期限",
  errHearAboutUs: "請選擇來源",
  placeHolderEnterServiceName: "輸入服務名稱",
  placeHolderEnterServiceDescription: "輸入服務描述",
  placeHolderEnterListGeneration: "輸入列表生成",
  placeHolder150TO250: "150 - 250",
  Submit: "提交",
  serviceDownload: "下載",
  serviceExample: "範例",
  forYourReference: "供您參考",
  yourFileNeedsToInclude: "您的文件需要至少包括網站或公司名稱",
  includeAll: "包括所有",
  serviceNext: "下一步",
  serviceBack: "返回",
  data_enrichment: "數據增強",
  data_cleansing: "數據清理",
  custom_list_generation: "自定義列表生成",
  job_title: "職位名稱",
  timeline_within_one_weeks: "時間範圍在一周內",
  timeline_one_to_two_weeks: "時間範圍一到兩周內",
  timeline_two_to_four_weeks: "時間範圍兩到四周內",
  timeline_recurring: "定期時間範圍",
  timeline_custom_range: "自定義時間範圍",
  hear_about_us_social_media: "社交媒體",
  hear_about_us_google: "Google",
  hear_about_us_online_article: "在線文章",
  hear_about_us_webinar: "網絡研討會",
  hear_about_us_referral: "推薦",
  hear_about_us_custom_input: "其他",
  duration_last_than_one_month: "少於一個月",
  duration_one_to_three_months: "一到三個月",
  duration_over_three_months: "超過三個月",
  duration_decide_later: "稍後決定",
  yes: "是",
  no: "否",
  byCancelingYouCanEnsureThatNoTasksWillBeClosed:
    "通過取消，您可以確保沒有任務將被關閉",
  areYouSureYouWantToCloseThisTask: "您確定要關閉此任務嗎？",
  onceTheTaskIsClosed: "一旦任務關閉，將無法撤銷，您將無法恢復它。",
  Close: "關閉",
  ourTeamIsWorkingOnThisTask: "我們的團隊正在處理此任務",
  pleaseProviceYourReasonForThisAction: "請提供您此行動的理由",
  sendRequest: "發送請求",
  OK: "確定",
  areYouSureYouWantToDelete: "您確定要刪除嗎？",
  proceedingWithDeletionWillResultInPermanentRemovalOfTheFile:
    "進行刪除將導致文件永久刪除。",
  byCancelYouEnsureThatNoFileWillBeDeleted:
    "通過取消，您可以確保不會刪除任何文件。",
  areYouSureYouWantToDeleteThis: "您確定要刪除這個嗎？",
  serviceMessage: "消息",
  serviceFile: "文件",
  proceedingWithDeletionWillResultInPermanentRemovalOfThisMessage:
    "進行刪除將導致此消息永久刪除。",
  proceedingWithDeletionWillResultInPermanentRemovalOfThisFile:
    "進行刪除將導致此文件永久刪除。",
  youEnsureThatNoMessagesWillBeDeleted:
    "通過取消，您可以確保不會刪除任何消息。",
  youEnsureThatNoFilessWillBeDeleted: "通過取消，您可以確保不會刪除任何文件。",
  serviceDeleteFail: "刪除失敗",
  enterYourReason: "輸入您的理由",
  seriveStartDate: "開始日期",
  serviceEndDate: "預計結束日期",
  yourRequestHasBeenSent: "您的請求已發送",
  sendUsYourFeedback: "發送您的反饋給我們",
  weAreConstantlyImproving: "我們不斷改進！分享您的反饋以塑造我們平台的未來。",
  describeYourExperienceHere: "在此描述您的體驗...",
  additionallyYouCanAttachAnyScreenshots:
    "此外，您可以附加任何截圖或文件以提供更多細節。",
  sendFeedback: "發送反饋",
  serviceResume: "恢復",
  yourRequestHasBeenResumed: "您的請求已恢復",
  yourRequestIsCurrentlyPaused: "您的請求目前已暫停",
  serviceEdited: "已編輯",
  theFileSizeCannotExceed50M: "文件大小不能超過50M",
};