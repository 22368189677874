export default {
    navHome: "Home",
    navProspect: "Prospects",
    navCompanies: 'Companies',
    navEnrich: 'Enrich',
    navIntent: "Website Intent",
    navSequence: "Sequence",
    navService: "Services",
    navAccountCredit: 'Account credit',
    navSetting: "Setting",
    navSequenceDetail: "Sequence detail",

    // nav desc
    filterYourSearchToFindPreciselyTargeted: "Filter your search to find precisely targeted lists of companies",
    filterYourSearchToFindHighlySpecific: "Filter your search to find highly specific groups of targeted leads",
    instantlyEnhanceYourData: "Instantly enhance your data by adding valuable insights and key details from various sources",
    transformAnonymousWebsiteTrafficInto: "Transform anonymous website traffic into B2B leads with real-time identification",
    personalizeYourOutreach: 'Automate and personalize your outreach to save time and engage your prospects',
    comprehensiveBusinessSolutions: 'Comprehensive business solutions and support services for your needs',


    discoverAndConnectWithPotentialBuyers: "Discover and connect with potential buyers",
    activelySeekingYourSolutionToday: "actively seeking your solution today",
    Market_Intent: "Market Intent",
    Tools: "Tools",
    technologyLookup: "Technology Lookup",
    domainLookup: "Domain Lookup",
    emailVerification: "Email Verification",
    upgrade: "Upgrade",
    helpCenter: "Help Center",
    contactUs: "Contact Us",
    feedback: "Feedback",
    hello: "Hello",
    clickToChange: "Click to change",
    yourProfile: "Your Profile",
    currentUsage: "Current Usage",
    upgradePlan: "Upgrade Plan",
    integrations: "Integrations",
    featureRequests: "Feature Requests",
    change: "change",
    en: "English",
    cn: "中文（简）",
    tw: "中文（繁）",
    logout: "Log Out",
    switchLanguage: "Switched Language successfully",
};