import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/index.scss"; // global css
import VueCodeMirror from "vue-codemirror";
import "codemirror/lib/codemirror.css";

import App from "./App";
import router from "./router";
import store from "./store";
import i18n from "@/locales";
import ECharts from "vue-echarts";
import pagination from "@/components/pagination";
import Clipboard from "v-clipboard";
import Loading from "@/components/Loading";
import tableSkeleton from "@/utils/tableSkeleton";

import "@/icons";

import "@/permission";
import scrollActive from "@/utils/scrollActive";

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
  size: "mini",
});

Vue.use(tableSkeleton, {
  directiveName: "skeleton",
  rows: 25,
  radius: 3,
  bg: "#f2f2f2",
});

Vue.use(Clipboard);
Vue.use(VueCodeMirror);

Vue.component("v-chart", ECharts);
Vue.component("pagination", pagination);
Vue.component("Loading", Loading);

Vue.directive("focus", {
  inserted(el, binding, vnode) {
    // 聚焦元素
    el.querySelector("input").focus();
  },
});
Vue.directive("scrollbar", scrollActive);

Vue.directive("removeAriaHidden", {
  bind(el, binding) {
    const ariaEls = el.querySelectorAll(".el-radio__original");
    ariaEls.forEach((item) => {
      item.removeAttribute("aria-hidden");
    });
  },
});

Vue.config.errorHandler = function (err, vm, info) {
  //  console.error(`Error: ${err.toString()}\nInfo: ${info}`);
};

Vue.filter("convertFileSize", (bytes) => {
  if (bytes === 0) return "0 Byte";
  const units = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const k = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + units[i];
});

const EventBus = new Vue();
Vue.prototype.$bus = EventBus;

/** for sequence detail steps page
 *  1. to detect which node is selected
 *  2. if add new node drawer opened
 * */
const sequenceDetection = Vue.observable({
  sequence_workflow_current_node_id: "",
  sequence_workflow_current_plus_node: undefined,
  sequence_workflow_current_plus_id: ''
});
Object.defineProperty(Vue.prototype, "$sequence_workflow_current_node_id", {
  get() {
    return sequenceDetection.sequence_workflow_current_node_id;
  },
  set(value) {
    sequenceDetection.sequence_workflow_current_node_id = value;
  },
});
Object.defineProperty(
  Vue.prototype,
  "$sequence_workflow_current_plus_node",
  {
    get() {
      return sequenceDetection.sequence_workflow_current_plus_node;
    },
    set(value) {
      sequenceDetection.sequence_workflow_current_plus_node = value;
    },
  }
);
Object.defineProperty(
  Vue.prototype,
  "$sequence_workflow_current_plus_id",
  {
    get() {
      return sequenceDetection.sequence_workflow_current_plus_id;
    },
    set(value) {
      sequenceDetection.sequence_workflow_current_plus_id = value;
    },
  }
);

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  store,
  i18n,
  render: (h) => h(App),
});
