<template>
  <el-skeleton>
    <template slot="template">
      <div v-for="(navItem, i) in list" :key="i" class="nav-item-wrapper">
        <div v-for="child in navItem" :key="child.name" class="nav-item">
          <el-skeleton-item variant="text" class="nav-icon" />
          <el-skeleton-item variant="text" class="nav-name" />
        </div>
      </div>
    </template>
  </el-skeleton>
</template>

<script>
export default {
  data() {
    return {
      list: [[1], [1, 2, 3], [1], [1], [1]],
    };
  },
};
</script>

<style scoped lang="scss">
.nav-list {
  .nav-item-wrapper {
    padding: 8px;
    border-bottom: 1px solid $color-gray-4;
    .nav-item {
      height: 32px;
      display: flex;
      align-items: center;
      padding: 0 9px;
      border-radius: 8px;
      line-height: 18px; /* 150% */
      .nav-icon {
        flex-shrink: 0;
        width: 15px;
        height: 15px;
      }
      .nav-name {
        margin-left: 8px;
      }
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      &:nth-child(1) {
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
