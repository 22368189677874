<template>
  <span class="icon-wrapper" @click="setNavCollapse">
    <svg-icon class="nav-icon" :icon-class="isReverse ? 'nav-collapse-reverse': 'nav-collapse'"></svg-icon>
  </span>
</template>

<script>
export default {
  props: ['isReverse'],
  methods: {
    setNavCollapse() {
      this.$emit('toggleCollapse')
    }
  }
}
</script>

<style scoped lang="scss">
.icon-wrapper {
  width: 20px;
  height: 20px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  .svg-icon {
    stroke: $color-gray-1;
  }
  &:hover {
    background-color: $color-gray-0;
    .svg-icon {
      stroke: $color-black-4;
    }
  }
}
</style>
