export default {
    //search-company
    people: "People",
    companies: "Companies",
    clearAll: "Clear All",
    searchProspects: "Search...",
    //actions
    isFoundIn: "Is found in ",
    isNotFoundWithinAnyLists: "is not found within any lists",
    l: " lists",
    remove: "Remove",
    saveToLists: "Save to Lists",
    DeleteFailed: "Delete Failed",
    //delete dialog
    areYouSureYouWantToDeleteThisRecord:
      "Are you sure you want to delete this record?",
    proceedingWithDeletionWillResultInPermanentRemovalOfTheList:
      "Proceeding with deletion will result in permanent removal of the list",
    delete: "Delete",
    byCancelingYouEnsureThatNoListsWillBeRemoved:
      "By canceling, you ensure that no lists will be removed",
    //export dialog
    holdOnWereGeneratingYourExport: "Hold on, we're generating your export",
    UseThisButtonOnlyIfYouWishToCancelYourAction:
      "Use this button only if you wish to cancel your action",
    //save search pop
    saveSearch: "Save Search",
    newSaveSearch: "New Save Search",
    UpdateSavedSearch: "Update Saved Search",
    enterNewSearchName: "Enter New Search Name",
    visibility: "Visibility",
    manageWhoCanSeeYourSavedSearch: "Manage who can see your saved search",
    private: "Private",
    onlyYouCanSeeYourSavedSearch: "Only you can see your saved search",
    everyoneCanSeeYourSavedSearch: "Everyone can see your saved search",
    pinSavedSearch: "Pin Saved Search",
    findSavedSearch: "Find saved search",
    enterSavedSearchName: "Enter saved search name",
    duplicate: "Duplicate",
    update: "Update",
    RemoveCreditCard: "Remove Credit Card",
    enterATitleForYourCard: "Enter a title for your card",
    enterYourCardNumber: "Enter your card number",
    digit: "3-digit",
    provideYourCompleteAddress: "Provide your complete address",
    selectYourCountry: "Select your country",
    //add credits pop
    ClickTheButtonBelowToAddMoreCredits:
      "Click the button below to add more credits!",
    PleaseEnterTheAmountYouLikeToTopUpToYourCredit:
      "Please enter the amount you'd like to top up to your credit",
    //companyLink\contactBtn\functionBtn\default
    more: "More",
    verified: "Verified",
    GetEmailSuccessfully: "Got Email Successfully",
    CopySuccessfully: "Copied Successfully",
    recorded: "Recorded",
    implement: "Implement",
    sequence: "Sequence",
    of: "of",
    toAssistYouEffectivelyWeRequireFurtherDetailsAbout:
      "To assist you effectively, we require further details about your search preferences. Please utilize the filter options in the left panel to specify your desired criteria, enabling us to refine the search results and provide you with targeted individuals that meet your requirements.",
  
    //filter
    all: "All",
    my: "My",
    team: "Team",
    lists: "Saved Lists",
    employees: "Employees",
    technologies: "Technologies",
    socialmedia: "Social Media",
    enterSocialMedia: "Enter Social Media",
    noResultsFound: "No results found",
    searchCompanies: "Search Companies",
    filters: "Filters",
    enterCompanies: "Enter Companies",
    accountHqLocation: "Account HQ Location",
    CityStateCountryZIP: "City/State/Country/ZIP",
    enterLocations: "Enter Locations",
    revenue: "Revenue",
    chooseARangeOfRevenue: "Choose a range of revenue",
    ofRevenueIsUnknown: "of revenue is unknown",
    technology: "Technology",
    category: "Category",
    categories: "Categories",
    enterCategories: "Enter Categories",
    enterTechnologies: "Enter Technologies",
    enterIndustries: "Enter Industries",
    employee: "Employee",
    predefinedRange: "Predefined Range",
    ofEmployeesIsUnknown: "of employees is unknown",
    enterNames: "Enter Names",
    jobTitle: "Job Title",
    searchForAJobTitle: " Search for a Job Title",
    founded: "Founded",
    chooseARangeOfFoundedTime: "Choose a range of time",
    from: "From",
    to: "To",
    specialties: "Specialities",
    enterSpecialties: "Enter Specialties",
    location: "Location",
    total: "Total",
    department: "Department",
    EnterDepartments: "Enter Departments",
    JobTitle: "Job Title",
    EnterJobTitles: "Enter Job Titles",
    ManagementLevel: "Management Level",
    EnterManagementLevels: "Enter Management Levels",
    AccountHQ: "Account HQ",
    ContactHQ: "Contact HQ",
    departmentFunction: "Department Function",
    EnterJobFunctions: "Enter Job Functions",
    enterNamesAndPressTheEnterKey: "Enter names and press the Enter key...",
    EnterJobTitlesAndPressTheEnterLKey:
      "Enter titles with ',' and press the Enter key...",
    pleaseSelectRange: "Please select a range",
  
    //table
    selectAllCompanies: "Select All Companies",
    selected: "Selected",
    export: "Export",
    industry: "Industry",
    keywords: "Keywords",
    companyLocation: "Company Location",
    action: "Action",
    contact: "Contact",
    Title: "Title",
    clearSelection: "Clear Selection",
    selectNumberOfCompanies: "Select number of companies",
    maxCompanies: "Max companies",
    youCanOnlySelectAMaximumOf25Companies:
      "You can only select a maximum of 25 companies.",
    UpgradeYourPlanNow: "Upgrade your plan now!",
    viewPlans: "View Plans",
    applySelection: "Apply Selection",
    selectNumberOfProspects: "Select number of prospects",
    maxProspects: "Max prospects",
    youCanOnlySelectAMaximumOf25Prospects:
      "You can only select a maximum of 25 prospects.",
    PleaseSelectTheDataToBeExported: "Please select the data to be exported",
    overview: "Overview",
    genericContacts: "Generic Contacts",
    size: "Size",
    contactLocation: "Contact Location",


    // 新UI
    prospectsSearch: "Prospects Search",
    companiesSearch: "Companies Search",
    savedSearches: "Saved searches",
    savedLists: "Saved lists",


    // saved list and saved searches
    sortAndFilter: "Sort & Filter",
    sortBy: "Sort by",
    filterAll: 'All',
    filterPrivate: 'Private',
    filterTeam: 'Team',
    searchSavedSearches: "Search saved searches",
    searchSavedLists: "Search saved lists"
    
};