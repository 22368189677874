export default {
    //top-nav
    navHome: "首頁",
    navProspect: "潛在客戶",
    navCompanies: '公司',
    navEnrich: '数据丰富',
    navIntent: "網站意圖",
    navSequence: "工作流程",
    navService: "企業服務",
    navAccountCredit: '帳戶信用點',
    navSetting: "設定",
    navSequenceDetail: "工作流程詳情",
    
    // nav desc
    filterYourSearchToFindPreciselyTargeted: "篩選您的蒐索，以找到目標明確的公司清單",
    filterYourSearchToFindHighlySpecific: "篩選您的蒐索，以找到高度特定的目標潛在客戶群",
    instantlyEnhanceYourData: '通過添加來自各種來源的有價值的見解和關鍵細節，立即增强您的數據',
    transformAnonymousWebsiteTrafficInto: "通過添加來自各種來源的有價值的見解和關鍵細節，立即增强您的數據",
    personalizeYourOutreach: '自動化和個性化您的外展活動，以節省時間並吸引潛在客戶',
    comprehensiveBusinessSolutions: '滿足您需求的全面業務解決方案和支持服務',

    b2bLeadsWithRealtimeIdentification: "實時識別的B2B潛在客戶",
    discoverAndConnectWithPotentialBuyers: "發現並與潛在客戶聯繫",
    activelySeekingYourSolutionToday: "今天積極尋求您的解決方案",
    Market_Intent: "市場意圖",
    Tools: "工具",
    technologyLookup: "技術查詢",
    domainLookup: "域名查詢",
    emailVerification: "電子郵件驗證",
    upgrade: "升級",
    helpCenter: "幫助中心",
    contactUs: "聯繫我們",
    feedback: "反饋",
    hello: "你好",
    clickToChange: "點擊更改",
    yourProfile: "您的個人資料",
    currentUsage: "當前使用情況",
    upgradePlan: "升級計劃",
    integrations: "集成",
    featureRequests: "功能請求",
    change: "更改",
    en: "英文",
    cn: "中文（简）",
    tw: "中文（繁）",
    logout: "登出",
    switchLanguage: "成功切換語言",
};