<template>
  <div
    class="notification-item"
    :style="{
      background: !is_read ? '#f6f9ff' : '#fff',
    }"
    @click="handleItemClick"
  >
    <div class="dot" v-show="!is_read"></div>
    <div class="icon">
      <svg-icon icon-class="header-notification-list-icon1" />
    </div>
    <div class="text">
      <p class="title">{{ subject || "-" }}</p>
      <div v-if="message" v-html="message" class="desc"></div>
      <div class="text-bottom">
        <p class="info">{{ localeDateTime(data.created_at) }}</p>
        <el-button
          class="notification-view-detail-button"
          @click="handleViewDetail"
        >
          {{ $t("notification-view-details") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { updateNotification } from "@/api/notification";
import DOMPurify from "dompurify";
import moment from "moment";
import { mapMutations } from "vuex";
export default {
  name: "notification-item",
  props: ["data", "profile_id", "statusSlugMap", "read_ids"],
  data() {
    return {
      is_read: this.data.is_read,
    };
  },
  computed: {
    subject() {
      return (
        this.data[this.$i18n.locale === "en" ? "subject_en" : "subject"] ||
        this.data.notification_type_name ||
        ""
      );
    },
    message() {
      const messageField =
        this.$i18n.locale === "en" ? "message_en" : "message";
      if (this.data[messageField]) {
        if (this.data.is_html_message) {
          return DOMPurify.sanitize(this.data[messageField]);
        }
        return this.data[messageField];
      }
      switch (this.data.notification_type_slug) {
        case "data-redemption-completed-people":
          return this.$t(
            "notification-data-redemption-completed-people-message",
            [
              this.data.notification_metadata.total_charged_credit.toLocaleString(),
              this.data.notification_metadata.total_contact_processed.toLocaleString(),
            ]
          );
        case "services-status-update":
          return this.$t("notification-services-status-update-message", [
            this.statusSlugMap[
              this.data.notification_metadata.update_service_status_slug
            ][
              this.$i18n.locale === "en" ? "name_en" : "name"
            ]?.toLocaleString(),
          ]);
        case "receive-referral-credit-bonus":
          return this.$t("notification-receive-referral-credit-bonus-message", [
            this.data.notification_metadata.credit.toLocaleString(),
          ]);
          break;
        case "receive-onboarding-credit-bonus":
          return this.$t("notification-receive-onboarding-credit-bonus", [
            this.data.notification_metadata.credit.toLocaleString(),
          ]);
        default:
          return "";
      }
    },
  },
  watch: {
    data(value) {
      this.is_read = value.is_read;
    },
    read_ids(value) {
      if (value.includes(this.data.profile_user_notification_id)) {
        this.is_read = true;
      }
    },
  },
  methods: {
    ...mapMutations("notification", ["setReadIds"]),
    localeDateTime(datetime) {
      moment.locale(this.$i18n.locale);
      return moment(datetime).format("LLLL");
    },
    async handleItemClick() {
      if (this.is_read) {
        return;
      }
      try {
        this.is_read = true;
        this.$emit("decreaseNotReadCount");
        await updateNotification({
          profile_id: this.profile_id,
          profile_user_notifications: [this.data.profile_user_notification_id],
          is_read: true,
        });
        this.setReadIds([this.data.profile_user_notification_id]);
      } catch (error) {
        console.log(error);
      }
    },
    handleViewDetail() {
      switch (this.data.notification_type_slug) {
        case "data-redemption-completed-people":
          const params1 = new URLSearchParams({
            redeem_queries: [this.data.notification_metadata.redeem_query_id],
          });
          this.$router.push(`/search/people?${params1.toString()}`);
          this.$emit("close");
          break;
        case "services-status-update":
          const params2 = new URLSearchParams({
            id: this.data.notification_metadata.id,
          });
          this.$router.push(`/services/overview?${params2.toString()}`);
          this.$emit("close");
          break;
        default:
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.notification-item {
  padding: 12px 16px;
  position: relative;
  border-bottom: 1px solid $color-gray-4;
  display: flex;
  gap: 16px;
  cursor: pointer;
  .dot {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $color-red-1;
    left: 8px;
    top: 12px;
  }
  .icon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: $color-gray-0;
  }
  .text {
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: calc(100% - 48px);

    .title {
      color: var(--Black, #343434);
      font-family: Lato;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.39px;
    }

    .desc {
      color: var(--Black, #343434);
      font-family: Lato;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 154.3%; /* 20.059px */
      letter-spacing: 0.39px;
      word-wrap: break-word;
      word-break: break-all;

      max-height: 40px;

      overflow: hidden;
      text-overflow: ellipsis;
    }

    .text-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      .info {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(--Gray-0004, #98a1b3);
        font-family: Lato;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 154.3%; /* 20.059px */
        letter-spacing: 0.39px;
      }
    }
  }
}
</style>
