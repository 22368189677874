import * as API from '@/api/permission'

const state = {
  permissionList:[],
  roleList:[],
  teamList: {},
  userListInRole:[],
  userListInTeam:{},
  teamRelationList:{},
  teamRelationType:[],
  sharingRulesGroup:[],
  showList:true,
  activeTeam:{},
  saveLoading:false, //team sharing rules save loading
  isDisabled: true, //team sharing rules save button disabled
  roleIsDisabled:true //role sharing rules save button disabled
}
const mutations = {
  setRoleIsDisabled(state,data){
    state.roleIsDisabled = data
  },
  setSaveLoading(state,data){
    state.saveLoading = data
  },
  setIsDisabled(state,data){
    state.isDisabled = data
  },
  setActiveTeam(state,data){
    state.activeTeam = data
  },
  setShowingList(state,data){
    state.showList = data
  },
  setPermissionList(state,data){
    state.permissionList = data
  },
  setRoleList(state,data){
    state.roleList = data
  },
  setTeamList(state,data){
    state.teamList = data
  },
  setUserListInRole(state,data){
    state.userListInRole = data
  },
  setUserListInTeam(state,data){
    state.userListInTeam = data
  },
  setTeamRelationList(state,data){
    state.teamRelationList = data
  },
  setTeamRelationType(state,data){
    state.teamRelationType = data
  },
  setSharingRulesGroup(state,data){
    state.sharingRulesGroup = data
  }
}
const actions = {
  async getPermissionList(context){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
    }
    try{
      const res = await API.getPermissionList(params)
      context.commit('setPermissionList',res.data)
      return res.data
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async getRoleList(context, data){
    const currentId = this.state.user.currentId.profile_id
    const max_team = this.state.user.systemConfig.default_iam_max_team_snippets
    const max_user = this.state.user.systemConfig.default_iam_max_user_snippets
    const params = {
      "profile_id":currentId,
      "max_user_snippets": max_team,
      "max_team_snippets": max_user,
      "page":1,
      "per_page":25,
      ...data,
    }
    try{
      const res = await API.getRoleList(params)
      context.commit('setRoleList',res.data)
      return res.data
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async createRole(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.createRole(params)
      return res.data
    }catch(err){
      console.log(err)
      throw err
    }
  },
  async addRoleToUser(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.addRoleToUser(params)
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async addRoleToTeam(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.addRoleToTeam(params)
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async updateRole(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.updateRole(params)
      return res.data
    }catch(err){ console.log(err);
      throw err;
    }
  },

  async updateRolePermission(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.updateRolePermission(params)
      return res.data
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async deleteRole(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.deleteRole(params)
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async deleteUserRole(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.deleteUserRole(params)
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async getUserListInRole(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      "page":1,
      "per_page":25,
      ...data
    }
    try{
      const res = await API.getUserListInRole(params)
      context.commit('setUserListInRole',res.data)
      return res.data
    }catch(err){
      console.log(err)
      throw err
    }
  },

  /*------------------------- team ---------------------------*/

  async getTeamList(context,data){
    const currentId = this.state.user.currentId.profile_id
    const max_user = this.state.user.systemConfig.default_iam_max_user_snippets
    const params = {
      "profile_id": currentId,
      "max_user_snippets": max_user,
      "page": 1,
      ...data,
    }
    try {
      const res = await API.getTeamList(params)
      context.commit('setTeamList',res.data)
    }catch(err){ console.log(err);
      throw err;
    }
  },
  async createTeam(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.createTeam(params)
      return res.data
    }catch(err){ console.log(err);
      throw err;
    }
  },
  async updateTeam(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.updateTeam(params)
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async deleteTeam(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.deleteTeam(params)
    }catch (err) {
      console.log(err);
      throw err;
    }
  },
  async deleteUserTeam(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.deleteUserTeam(params)
      return res.data
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async getUserListInTeam(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      "page":1,
      "per_page":25,
      ...data
    }
    try{
      const res = await API.getUserListInTeam(params)
      context.commit('setUserListInTeam',res.data)
      return res.data
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async  updateTeamUser(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.updateTeamUser(params)
      return res.data
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async updateTeamRole(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.updateTeamRole(params)
      return res.data
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async addTeamToUser(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.addTeamToUser(params)
      return res.data
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  /*------------------------- relation ---------------------------*/

  async getTeamRelationList(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.getTeamRelationList(params)
      context.commit('setTeamRelationList',res.data)
      return res.data
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async modifyTeamRelation(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.modifyTeamRelation(params)
      return res.data
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async deleteTeamRelation(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try{
      const res = await API.deleteTeamRelation(params)
      return res.data
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async addTeamRelation(context,data){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
      ...data
    }
    try {
      const res = await API.addTeamRelation(params)
      return res.data
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async getTeamRelationType(context){
    const currentId = this.state.user.currentId.profile_id
    const params = {
      "profile_id":currentId,
    }
    try {
      const res = await API.getTeamRelationType(params);
      context.commit('setTeamRelationType', res.data);
      return res.data;
    } catch (err) {
      console.log(err);
      throw err; // 重新抛出错误
    }
  },
  /*------------------------- sharing rules ---------------------------*/
  async createSharingRulesGroup(context,data){
    const currentId = this.state.user.currentId.profile_id
    try{
      const res = await API.createSharingRulesGroup({
        "profile_id":currentId,
        ...data
      })
      return res.data
    }catch (err) {
      console.log(err);
      throw err; // 重新抛出错误
    }
  },
  async deleteSharingRulesGroup(context,data){
    const currentId = this.state.user.currentId.profile_id
    try{
      const res = await API.deleteSharingRulesGroup({
        "profile_id":currentId,
        ...data
      })
      return res.data
    }catch (err) {
      console.log(err);
      throw err;
    }
  },
  async updateSharingRulesGroup(context,data){
    const currentId = this.state.user.currentId.profile_id
    try{
      const res = await API.updateSharingRulesGroup({
        "profile_id":currentId,
        ...data
      })
      return res.data
    }catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getSharingRulesGroupList(context,data){
    const currentId = this.state.user.currentId.profile_id
    try{
      const res = await API.getSharingRulesGroupList({
        "profile_id":currentId,
        ...data
      })
      context.commit('setSharingRulesGroup',res.data)
      return res.data
    }catch (err) {
      console.log(err);
      throw err;
    }
  },
  async updateSharingRules(context,data){
    const currentId = this.state.user.currentId.profile_id
    try{
      const res = await API.updateSharingRules({
        "profile_id":currentId,
        ...data
      })
      return res.data
    }catch (err) {
      console.log(err);
      throw err;
    }
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
