export const template_en = {
  sequence_template_aside_create_new: "New template",
  sequence_template_main_empty_title: "Nothing here yet",
  sequence_template_main_empty_description:
    "Pick a template from the list to start editing here.",
  version: "Version:",
  sequence_template_version_select_default: "Default",
  sequence_template_version_select_add: "New version",
  sequence_template_set_as_default: "Set as default",
  rename: "Rename",
  delete: "Delete",
  sequence_template_create_dialog_name: "Create New Version",
  sequence_template_edit_dialog_name: "Update Name",
  sequence_template_create_dialog_input_label: "Name",
  sequence_template_edit_dialog_input_label: "Name",
  sequence_template_create_dialog_input_oplaceholder: "Enter a name",
  sequence_template_edit_dialog_input_placeholder: "Enter a name",
  sequence_template_create_dialog_title: "Create New Template",
  sequence_template_create_dialog_template_name_label: "Template name",
  sequence_template_create_dialog_template_name_placeholder: "Enter a name",
  sequence_template_create_dialog_template_channel_label: "Template type",
  sequence_template_create_dialog_template_channel_placeholder:
    "Select template type",
};

export const template_zh_cn = {
  sequence_template_aside_create_new: "新模板",
  sequence_template_main_empty_title: "这里还没有内容",
  sequence_template_main_empty_description: "从列表中选择一个模板来开始编辑。",
  version: "版本：",
  sequence_template_version_select_default: "默认",
  sequence_template_version_select_add: "新增版本",
  sequence_template_set_as_default: "设为默认",
  rename: "重命名",
  delete: "删除",
  sequence_template_create_dialog_name: "创建新版本",
  sequence_template_edit_dialog_name: "更新名称",
  sequence_template_create_dialog_input_label: "名称",
  sequence_template_edit_dialog_input_label: "名称",
  sequence_template_create_dialog_input_oplaceholder: "输入名称",
  sequence_template_edit_dialog_input_placeholder: "输入名称",
  sequence_template_create_dialog_title: "创建新模板",
  sequence_template_create_dialog_template_name_label: "模板名称",
  sequence_template_create_dialog_template_name_placeholder: "输入名称",
  sequence_template_create_dialog_template_channel_label: "模板类型",
  sequence_template_create_dialog_template_channel_placeholder: "选择模板类型",
};

export const template_zh_tw = {
  sequence_template_aside_create_new: "新範本",
  sequence_template_main_empty_title: "這裡還沒有內容",
  sequence_template_main_empty_description: "從列表中選擇一個範本來開始編輯。",
  version: "版本：",
  sequence_template_version_select_default: "預設",
  sequence_template_version_select_add: "新增版本",
  sequence_template_set_as_default: "設為預設",
  rename: "重新命名",
  delete: "刪除",
  sequence_template_create_dialog_name: "建立新版本",
  sequence_template_edit_dialog_name: "更新名稱",
  sequence_template_create_dialog_input_label: "名稱",
  sequence_template_edit_dialog_input_label: "名稱",
  sequence_template_create_dialog_input_oplaceholder: "輸入名稱",
  sequence_template_edit_dialog_input_placeholder: "輸入名稱",
  sequence_template_create_dialog_title: "建立新範本",
  sequence_template_create_dialog_template_name_label: "範本名稱",
  sequence_template_create_dialog_template_name_placeholder: "輸入名稱",
  sequence_template_create_dialog_template_channel_label: "範本類型",
  sequence_template_create_dialog_template_channel_placeholder: "選擇範本類型",
};
