<template>
  <div id="right">
    <div class="left">
      <NavCollapseIcon :is-reverse="true" v-if="!navCollapse" @toggleCollapse="toggleCollapse" />
      <span class="module-name">{{ $t(moduleName) }}</span>
    </div>
    <div class="setting-wrapper">

      <notification />

      <el-dropdown class="question" trigger="click">
        <span class="btn-nav-icon">
          <img src="@/icons/png/topNav-help.png" alt="My Image" />
        </span>
        <el-dropdown-menu
          slot="dropdown"
          :append-to-body="false"
          :visible-arrow="false"
        >
          <el-dropdown-item>
            <a href="javascript:;" @click="showFeedbackDialog" class="rlink">
              <svg-icon icon-class="feedback" />
              <span>{{ $t("feedback") }}</span>
            </a>
          </el-dropdown-item>
          <el-dropdown-item>
            <a href="https://www.pubrio.com/faqs" target="_blank" class="rlink">
              <svg-icon icon-class="Help" />
              <span>{{ $t("helpCenter") }}</span>
            </a>
          </el-dropdown-item>
          <el-dropdown-item>
            <a
              href="https://www.pubrio.com/contact-us"
              target="_blank"
              class="rlink"
            >
              <svg-icon icon-class="about us" />
              <span>{{ $t("contactUs") }}</span>
            </a>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <Language />
    </div>
    <Feedback :dialogVisible.sync="feedbackShow" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Feedback from "@/views/dashboard/components/nav/components/Feedback.vue";
import notification from "@/components/notification/notification.vue";
import Language from './components/Language.vue';
import NavCollapseIcon from './components/NavCollapseIcon.vue';

export default {
  name: "NavTop",
  components: {
    Feedback,
    Language,
    notification,
    NavCollapseIcon
  },
  data() {
    return {
      feedbackShow: false,
    };
  },
  computed: {
    ...mapState('base', ['navCollapse']),
    moduleName() {
      console.log('hhhh');
      console.log(this.$route);
      return this.$route.meta.title
    }
  },
  methods: {
    ...mapMutations('base', ['setNavCollapse']),
    ...mapActions("user", ["getConfig", "getLanguages"]),
    ...mapActions("account", ["getUserList", "getProfile"]),
    showFeedbackDialog() {
      this.feedbackShow = true;
    },
    toggleCollapse() {
      this.setNavCollapse(!this.navCollapse);
    }
  },
};
</script>

<style scoped lang="scss">
#right {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: $nav-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $color-gray-0;
  padding: 0 16px;
  background-color: #fff;
  .left {
    display: flex;
    gap: 8px;
    .module-name {
      color: $color-black-4;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.32px;
    }
  }
  .setting-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .el-button {
    flex: 2;
    font-size: 14px;
  }
  .question {
    ::v-deep .el-dropdown-menu {
      width: 232px;
      margin-right: -100px;
      padding-bottom: 12px;
      .el-dropdown-menu__item {
        width: 100%;
        padding: 0 16px !important;
        a {
          display: flex;
          align-items: center;
          height: 44px;
          line-height: 40px;
          padding: 10px;
          font-weight: 600;
          font-size: 16px;
          color: $color-black;
          .svg-icon {
            margin-right: 16px;
            font-size: 20px;
          }
        }
        .rlink {
          border-bottom: 0.4px solid #e1e2e6;
        }
      }
    }
  }
  ::v-deep .el-dropdown-menu {
    .el-dropdown-menu__item {
      width: 100%;
      padding: 0 16px;
    }
    .el-dropdown-menu__item:focus,
    .el-dropdown-menu__item:not(.is-disabled):hover {
      background: $color-blue-1;
    }
  }
}
.fade-enter-active {
  animation: fade-in 0.3s ease-in-out forwards;
}

.fade-leave-active {
  animation: fade-out 0.3s ease-in-out forwards;
}

@keyframes fade-in {
  0% {
    max-height: 0;
    transform-origin: top;
    transform: scaleY(0);
    opacity: 0;
  }
  100% {
    max-height: 346px;
    transform-origin: top;
    transform: scaleY(1);
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    max-height: 346px;
    transform-origin: top;
    transform: scaleY(1);
    opacity: 1;
  }
  100% {
    max-height: 0;
    transform-origin: top;
    transform: scaleY(0);
    opacity: 0;
  }
}
</style>
