export default {
  serviceMenuOverview: "Overview",
  serviceMenuPipeline: "Pipeline",
  serviceMenuAnalytics: "Analytics",
  serviceMenuResources: "Resources",
  salesServices: "Sales Services",
  chooseYourTypeofServicesToStreamlineAndEnhanceYourBusinessOperations:
    "Choose your type of services to streamline and enhance your business operations",
  dataPreparation: "Data Preparation",
  salesAssistant: "Sales Assistant",
  appointmentSetting: "Appointment Setting",
  othersCustomService: "Others (Custom Service)",
  requestService: "Request Service",
  learnMore: "Learn more",
  serviceRequestStatus: "Service Request Status",
  noServiceRequestsYet: "No Service Requests Yet",
  submitYourFirstRequestToUnlockThePower:
    "Submit your first request to unlock the power of our sales services! Track your requests' status here to supercharge your sales efforts. Let's get started!",
  serviceTableItemName: "Name",
  serviceTableItemStatus: "Status",
  serviceTableItemType: "Type",
  serviceTableItemProgress: "Progress",
  serviceTableItemStart: "Start",
  serviceTableItemEnd: "End",
  serviceTableItemPriority: "Priority",
  addASubject: "Add a subject",
  created: "created",
  closed: "closed",
  paused: "paused",
  working: "working",
  completed: "completed",
  resuming: "resuming",
  reviewing: "reviewing",
  others: "Others",
  data_preparation: "Data Preparation",
  sales_assistant: "Sales Assistant",
  appointment_setting: "Appointment Setting",
  appointment_booking: "Appointment Booking",
  low: "Low",
  normal: "Normal",
  urgent: "Urgent",
  high: "High",
  requiredCredits: "Required Credits",
  serviceCreated: "Created",
  QandARecord: "Q&A Record",
  serviceAttachments: "Attachments",
  discussionBoard: "Discussion Board",
  wannaAddSomething: "wanna add something?",
  serviceEdit: "Edit",
  serviceDelete: "Delete",
  addDescription: "Add Description",
  DragDropor: "Drag & Drop or",
  chooseFile: "Choose file",
  toUpload: "to upload",
  ourDataPreparationService:
    "Our Data Preparation service provides comprehensive solutions tailored to optimize your sales data management. We focus on simplifying the process, allowing you to enhance your existing datasets, ensure accuracy through data cleansing, and create customized lists that perfectly align with your needs. This approach grants you the flexibility and efficiency required to effectively manage your data.",
  moreoverWithOurDedicatedDataManagement:
    "Moreover, with our dedicated data management service, Pubrio, the process becomes even more effortless. You can seamlessly attach files and augment your datasets with additional information. Pubrio offers more than just enrichment; it also allows for the creation of entirely new lists based on your specific criteria. This personalized service ensures that your datasets are precisely tailored to meet your objectives and industry standards, putting you in control of your data landscape.",
  flexibilityToAttach:
    "Flexibility to attach existing lists or instruct Pubrio to find new lists based on specified criteria.",
  comprehensiveDataEnrichment:
    "Comprehensive data enrichment to add missing details and enhance dataset completeness.",
  dataCleansingServicesToEnsureAccuracy:
    "Data cleansing services to ensure accuracy and quality, including duplicate removal and error correction.",
  abilityToInstructPubrio:
    "Ability to instruct Pubrio to curate entirely new lists based on meticulously defined criteria.",
  tailoringDatasetsPrecisely:
    "Tailoring datasets precisely to align with specific objectives and industry standards.",
  dataEnrichmentWithExistingList:
    "Data Enrichment with Existing List: Easily enhance your current list by uploading it to Pubrio. We'll fill in missing details, such as email addresses and phone numbers, to meet your requirements. For example, provide your customer database, and we'll update it with essential information.",
  customListGenerationIf:
    "Custom List Generation: If you don't have a list, let Pubrio find one for you. Just tell us your criteria, like job titles or industries, and we'll create the perfect list. For instance, if you need a list of top technology executives in a specific area, we'll handle it for you.",
  dataCleansingForExistingLists:
    "Data Cleansing for Existing Lists: Ensure your data is accurate and reliable with Pubrio's data cleansing service. Send us your list, and we'll remove duplicates and correct any errors. For example, if you have a list of recent leads, we'll make sure it's ready for your marketing campaigns.",
  empoweringYourSalesEeavors:
    "Empowering your sales endeavors, our Sales Assistant service offers comprehensive support across various critical areas, ensuring your outreach and communication strategies are finely tuned for success. Whether you're looking to craft compelling content, seamlessly organize events, develop tailored presentations, or refine your sales and communication strategies, our dedicated team is here to supercharge your sales efforts and elevate your brand's presence in the market.",
  craftingCompellingContent:
    "Crafting Compelling Content: Effective communication is key to capturing your audience's attention. Our team excels in crafting engaging content tailored to your brand and audience preferences. From thought-provoking LinkedIn posts to informative blog articles and eye-catching social media updates, we ensure your message resonates with your target market.",
  seamlessEventAndWebinarPreparation:
    "Seamless Event and Webinar Preparation: Events and webinars are powerful tools for engaging with your audience and generating leads. Our Sales Assistant service takes care of all the details, from planning and promotion to execution. Whether it's hosting informative webinars or organizing networking events, we ensure your events leave a lasting impression.",
  tailoredContentDevelopmentAndPresentation:
    "Tailored Content Development and Presentation: Captivate your audience with compelling presentations and personalized messaging. Our team specializes in creating visually appealing decks and crafting tailored messages that resonate with specific audience segments. Whether you're pitching to investors or nurturing leads, we ensure your content stands out.",
  strategicSalesAndCommunicationFrameworks:
    "Strategic Sales and Communication Frameworks: Streamline your sales processes with strategic guidance and effective communication scripts. Our Sales Assistant service equips your team with the tools and strategies needed to drive conversions and foster meaningful connections.",
  craftingCompellingContent: "Crafting Compelling Content",
  companyLinkedInPosts: "Company LinkedIn Posts (Frequency: Weekly or monthly)",
  personalLinkedInPosts:
    "Personal LinkedIn Posts (Frequency: Flexible, based on preference and content availability)",
  websiteBlogPosts:
    "Website Blog Posts (Frequency: Typically weekly or bi-weekly)",
  socialMediaPosts:
    "Social Media Posts (Frequency: Daily or as per content strategy)",
  emailMarketingCampaigns:
    "Email Marketing Campaigns (Frequency: Weekly or bi-weekly)",
  seamlessEventAndWebinarPreparation: "Seamless Event and Webinar Preparation",
  webinarAndWorkshopPreparation: "Webinar and Workshop Preparation",
  networkingAndOfflineEvent: "Networking and Offline Event Coordination",
  tailoredContentDevelopment: "Tailored Content Development and Presentation",
  companyDeckPreparation:
    "Company Deck Preparation (For presentations, pitches, etc.)",
  highlyPersonalizedMessage:
    "Highly Personalized Message Creation (Tailored for specific audience segments)",
  strategicSales: "Strategic Sales and Communication Frameworks",
  salesCallScripts: "Sales Call Scripts",
  emailOutreachTemplate: "Email Outreach Templates",
  salesChannelStrategies: "Sales Channel Strategies",
  ourAppointmentSettingService:
    "Our Appointment Setting service is tailored to elevate your business by facilitating crucial connections, fostering industry exposure, and nurturing valuable prospects. We specialize in individualized industry exposure, seamless seminar and webinar coordination, warming up new prospects, and initiating engaging approaches to new audiences.",
  individualIndustryAppointment:
    "Individual Industry Appointment Exposure and Reaction",
  tailoredOutreach:
    "Tailored outreach to key decision-makers and influencers within your industry ensures targeted exposure.",
  strategicFollowups:
    "Strategic follow-ups allow us to gauge reactions, address concerns, and foster deeper engagements.",
  eachInteraction:
    "Each interaction is personalized based on the recipient's profile and preferences, maximizing impact.",
  seminarWebinarCoordination: "Seminar & Webinar Coordination",
  ourExpertTeamHandles:
    "Our expert team handles every aspect of seminar and webinar coordination, from initial planning to post-event follow-up.",
  weMeticulouslyPlan:
    "We meticulously plan and execute events to showcase your expertise, ensuring a seamless and engaging experience for participants.",
  byLeveragingAdvancedTechnologyPlatforms:
    "By leveraging advanced technology platforms, we enhance interaction and participation, maximizing the impact of your events.",
  newProspectWarmUp: "New Prospect Warm-Up",
  proactiveEngagementStrategies:
    "Proactive engagement strategies are employed to warm up new prospects, laying the groundwork for fruitful discussions.",
  tailoredCommunicationStrategies:
    "Tailored communication strategies are deployed to build rapport, trust, and interest in your offerings.",
  throughStrategicNurturing:
    "Through strategic nurturing, we keep prospects engaged and primed for conversion, increasing the likelihood of successful appointments.",
  newAudienceApproach: "New Audience Approach",
  weEmployInnovativeStrategies:
    "We employ innovative strategies to reach new audiences and expand your business's reach beyond traditional channels.",
  targetedOutreachCampaigns:
    "Targeted outreach campaigns are crafted based on thorough audience research and segmentation, ensuring relevance and effectiveness.",
  ourApproachIsContinuouslyOptimized:
    "Our approach is continuously optimized based on feedback and insights, ensuring that we stay ahead of the curve in reaching and engaging new audiences.",
  individualIndustry: "Individual Industry Appointment Exposure and Reaction",
  personalizedAppointmentSetting:
    "Personalized appointment setting with industry stakeholders.",
  strategicFollowupCommunication:
    "Strategic follow-up communication for further engagement.",
  comprehensivePlanning: "Comprehensive planning and execution of events.",
  utilizationOfAdvancedTechnologies:
    "Utilization of advanced technologies for enhanced interaction.",
  newProspectWarmUp: "New Prospect Warm-Up",
  proactiveEngagementStrategy:
    "Proactive engagement strategy tailored to prospects.",
  customizedCommunication: "Customized communication to maintain interest.",
  newAudienceApproach: "New Audience Approach",
  innovativeOutreach:
    "Innovative outreach campaigns designed for new audiences.",
  continuousRefinement: "Continuous refinement based on feedback and insights.",
  customService: "Custom Service",
  tailoredToMeet:
    "Tailored to meet your unique needs, our Custom Service offering provides flexibility and adaptability to address a wide range of requirements. Whether you're seeking assistance with webinar participant tracking, offline event promotion, ads enhancement, or any other specialized task, our dedicated team is here to help you achieve your goals efficiently and effectively.",
  webinarParticipantsTracking: "Webinar Participants Tracking",
  comprehensiveTrackingOfWebinar:
    "Comprehensive tracking of webinar participants to analyze engagement levels and gather valuable insights.",
  customizedReportsAndAnalytics:
    "Customized reports and analytics to assess the effectiveness of your webinars and optimize future strategies.",
  seamlessIntegration:
    "Seamless integration with your existing systems for streamlined data management and reporting.",
  offlineEventPromotion: "Offline Event Promotion",
  strategicPlanning:
    "Strategic planning and execution of offline event promotion campaigns to maximize attendance and engagement.",
  utilizationOfTargetedMarketingChannels:
    "Utilization of targeted marketing channels and promotional tactics to reach your desired audience effectively.",
  creativeApproaches:
    "Creative approaches tailored to the unique characteristics of each event, ensuring maximum impact and ROI.",
  adsEnhancement: "Ads Enhancement",
  optimizationOfAdCampaigns:
    "Optimization of ad campaigns across various platforms to enhance visibility, engagement, and conversion rates.",
  ABTesting:
    "A/B testing and performance analysis to identify the most effective ad creatives, messaging, and targeting strategies.",
  continuousMonitoring:
    "Continuous monitoring and adjustment of ad campaigns to adapt to changing market dynamics and maximize results.",
  customizedSolutions: "Customized Solutions",
  ourCustomService:
    "Our Custom Service offering extends beyond predefined features to address your specific requirements and preferences.",
  whetherYouNeedAssistance:
    "Whether you need assistance with data analysis, content creation, strategic planning, or any other aspect of your business, we can tailor our services to suit your needs.",
  ourFlexibleApproach:
    "Our flexible approach allows us to collaborate closely with you to develop customized solutions that deliver tangible results and drive business growth.",
  setupAndImplementation:
    "Setup and implementation of webinar participant tracking systems.",
  customizedReporting:
    "Customized reporting and analytics to measure webinar performance and audience engagement.",
  developmentAndExecution:
    "Development and execution of targeted offline event promotion campaigns.",
  coordinationOfPromotionalActivities:
    "Coordination of promotional activities, including print materials, direct mail, and local advertising.",
  evaluationAndOptimization:
    "Evaluation and optimization of ad campaigns across digital platforms.",
  creativeDevelopment:
    "Creative development, targeting refinement, and performance monitoring to maximize ROI.",
  tailoredServices:
    "Tailored services to address specific needs and objectives.",
  collaborationWithOurTeam:
    "Collaboration with our team to develop customized solutions that meet your unique requirements.",
  serviceGetStarted: "Get Started",
  keyFeatures: "Key Features",
  dataEnrichment: "Data Enrichment",
  dataCleansing: "Data Cleansing",
  customListGeneration: "Custom List Generation",
  serviceOfferings: "Service Offerings",
  introDataPreparation:
    "Our Data Preparation service provides comprehensive solutions tailored to optimize your sales data management. We focus ...",
  introSalesAssitant:
    "The Sales Assistant service goes beyond general administrative support by specifically focusing on tasks integral to the sales process. It ...",
  introAppointmentSetting:
    "This service involves a personalized and hands-on approach to connecting customers with their target audience based on the provid ...",
  introOtherService:
    'The "Others" service, which allows customers to request custom services by submitting details about their unique needs, falls unde ...',
  questionCommonPagePriorityLevelTitle:
    "What is your priority level for this task?",
  questionCommonPagePriorityLevelDesc:
    "Stating your task priority helps us address your needs promptly. You can change it post-submission.",
  questionCommonPageTimelineTitle:
    "Thinking about a subscription term or timeline?",
  questionCommonPageTimelineDesc:
    "Let us know your preferred timeframe and when you plan to start.",
  questionCommonPageServiceRequestTitle:
    "Ready to start? First, give your application a distinctive name",
  questionCommonPageServiceRequestDesc:
    "We'll use it to track and update you on the progress in your dashboard.",
  questionCommonPageServiceDurationTitle:
    "How long will your service period last?",
  quesitonCommonPageInvestmentBudget:
    "Does your investment budget for business development and projects exceed HKD 12000 per month?",
  questionCommonPageHearAboutUs: "Where did you hear about us?",
  questionCommonPageUploadTitle:
    "Please upload your Excel/CSV file here for data enrichment or data cleansing",
  questionCommonPageUploadDesc:
    "You may skip or click 'Next' without uploading if you choose to create a custom list generation",
  questionDataPreparationPageInterestedDataOptimizationTitle:
    "Which services are you interested in for your data optimization?",
  questionDataPreparationPageInterestedDataOptimizationDesc:
    "You are required to attach a CSV file in the next step for choosing either data enrichment or data cleansing",
  questionDataPreparationPageDataAttributesTitle:
    "Choose the specific data attributes you wish to incorporate into the file (if applicable)",
  questionDataPreparationPageDataAttributesDesc: "Select one or more",
  questionDataPreparationPageNumberOfLeadsTitle:
    "How many leads are you looking to generate or acquire for your business?",
  questionDataPreparationPageNumberOfLeadsDesc:
    "Specify the quantity or range to customize our services for you",
  website_url: "website url",
  contact_first_name: "contact first name",
  contact_last_name: "contact last name",
  contact_title: "contact title",
  contact_location: "contact location",
  contact_work_email: "contact work email",
  contact_personal_email: "contact personal email",
  contact_work_phone: "contact work phone",
  contact_personal_phone: "contact personal phone",
  company_size: "company size",
  company_industry: "company industry",
  company_location: "company location",
  company_founded_year: "company founded year",
  company_keywords: "company keywords",
  company_ranking_in_global: "company ranking in global",
  company_financial_status: "company financial status",
  company_office_address: "company office address",
  website_total_visit: "website total visit",
  company_funding_status: "company funding status",
  slugServiceName: "Service Name",
  slugServiceDesc: "Service Description",
  errEnterServiceName: "please enter service name",
  errEnterDescription: "please enter service description",
  errSelectAService: "please select a service",
  errSelectSpecificDataAttributes: "please select specific data attributes",
  errInputTheQuantity: "please input the quantity",
  errSelectPriorityLevel: "please select your priority level",
  errSelectTimeline: "please select timeline",
  errInputContent: "please input content",
  errInvestmentBudget: "please select yes or no",
  errServiceDuration: "please select service duration",
  errHearAboutUs: "please select content",
  placeHolderEnterServiceName: "Enter service name",
  placeHolderEnterServiceDescription: "Enter service description",
  placeHolderEnterListGeneration: "Enter list generation",
  placeHolder150TO250: "150 - 250",
  Submit: "Submit",
  serviceDownload: "Download",
  serviceExample: "example",
  forYourReference: "for your reference",
  yourFileNeedsToInclude:
    "Your file needs to include at least websites or company names",
  includeAll: "Include all",
  serviceNext: "Next",
  serviceBack: "Back",
  data_enrichment: "Data Enrichment",
  data_cleansing: "Data Cleansing",
  custom_list_generation: "Custom List Generation",
  job_title: "Job Title",
  timeline_within_one_weeks: "Timeline within one weeks",
  timeline_one_to_two_weeks: "Timeline one to two weeks",
  timeline_two_to_four_weeks: "Timeline two to four weeks",
  timeline_recurring: "Timeline recurring",
  timeline_custom_range: "Timeline custom range",
  hear_about_us_social_media: "social media",
  hear_about_us_google: "google",
  hear_about_us_online_article: "online article",
  hear_about_us_webinar: "webinar",
  hear_about_us_referral: "referral",
  hear_about_us_custom_input: "others",
  duration_last_than_one_month: "last than one month",
  duration_one_to_three_months: "one to three months",
  duration_over_three_months: "over three months",
  duration_decide_later: "decide later",
  yes: "Yes",
  no: "No",
  byCancelingYouCanEnsureThatNoTasksWillBeClosed:
    "By canceling, you ensure that no tasks will be closed",
  areYouSureYouWantToCloseThisTask: "Are you sure you want to close this task?",
  onceTheTaskIsClosed:
    "Once the task is closed, it cannot be undone, and you won't be able to resume it",
  Close: "Close",
  ourTeamIsWorkingOnThisTask: "Our team is working on this task",
  pleaseProviceYourReasonForThisAction:
    "Please provide your reason for this action",
  sendRequest: "Send Request",
  OK: "OK",
  areYouSureYouWantToDelete: "Are you sure you want to delete",
  proceedingWithDeletionWillResultInPermanentRemovalOfTheFile:
    "Proceeding with deletion will result in permanent removal of file.",
  byCancelYouEnsureThatNoFileWillBeDeleted:
    "By canceling, you ensure that no file will be deleted",
  areYouSureYouWantToDeleteThis: "Are you sure you want to delete this",
  serviceMessage: "message",
  serviceFile: "file",
  proceedingWithDeletionWillResultInPermanentRemovalOfThisMessage:
    "Proceeding with deletion will result in permanent removal of this message.",
  proceedingWithDeletionWillResultInPermanentRemovalOfThisFile:
    "Proceeding with deletion will result in permanent removal of this file.",
  youEnsureThatNoMessagesWillBeDeleted:
    "By canceling, you ensure that no messages will be deleted",
  youEnsureThatNoFilessWillBeDeleted:
    "By canceling, you ensure that no files will be deleted",
  serviceDeleteFail: "Delete failed",
  enterYourReason: "Enter your reason",
  seriveStartDate: "Start Date",
  serviceEndDate: "End Date",
  yourRequestHasBeenSent: "Your request has been sent",
  sendUsYourFeedback: "Send us your feedback",
  weAreConstantlyImproving:
    "We're constantly improving! Share your feedback to shape the future of our platform.",
  describeYourExperienceHere: "Describe your experience here...",
  additionallyYouCanAttachAnyScreenshots:
    "Additionally, you can attach any screenshots or files to provide further details.",
  sendFeedback: "Send Feedback",
  serviceResume: "Resume",
  yourRequestHasBeenResumed: "Your request has been resumed",
  yourRequestIsCurrentlyPaused: "Your request is currently paused",
  serviceEdited: "Edited",
  theFileSizeCannotExceed50M: "The file size cannot exceed 50M",
};