import { notificationEnglish } from "./notification";
import sequence from "./sequence/en";
import base from "./base/en";
import search from "./search/en";
import services from "./services/en";
import intent from "./intent/en";
import tableEmptyInfomationEnglish from "./common/table-empty-infomation/en";
import globalMessageEn from "./common/global-message/en";
import settingApiKeysEn from "./settings/api-keys/en";
import pricing from './settings/pricing/en';
import home from './home/en'

export default {
  //login
  loading: "Loading...",
  SignUp: "Sign Up",
  LoginSuccess: "Logged in successfully",
  LoginOut: "Logged out successfully",
  email: "Email",
  password: "Password",
  logIn: "Log In",
  WelcomeBack: "Welcome Back",
  PleaseEnterYourEmailAndPassword: "Please enter your email and password",
  CharactersRequired: "8+ characters required",
  keepMeSignedIn: "Keep me signed in",
  forgetPassword: "Forget password",
  dontHaveAnAccount: "Do not have an account? ",
  signUpNow: "Sign Up Now",
  AllRightsReserved: "All Rights Reserved",
  privacyPolicy: "Privacy Policy",
  and: "and",
  termsOfService: "Terms of Service",
  Name: "Name",
  viewAll: "view all",
  fold: "fold",
  updateCreditCard: "Update Credit Card",
  role: "Role",
  noLimit: "No Limit",

  //signup
  company: "Company",
  companyName: "Company Name",
  signUp: "Sign Up",
  alreadyHaveAnAccount: "Already have an account? ",
  getStarted: "Get started! Empower your business with our platform",
  signUpSuccess: "Signed up successfully",

  //foget
  noWorries:
    "No worries, enter your registered email, and we will send you reset instructions",

  //reset
  reset: "Reset",
  forgotPassword: "Forgot Password",
  RequestPasswordReset: "Request Password Reset",
  backToLogin: "Back to Login",
  verifyPassword: "Verify Password",
  EnterANewPasswordFor: "Enter a new password for example@gmail.com",
  ResetPassword: "Reset Password",
  newPassword: "New Password",


  //account
  addCreditCard: "Add Credit Card",
  zip: "Zip",
  country: "Country",
  state: "State",
  city: "City",
  address: "Address",
  billingInformation: "Billing Information",
  yourTeams: "Your Teams",
  cvc: "CVC",
  year: "year",
  month: "month",
  expirationDate: "Expiration Date",
  creditCardNumber: "Credit Card Number",
  cardInformation: "Card Information",
  title: "Title",
  fullName: "Full Name",
  yourDetail: "Your Detail",
  creditsToUse: "credits to use.",
  youHave: "You have",
  download: "Download",
  buyMoreCredits: "Buy more Credits",
  description: "Description",
  topUpCredits: "Top Up Credits",
  cancel: "cancel",
  date: "Date",
  invoiceHistory: "Invoice History",
  confirmNewPass: "Confirm new pass",
  creditsUsed: "Credits used",
  newPass: "New pass",
  oldPass: "Old pass",
  cardNumber: "Card Number:",
  changePassword: "Change Password",
  creditUsage: "Credit Usage",
  typeTheNewTitle: "Type the new title",
  save: "Save",
  addMoreCredits: "Add more credits",
  featureRequest: "Feature Request",
  creditsMo: "{credit} Credits / mo",
  apiIntegrations: "API Integrations",
  profileSubscription: "Profile Subscription",
  "additional-user-licence": "Additional User Licence",
  "agency-white-label": "Agency White Label",
  "api-extended-limit": "API Extended Limit",
  "profile-subscription": "Profile Subscription",
  creditsInYourPlan: "Credits (In your Plan)",
  plugin: "Plugin",
  settings: "SETTINGS",
  phone: "Phone",
  "1User": "1 user",
  users: "Users",
  billing: "Billing",
  monthlyQueryLimit: "Monthly Query Limit",
  price: "Price",
  whatsIncluded: "What''s Included",
  givenName: "Given Name",
  profile_teams: "Profile & Teams",
  purchasePlan: "Purchase Plan",
  accountOverview: "ACCOUNT OVERVIEW",
  familyName: "Family Name",
  dailyQueryLimit: "Daily Query Limit",
  rateLimit: "Rate Limit",
  youAreOnA: "You are on a",
  PlanAndYourCreditsWillRefreshOn: "plan and your credits will refresh on",
  过期: "",
  searchSettings: "Search Settings...",
  plan: "Plan",
  accountInfo: "Account Info",
  creditCardInformation: "Credit Card Information",
  apiUsage: "API Usage",
  mo: "/mo",
  perUserPerMonth: "per User Per Month",
  billedAnnually: "Billed Annually",
  dailyQuery: "Daily Query",
  monthlyQuery: "MonthlyQuery",
  currentPlan: "Current Plan",
  everythingInPlus: "Everything In Plus",
  mostPopular: "mostPopular",
  topUpToIncreaseYourCredits: "Top up to increase your credits",
  addCredits: "Add Credits",
  allPlansInclude: "All Plans Include",
  Amount: "Amount",
  credits: "Credits",
  purchaseSuccess: "Purchased credits successfully, your total credits are ",
  Loading: "Loading",
  Selected: "Selected",
  SelectPlan: "Select Plan",
  currentPlan: "Current Subscription",
  upgradeSuccess: "Plan switched successfully",
  purchaseFailed: "Purchase failed",
  upgradeFailed: "Plan switch failed",
  inviteSentSuccessfully: "Invite sent successfully",
  DeleteSuccessfully: "Deleted Successfully",
  AccountupdatedSuccessfully: "Account updated successfully",
  AccountUpdateFailed: "Account Updated Failed",
  PasswordUpdatedSuccessfully: "Password updated successfully",
  PasswordUpdateFailed: "Password Updated Failed",
  teamDetails: "Team Details",
  teamName: "Team Name",
  changeTeam: "Change Team",
  clickToChangeTeam: "Click to change team",
  changeTeamSuccessfully: "Changed team successfully",
  status: "Status",
  Action: "Action",
  pending: "Pending",
  current: "Current",
  AreYouSureYouWantToDeleteThisCreditCard:
    "Are you sure you want to delete this credit card?",
  AreYouSureYouWantToDeleteThisTeamMember:
    "Are you sure you want to delete this team member?",
  PasswordCannotBeEmpty: "Password cannot be empty",
  newUser: "New User",
  deleteUser: "Delete User",
  nickname: "Nickname",
  admin: "Admin",
  inviteYourTeam: "Invite Your Team",
  empowerEveryoneToGetMoreDealsDonefaster:
    "Empower everyone to get more deals done faster",
  addMultipleEmailAddressesSeparatedByCommas:
    "Add multiple email addresses separated by commas",
  sendInvites: "Send Invites",
  FirstName: "First Name",
  LastName: "Last Name",
  timezone: "Timezone",
  contactNo: "Contact No",
  controlYourTeamMembersAndTheirAccountAccessRightHere:
    "Control your team members and their account access right here.",
  pleaseEnterYourCurrentPasswordToChangeYourPassword:
    "Please enter your current password to change your password.",
  updatePassword: "Update Password",
  oldPassword: "Old Password",
  confirmNewPassword: "Confirm New Password",
  InviteYourTeam: "Invite Your Team",
  empowerEveryoneToGetMoreDealsDoneFaster:
    "Empower everyone to get more deals done faster.",
  enterEmailAddressesMultipleCommaseparated:
    "Enter email addresses (multiple comma-separated)",
  invoice: "Invoice",
  accountAdmin: "Account Admin",
  setAsDefault: "Set as Default",
  bankName: "Bank Name",
  expiry: "Expiry",
  viewAllYourPreviousInvoicesHere: "View all your previous invoices here.",
  BillingHistory: "Billing History",
  cardInfo: "Payment Methods",
  updateYourCreditCardInformation: "Update your credit card information.",
  ManageCreditCard: "Manage Credit Card",
  CardNumber: "Card Number",
  billingAddress: "Billing Address",
  yourCreditsWillRefreshOn: "Your credits will refresh on",
  yourCreditsWillBeChargeOn: "Your credits will be charge on",
  被收取: "",
  perYear: "/year",
  perMonth: "/month",
  monthlyCredit: "Monthly Credit",
  Usage: "Usage",
  features: "Features",
  credits_mo: "Credits/mo",
  credits_year: "Credits/year",
  _mo: "/mo",
  Quantity: "Quantity",
  seeOtherPlan: "See Other Plans",
  subscriptionTiers: "Subscription Tiers",
  experiencePricingThatsEasyToUnderstandAndAdaptable:
    "Experience pricing that's easy to understand and adaptable to your needs.",
  monthlyBilling: "Monthly Billing",
  annualBilling: "Annual Billing",
  forPlanCustomizationContactOurSalesTeam:
    "For plan customization, contact our sales team",
  billedAnnually_user: "Billed Annually/user",
  seePlanComparison: "See Plan Comparison",
  topUp: "Top Up",
  changeTeamNameFailed: "Change team name failed",

  // purchaseCreditsAtTheRateOf100PerCreditCustomize: 'Purchase credits at the rate of $1.00 per credit. Customize your credit bundle based on your needs. ',
  totalPrice: "Total Price",
  credit: "Credit",
  collapse: "Collapse",
  expand: "Expand",

  // analytic
  showNumber: "show number",
  Website: "Website",
  b2bTraffic: "B2B Traffic",
  b2bReturningVsNew: "B2B Returning VS New Visitors",
  visitors: "Visitors",
  totalView: "Total View",
  totalSessions: "Total Sessions",
  operatingSystem: "Operating System",
  browser: "Browser",
  device: "Device",
  companySize: "Company Size",
  topIndustry: "Top Industries",
  topCountries: "Top Countries",
  topReferrals: "Top Referrals",
  new: "New",
  returning: "Returning",
  countriesStatistics: "Countries Statistics",
  topCompaigns: "Top Campaigns",
  topPagesByVisits: "Top Pages by Visits",
  topPagesByEngagement: "Top Pages by Engagement",
  Referrals: "Referrals",
  Pages: "Pages",
  avgTime: "Avg Time",
  totalCompanies: "TOTAL COMPANIES",
  b2bPageViews: "B2B PAGE VIEWS",
  TotalSessions: "TOTAL SESSIONS",
  viewCompanyList: "View Companies List",
  noDiscussionsYet: "No Discussions Yet",
  useThisSpaceToAskQuestions:
    "You can use this space to ask questions, get updates, and discuss details directly with us",
  // mycontact
  prospects: "Prospects",
  list: "List",
  view: "View",
  add: "Add",
  newList: "New List",
  savedLists: "Saved Lists",
  searchList: "Search list name",
  owner: "Owner",
  records: "Records",
  actions: "Actions",
  lastModified: "Last Modified",
  name: "name",
  enterAListName: "Enter a list name",
  pleaseSelectAListFirst: "please select a list first",
  saveSuccessfully: "Saved Successfully",
  pleaseEnterAListName: "please enter a list name",
  editList: "Edit List",
  fromThisList: "from this list. Are you sure you want to continue",
  areYouSureYouWantToChange: "Are you sure you want to change the list type to",
  // yes: 'Yes',
  noListCreated: "No List Created",
  enhanceTheOrganization:
    "Enhance the organization of your contacts and accounts within your target market. After creating a list, they will appear on this page.",
  createSuccessfully: "Created Successfully",
  removeSuccessfully: "Removed Successfully",
  createNewList: "Create New List",
  groupTeam: "Team",
  groupPersonal: "Personal",
  edit: "Edit",
  areYouSureYouWantToDeleteTheList: "Are you sure you want to delete the list?",
  proceedingWithDeletion:
    "Proceeding with deletion will result in permanent removal of the list.",
  byCanceling: "By canceling, you ensure that no lists will be removed",
  byCancelingYouEnsureThatNo: "By canceling, you ensure that no",
  willBeRemoved: "will be removed",
  groupPeople: "people",
  groupCompany: "company",
  myList: "My List",
  teamList: "Team's List",
  resetAssured:
    "Rest assured, you have the flexibility to switch between different types as needed.",
  wontBeChanged:
    "By canceling, you ensure that this list type won't be changed",
  priority: "Priority",
  proceedingWithDeletionWillResultInPeopleDelete:
    "Proceeding with deletion will result in permanent removal of the people",
  proceedingWithDeletionWillResultInCompanyDelete:
    "Proceeding with deletion will result in permanent removal of the company",
  areYouSureYouWantToRemoveCompanyFromList: "1",
  areYouSureYouWantToRemovePeopleFromList: "2",
  groupExisted: "group already exists",
  sorryTheListIsCurrrntlyOpen:
    "Sorry, the list is currently open. Are you sure you want to delete it?",
  listName: "Name",
  pleaseEnterAValidSiteUrl: "Please enter a valid site url",

  // market-intent
  comingSoon: "COMING SOON",
  weAreCurrentlyWorkingOnThisFeature:
    "We are currently working on this feature and will launch soon",
  stayConnectedWithUs:
    "Stay connected with us through our social media channels to receive updates.",
  selectAWebsite: "Select a website",

  // sequence
  Sequences: "Sequences",
  label: "Label",
  template: "Template",
  folder: "Folder",
  single: "Single",
  group: "Group",
  steps: "Steps",
  sizes: "Sizes",
  time: "Time",
  //email
  SendATestEmail: "Send a Test Email",
  Categories: "Categories",
  addToCategory: "Add to Category",
  EnterOrCreateCategories: "Enter or create categories",
  addSuccessfully: "Added Successfully",
  Channels: "Channels",
  emails: "Emails",
  enterChannel: "Enter Channel",
  CategoryName: "Category Name",
  PleaseEnterANewCategoryName: "Please enter a new category name:",
  EnterANewCategoryName: "Enter a new category name",
  Steps: "Steps",
  Actions: "Actions",
  Time: "Time",
  From: "From",
  To: "To",
  CancelSequenceCreation: "Cancel Sequence Creation",
  AreYouSureYouWantToCancelTheSequenceCreationProcess:
    "Are you sure you want to cancel the sequence creation process?",
  SaveAsDraft: "Save as Draft",
  CancelSequence: "Cancel Sequence",
  ByCancelingAllSettingsWillBeLost:
    "By canceling, all the settings and progress you've made will be lost.",
  ImportContacts: "Import Contacts",
  PleaseUploadACSVFileByDraggingAndDroppingItHere:
    "Please upload a CSV file by dragging and dropping it here",
  ChooseAFile: "Choose a file",
  CSVFileFields: "CSV File Fields",
  ReplyFields: "Reply Fields",
  continue: "Continue",
  Contacts: "Contacts",
  Back: "Back",
  Import: "Import",
  Imported: "Imported",
  Skipped: "Skipped",
  Done: "Done",
  NewCustomField: "New Custom Field",
  EnterNewLabel: "Enter new label",
  EnterNewType: "Enter new type",
  removeRecorded: "Remove Recorded",
  YouHaveUnsavedChanges: "You have unsaved changes",
  LeavingDiscard:
    "Leaving this page will discard any unsaved content. Are you sure you want to continue?",
  StayPage: "Stay on Page",
  LeavePage: "Leave Page",
  NewCategory: "New Category",
  Create: "Create",
  UnlockingSequence:
    "Unlocking this sequence enables you to make modifications",
  ModifyingLeadDeselection:
    "Modifying this content will lead to the deselection of the current template version",
  Modify: "Modify",
  CancelingPreserveCurrent: "Canceling will preserve the current version",
  rename: "Rename",
  CreatNewLabel: "Create New Label",
  NewLabel: "New Label",
  PleaseEnterANewLabelName: "Please enter a new label name:",
  EnterANewLabelName: "Enter a new label name",
  SequenceName: "Sequence Name",
  PleaseProvideNameForNewSequence:
    "Please provide a name for your new sequence",
  EnterNewSequenceName: "Enter new sequence name",
  SetUpEmail: "Set up an email account for sending",
  NewSchedule: "New Schedule",
  PleaseEnterANewScheduleName: "Please enter a new schedule name:",
  EnterNewScheduleName: "Enter new schedule name",
  AddNewTemplate: "Add New Template",
  RemoveFromTemplate: "Remove From Template",
  deleteSuccess: "Deleted successfully",
  removeSuccess: "Removed successfully",
  GroupName: "Group Name",
  PleaseEnterNewGroupName: "Please enter new group name",
  EnterNewGroupName: "Enter new group name",
  TemplateName: "Template Name",
  PleaseEnterANewTemplateName: "Please enter a new template name",
  EnterNewTemplateName: "Enter new template name",
  renameSuccessfully: "Renamed Successfully",
  CreateSuccessfully: "Created Successfully",
  addToGroup: "Add to Group",
  SelectChannelForNewTemplate: "Select channel for new template",
  Role: "Role",
  Account: "Account",
  customFields: "Custom Fields",
  contactsToStartEngagingWithThem: "contacts to start engaging with them.",
  thisSequenceDoesntHaveAnyProspectsOrLeadsYetConsid:
    "This sequence doesn''t have any prospects or leads yet. Consider adding",
  noProspectsAdded: "No prospects added",
  deliveryCurrentlyNoMessagesHaveBeenSentThroughTheS:
    "delivery. Currently, no messages have been sent through the sequence.",
  ensureEfficientCommunicationByMonitoringYourSequen:
    "Ensure efficient communication by monitoring your sequence''s message",
  noMessagesHaveBeenSent: "No messages have been sent",
  yourNeedsGetStartedByClickingNewSequence:
    "your needs. Get started by clicking [+ New Sequence]",
  beginBuildingYourSalesStrategiesByDesigningSequenc:
    "Begin building your sales strategies by designing sequences tailored to",
  noSequencesCreatedYet: "No Sequences Created Yet",
  leadActions: "Lead actions",
  leadInformation: "Lead information",
  thisGroupHasNoTemplatesYet: "This group has no templates yet",
  key: "{expr}",
  copyContent: "Copy content",
  default: "Default",
  selectVersion: "Select version",
  subject: "Subject",
  searchTemplatesfolders: "Search templates/folders",
  send: "Send",
  subject2: "Subject:",
  enterASubjectOrLeaveItBlankToContinueInTheSameThre:
    "Enter a subject or leave it blank to continue in the same thread",
  chooseYourChannel: "choose your channel",
  kl: "KL",
  addContacts: "Add Contacts",
  addContactsToSequence: "Add contacts to sequence",
  addremoveCustomContactField: "Add/Remove Custom Contact Field",
  contactCustomFields: "Contact Custom Fields",
  type: "Type",
  typePhoneNumber: "Type phone number",
  addAPhoneNumber: "Add a Phone Number",
  fieldType: "Field Type",
  fieldLabel: "Field Label",
  skipTheFollowingNationalHolidaysLaborDayIndependen:
    "Skip the following national holidays: Labor Day, Independence Day, Memorial Day, Thanksgiving, Christmas Eve, Christmas, New Year''s Day",
  checked: "checked",
  useTheContactsLocalTimeZoneInsteadOfTheSchedulesTi:
    "Use the contact''s local time zone instead of the schedule''s time zone, if the contact contains location data.",
  definePostreplyOrMeetingActionAndSequenceCompletio:
    "Define post-reply or meeting action and sequence completion criteria",
  stopRepliesHandling: "Stop Replies Handling",
  timeZone: "Time Zone:",
  country2: "country",
  setMaxInitialEmailsDelaysEnabledisableOpensAndLink:
    "Set max initial emails, delays, enable/disable opens and link tracking",
  configuration: "Configuration",
  additionalInformation: "Additional Information",
  connectALinkedinAccount: "Connect a linkedin account",
  newLinkedinAccount: "New LinkedIn account",
  feelFreeToRevisitAnyOfThePreviousStepsAtAnyTimeToR:
    "Feel free to revisit any of the previous steps at any time to review or update your information before you ''Launch'' the sequence.",
  template2: "Template:",
  next: "Next",
  jacquelineLeung: "Jacqueline Leung",
  settings2: "settings",
  editThisTemplateScheduleFromThe: "Edit this template schedule from the",
  typeEmail: "Type Email",
  templateScheduling: "Template Scheduling",
  launch: "Launch",
  linkedin: "Linkedin",
  addAEmail: "Add a Email",
  addEmailAccount: "Add email account",
  addStep: "Add Step",
  newEmailAccount: "New Email account",
  craftYourLeadgenerationJourneyWithANewSequenceDefi:
    "Craft your lead-generation journey with a new sequence! Define your target audience, messages, and actions to create effective lead generation sequences.",
  craftYourCampaign: "Craft Your Campaign",
  tailorYourOwnHoursToSuitSpecificSchedulingNeeds:
    "Tailor your own hours to suit specific scheduling needs.",
  personalizedScheduling: "Personalized Scheduling",
  requiredInformation: "Required Information",
  chooseEmailAccountsForSendingCampaignMessages:
    "Choose email accounts for sending campaign messages",
  accounts: "Accounts",
  enterSequenceName: "Enter sequence name",
  thisPageEmpowersYouToTailorYourSchedulingSequenceS:
    "This page empowers you to tailor your scheduling sequence settings to match your preferences",
  schedule: "Schedule",
  useThisOptionToAddContactsToTheSequenceYouHaveCrea:
    "Use this option to add contacts to the sequence you have created.",
  addProspects: "Add Prospects",
  save2: "save",
  days: "days",
  days2: "days",
  within: "within",
  opened: "Opened",
  day: "day",
  day2: "day",
  waitFor: "Wait for",
  sendTestEmailToMe: "Send test email to me",
  create: "Create",
  enterANameForCustomContactField: "Enter a name for custom contact field",
  a: "A",
  byCancelingYouEnsureThatNoStepsWillBeDeleted:
    "By canceling, you ensure that no steps will be deleted",
  to2: "to",
  enterNameForNewVersion: "Enter name for new version",
  activated: "Activated",
  currentVersion: "Current Version",
  setTheNewVersionDefaultNow: "Set the new version default now",
  enterNewName: "Enter new name",
  newVersion: "New Version",
  dontForgetToSetTheNewVersionDefaultOnceYouveFinish:
    "Don''t forget to set the new version default once you''ve finished modifying it or",
  theDeletionOfThisConditionWillAlsoRemoveAllSubsequ:
    "The deletion of this condition will also remove all subsequent steps",
  pleaseEnterANewName2: "Please enter a new name:",
  aNewVersionOfTheSequenceHasBeenCreated:
    "A new version of the sequence has been created.",
  youCanEitherEditTheCurrentVersionOrSaveItAndCreate:
    "You can either edit the current version or save it and create a new one.",
  areYouSureYouWantToProceed: "Are you sure you want to proceed?",
  newVersionName: "New Version Name",
  sequenceStart: "Sequence start",
  chooseActions: "choose actions",
  chooseSteps: "choose steps",
  enterChannel2: "enter Channel",
  chooseCategories: "choose categories",
  htmlMode: "HTML Mode",
  considerBroadeningYourSearchCriteria:
    "- Consider broadening your search criteria.",
  checkYourSpellingAndMakeSureThereAreNoTypos:
    "- Check your spelling and make sure there are no typos.",
  tryUsingDifferentKeywordsOrPhrases:
    "- Try using different keywords or phrases.",
  noResultsFoundForYourSearchCriteria:
    "No results found for your search criteria",
  fromScratch: "From Scratch",
  sender: "Sender",
  finished: "Finished",
  inProgress: "In Progress",
  fromTemplate: "From Template",
  newSequence: "New Sequence",
  nameOwner: "Name owner",
  sort: "Sort",
  ownedBy: "Owned by",
  starredOnly: "Starred Only",
  starred: "Starred",
  box2: "Box 2",
  box1: "Box 1",
  add2: "+ Add",
  lastSynced: "Last Synced",
  hourlyLimit: "Hourly Limit",
  dailyLimit: "Daily Limit",
  account: "account",
  skipTheFollowingNationalHolidaysLaborDayIndependen2:
    "Skip the following national holidays: Labor Day, Independence Day, Memorial Day, Thanksgiving, Christmas Eve, Christmas, New Year''s Day",
  useTheContactsLocalTimeZoneInsteadOfTheSchedulesTi2:
    "Use the contact''s local time zone instead of the schedule''s time zone, if the contact contains location data.",
  saveChanges: "Save changes",
  emailSignature: "Email signature",
  reset2: "Reset",
  pleaseNoteTheValueShouldBe0OrEmails:
    "Please note the value should be > 0 or < {expr}",
  youHaveNoScheduledTemplateYet: "You have no scheduled template yet",
  discoverPremiumOptions: "Discover Premium Options",
  mailbox: "Mailbox",
  sendingLimits: "Sending limits",
  connect: "Connect",
  joinOurCommunityOfPremiumUsersTransformingEmailEng:
    "Join our community of premium users transforming email engagement and achieving outstanding sales outcomes through Pubrio.",
  enterYourEmailAddress: "Enter your email address",
  toJustMailbox: "to just {mailNum} mailbox",
  yourCurrentBasicPlanAllowsAccess: "Your current Basic plan allows access",
  setAsDefault2: "Set as default",
  createNewSchedule: "Create new schedule",
  enterTemplateName: "Enter template name",
  googleMeet: "Google Meet",
  emailPreview: "Email Preview",
  addTemplate: "Add Template",
  simplifyYourWorkflowGroupYourSequenceTemplatesToKe:
    "Simplify your workflow! Group your sequence templates to keep them organized, making it easy to find, edit, and use them as needed.",
  startCreatingPersonalizedTemplatesForYourSequences:
    "Start creating personalized templates for your sequences! Click the ''+'' button to create a new template and streamline your communication effortlessly.",
  noTemplateYet: "No Template Yet",
  preview: "Preview",
  creatNewGroup: "Create New Group",
  creatNewGroup2: "Create new group",
  stayOrganizedWithYourSequenceTemplatesCreateGroups:
    "Stay organized with your sequence templates! Create groups to effortlessly manage and locate your templates, making editing and utilization even simpler for you.",
  noGroupsHaveBeenCreated: "No groups have been created",
  creatNewTemplate: "Create new template",
  addSequenceTemplateToTheGroup: "Add sequence template to the group",
  byCancelingYouEnsureThatNoChangesAreMade:
    "By canceling, you ensure that no changes are made",
  sequenceTemplates: "Sequence Templates",
  youCanDeselectAndClickSaveToRemoveTheTemplateFromT:
    "You can deselect and click ''Save'' to remove the template from the group",
  selectTemplateToAddToGroup: "Select template to add to group",
  sequenceStatus: "Sequence Status",
  sequenceStep: "Sequence Step",
  newField: "New Field",
  enableTailoredDataEntryAndAdaptationToUniqueBusine:
    "Enable tailored data entry and adaptation to unique business processes",
  customContactFields: "Custom contact fields",
  modality: "modality",
  enterADescriptionForCustomContactField:
    "Enter a description for custom contact field",
  description2: "description",
  enterADefaultValueForCustomContactField:
    "Enter a default value for custom contact field",
  defaultValue: "default Value",
  enterAExampleForCustomContactField:
    "Enter a example for custom contact field",
  example: "example",
  enterPicklistoptionsForCustomContactField:
    "Enter picklist_options for custom contact field",
  newCustomContactFields: "New Custom Contact Fields",
  engagementMetrics: "Engagement Metrics",
  message: "Message",
  to3: "To:",
  receiver: "Receiver",
  claraHan: "Clara Han",
  createNewCategory: "Create new category",
  bestRegards: "Best regards,",
  thanksAgain: "Thanks again!",
  lookingForwardToAFruitfulCollaborationIfTheresAnyt:
    "Looking forward to a fruitful collaboration. If there''s anything specific we need to address before we get started, please feel free to let us know.",
  jacquelineLeungProjectInCharge: "Jacqueline Leung (Project in Charge)",
  kingLaiAdviser: "King Lai (Adviser)",
  iAppreciateTheIntroductionToOurMainContacts:
    "I appreciate the introduction to our main contacts:",
  contacted: "Contacted",
  sendTestEmail: "Send test email",
  thankYouSoMuchForTheWarmWelcomeWereEquallyExcitedA:
    "Thank you so much for the warm welcome! We''re equally excited about kicking off our project with Pubrio GTM and being a part of Venture AI''s Team.",
  hiJacqueline: "Hi Jacqueline,",
  nov152023118Pm: "Nov 15, 2023, 1:18 PM",
  message2: "{total_messages} Message",
  topEcommerceAutomationPlatf: "Top E-commerce Automation Platf...",
  cindyHsu: "Cindy Hsu",
  includeSignature: "Include signature",
  ch: "CH",
  continueOnFail: "Continue on fail",
  welcomeToPubrioGtm: "Welcome to Pubrio GTM",
  replyToPreviousThread: "Reply to previous thread",
  sendAutomatic: "Send automatic {title}",
  searchByContacts: "Search by contacts",
  processingErrorOccurredMaximumNumberOfUniqueContac:
    "Processing error occurred: Maximum number of unique contacts per month is reached",
  oct140954Pm: "Oct 14, 09:54 PM",
  shiftdragToMoveTheCanvas: "Shift+drag to move the canvas",
  active: "Active",
  inactive: "Inactive",
  archived: "Archived",
  archive: "Archive",
  unarchive: "Unarchive",
  archivedSuccessfully: "Archived successfully",
  unarchivedSuccessfully: "Unarchived successfully",
  sequenceArchivedSuccess: "sequence archived successfully",
  sequenceUnarchivedSuccess: "sequence unarchived successfully",
  alphabetical: "Alphabetical",
  creationDate: "Creation Date",
  viewDate: "View Date",
  staredFirst: "stared First",
  SaveAsANewVersion: "Save as a new version",
  SaveCurrentVersion: "Save current version",
  schedules: "Schedules",
  EmailsSentPerDay: "Emails Sent Per Day",
  EmailsSentPerHour: "Emails Sent Per Hour",
  DelayBetweenEachEmailSent: "Delay Between Each Email Sent (seconds)",
  RecommendedDailyLimit:
    "Recommended daily limit: 50-100 emails, with exceptions for 100+ only if you're sending highly personalized campaigns and maintain a strong domain reputation.",
  RecommendedHourlyLimit:
    "Recommended hourly limit: Keep the number of emails under 100. Do not exceed 120 emails per hour.",
  currentDelayAllows:
    "The current delay allows you to send a maximum of 120 emails per hour.",
  SetAsDefault: "Set as default",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  Templates: "Templates",
  Analytics: "Analytics",
  Meetings: "Meetings",
  Settings: "Settings",
  EnterTemplateName: "Enter template name",
  EnterGroupName: "Enter group name",
  ExportAllData: "Export All Data",
  ExportAsCSV: "Export as CSV",
  Total: "Total",
  Opened: "Opened",
  Clicked: "Clicked",
  NotOpened: "Not Opened",
  Bounced: "Bounced",
  Replied: "Replied",
  Failed: "Failed",
  Inbox: "Inbox",
  Report: "Report",
  Log: "Log",
  UseThisTemplate: "Use this Template",
  UseThisFolder: "Use this Folder",
  UpdateThisTemplate: "Update this Template",
  RemoveFromGroup: "Remove from Group",
  DeleteThisTemplate: "Delete this Template",
  MarkAsFinished: "Mark as Finished",
  Remove: "Remove",
  Pause: "Pause",
  Resume: "Resume",
  AddToSequence: "Add to Sequence",
  Export: "Export",
  InProgress: "In Progress",
  Finished: "Finished",
  Paused: "Paused",
  Engaged: "Engaged",
  Reply: "Reply",
  NotSent: "Not Sent",
  MaxNumberOfEmailsPerDay: "Max Number of Emails Per Day",
  MaxNumberOfEmailsPerHour: "Max Number of Emails Per Hour",
  EnableOpensTracking: "Enable Opens Tracking",
  EnableReplyTracking: "Enable Reply Tracking",
  EnableClickTracking: "Enable Click Tracking",
  ReplyByMessage: "Reply by Message",
  BookAMeeting: "Book a Meeting",
  ClickOnLink: "Click on Link",
  CreateManually: "Create Manually",
  AddContactsToTheSequenceManually:
    "Add contacts to the sequence manually, one by one.",
  ImportFromCSV: "Import from CSV",
  UploadCSVFileToAddContactsToSequence:
    "Upload a CSV file to add contacts to the sequence.",
  AddFromExisting: "Add from Existing",
  AddExistingContactsIntoTheSequence:
    "Add existing contacts into the sequence.",
  ChooseFile: "Choose File",
  MapAttributes: "Map Attributes",
  Options: "Options",
  ReviewAndImport: "Review and Import",
  UpdateMissingFields: "Update Missing Fields",
  DetectContactTimeZoneAutomatically: "Detect contact time zone automatically",
  account_website: "Account Website",
  linkedin_url: "Linkedin URL",
  raw_address: "Raw Address",
  first_name: "First Name",
  last_name: "Last Name",
  account_name: "Account Name",
  Identifier: "Identifier",
  firstName: "First Name",
  lastName: "Last Name",
  EnterJobTitleOrPosition: "Enter job title or position",
  EnterCompanyName: "Enter company name",
  EnterYourCustomerID: "Enter your customer ID",
  EnterCompanyWebsiteURL: "Enter company website URL",
  EnterLinkedInProfileURL: "Enter LinkedIn profile URL",
  EnterCity: "Enter city",
  versionName: "Version Name",
  PleaseEnterANewVersionName: "Please enter a new version name",
  EnterNewVersionName: "Enter new version name",
  versions: "Versions",
  SequenceNameUpdatedSuccessfully: "Sequence name updated successfully",
  SequenceNameCannotBeEmpty: "Sequence name cannot be empty",

  delete_success: "Deleted successfully",
  delete_fail: "Delete failed",
  set_default_success: "Set default successfully",
  DuplicateVersionSuccessfully: "Duplicated version successfully",
  CreateNewVersionSuccessfully: "Created new version successfully",
  UpdateSuccessfully: "Updated successfully",
  AddToGroupSuccessfully: "Added to group successfully",
  sendAutomaticEmail: "Send automatic email",
  sendMessage: "Send message",
  uploadFailed: "Upload failed",
  InvalidEmailFormat: "Invalid email format",
  FailedToSetUpEmailAccount: "Failed to set up email account",
  noAccount: "No account",
  addAnAccountToPersonalizYourExperienceAndUnlock:
    "Add an account to personalize your experience and unlock premium features ",
  trackingFeaturesForThisSequence: "tracking features for this sequence.",
  UpdatedSuccessfully: "Updated successfully",
  update_fail: "Update failed",
  connectYourGoogleMeetAccountToPubrioConversations:
    "Connect your Google Meet account to Pubrio Conversations",
  set_default_fail: "Set default failed",
  duplicatedSuccessfully: "Duplicated successfully",
  noStepsInSequencePleaseAddStepsFirst:
    "No steps in sequence, Please add steps first",
  addLabelSuccessfully: "Added label successfully",
  PleaseEnterSequenceName: "Please enter a sequence name",
  sequenceNameCannotBeEmpty: "Sequence name cannot be empty",
  sequenceNameUpdatedSuccessfully: "Sequence name updated successfully",
  createStepFlowSuccessfully: "Created step flow successfully",
  updateStepFlowSuccessfully: "Updated step flow successfully",
  testEmailSentSuccessfully: "Test email sent successfully",
  removeEmailAccountSuccessfully: "Removed email account successfully",
  pleaseSelectTheDataToBeExported: "Please select the data to be exported",
  confirm: "Confirm",
  saveFailed: "Save failed",
  deleteThisStep: "Delete this step",
  Email: "Email",
  LinkedIn: "LinkedIn",
  API: "API",
  Task: "Task",
  Custom: "Custom",
  proceedImmediately: "Proceed immediately",
  dayss: "day/s",
  hourss: "hour/s",
  minutess: "minute/s",
  theConditionAndAllTheStepsThatFollowFromItWillBeDeleted:
    "The condition and all the steps that follow from it will be deleted, do you want to continue?",
  sendConnectionRequest: "Send connection request",
  viewProfile: "View profile",
  callAnAPI: "Call an API",
  createAManualTask: "Create a manual task",
  hasEmailAddress: "Has Email address",
  hasLinkedInURL: "Has LinkedIn URL",
  hasPhoneNumber: "Has phone number",
  customCondition: "Custom condition",
  openedEmail: "Opened email",
  clickOnLinkInEmail: "Click on link in Email",
  unsubscribeFromEmail: "Unsubscribe from email",
  bookAMeeting: "Book a meeting",
  acceptedInvite: "Accepted invite",
  conditions: "Conditions",
  Yes: " Yes ",
  No: " No ",
  newVariable: "New Variable",
  selectVariable: "Select Variable",
  ifLeadVariable: "If lead variable ",
  condition: "Condition",
  selectCondition: "Select Condition",
  value: "Value",
  contains: "Contains",
  is_equal_to: "Is equal to",
  is_empty: "Is empty",
  is_not_empty: "Is not empty",
  picture: "Picture",
  company_domain: "Company Domain",
  company_name: "Company Name",
  icebreaker: "Icebreaker",
  change_steps_conditions: "Change Steps/Conditions",
  ForceASpecificSenderForThisStep: "Force a specific sender for this step",
  method: "Method",
  selectASender: "Select a sender",
  assignAPITaskTo: "Assign API task to",
  enterCompanyNamesAndPressEnterKey: "Enter company names and press Enter key",
  feelFreeToEditTheContentAndSaveYourChangesDirectly:
    "Feel free to edit the content and save your changes directly after clicking.",
  permission: "Permission",
  addAnyAdditionalStepsOrConditionsAsNeeded:
    "Add any additional steps or conditions as needed",
  reviewAndFinalizeSequenceSettingsAndPreferences:
    "Review and finalize sequence settings and preferences",

  // setting
  nameOnCard: "Name on card",
  expiryDate: "Expiry date",
  cvccvv: "CVC/CVV",
  details: "Details",
  notification: "Notification",
  markAsAllRead: "Mark as all read",
  Personal: "Personal",
  profile: "Profile",
  extensions: "Extensions",
  notifications: "Notifications",
  Company: "Company",
  roles: "Roles",
  teams: "Teams",
  "current-usage": "Current Usage",
  "upgrade-plan": "Upgrade Plan",
  Products: "Products",
  APIKeys: "API Keys",
  popular: "Popular",
  priceSequence: "Sequence (Coming Soon)",
  free: "Free",
  lite: "Lite",
  growth: "Growth",
  business: "Business",
  enterEmailAddressesMultipleCommaSeparated:
    "Enter email addresses [multiple, comma-separated]",
  sharingRulesSelectTeamToEnableThisField:
    "Sharing rules[Select team to enable this field]",
  allSeatsAreCurrentlyOccupiedAnyNewUsersNeedingAPaidSeatWillIncurAdditionalCharges:
    "All seats are currently occupied. Any new users needing a paid seat will incur additional charges.",
  dueToday: "Due Today",
  costPerBillingCycle: "Cost per billing cycle",
  "select...": "Select...",
  invite: "Invite",
  services: "Services",
  "services-group": "Services Group",
  "all-notification-services": "All Notification Services",
  "services-status-update": "Services Status Update",
  "system-group": "System Group",
  "all-notification-system": "All Notification System",
  "system-new-feature-release": "System New Feature Release",
  "system-news-letter": "System News Letter",
  "system-generic-alarm": "System Generic Alarm",
  markAsRead: "Mark as read",
  showLess: "Show less",
  showMore: "Show more",
  changedStatusTo: "changed status to",
  "access-management-group": "Access Management Group",
  "access-management-roles": "Access Management Roles",
  "access-management-create-roles": "Access Management Create Roles",
  "access-management-edit-user-roles": "Access Management Edit User Roles",
  "access-management-add-role-to-users": "Access Management Add Role To Users",
  "access-management-delete-users-role": "Access Management Delete Users Role",
  "access-management-delete-roles": "Access Management Delete Roles",
  "access-management-read-roles-and-role-users":
    "Access Management Read Roles And Role Users",
  "access-management-teams": "Access Management Teams",
  "access-management-delete-users-team": "Access Management Delete Users Team",
  "access-management-read-teams-sharing-rules-teams-users":
    "Access Management Read Teams Sharing Rules Teams Users",
  "access-management-create-teams": "Access Management Create Teams",
  "access-management-edit-teams-sharing-rules":
    "Access Management Edit Teams Sharing Rules",
  "access-management-delete-teams-sharing-rules":
    "Access Management Delete Teams Sharing Rules",
  "access-management-add-and-update-team-to-users":
    "Access Management Add And Update Team To Users",
  "access-management-read-team-relations":
    "Access Management Read Team Relations",
  "access-management-modify-team-relations":
    "Access Management Modify Team Relations",
  "intent-signal-group": "Intent Signal Group",
  "market-intent-signal": "Market Intent Signal",
  "web-intent-signal": "Web Intent Signal",
  "web-intent-signal-add-and-activate": "Web Intent Signal Add And Activate",
  "web-intent-signal-read-data": "Web Intent Signal Read Data",
  "web-intent-signal-export-data": "Web Intent Signal Export Data",
  "web-intent-signal-delete-data": "Web Intent Signal Delete Data",
  "web-intent-signal-read-analytic": "Web Intent Signal Read Analytic",
  "search-group": "Search Group",
  "company-search": "Company Search",
  "prospects-search": "Prospects Search",
  "sequence-group": "Sequence Group",
  sequences: "Sequences",
  "service-create-request": "Service Create Request",
  "service-update-request": "Service Update Request",
  "service-read-request": "Service Read Request",
  "service-delete-service-attachment": "Service Delete Service Attachment",
  "service-delete-service-message": "Service Delete Service Message",
  "service-send-and-edit-service-message":
    "Service Send And Edit Service Message",
  "service-upload-service-attachment": "Service Upload Service Attachment",
  "settings-group": "Settings Group",
  "custom-fields": "Custom Fields",
  "user-management-and-billing-group": "User Management And Billing Group",
  "billing-read-credit-card": "Billing Read Credit Card",
  "billing-download-invoice": "Billing Download Invoice",
  "billing-read-invoice": "Billing Read Invoice",
  "credit-usage": "Credit Usage",
  "credit-usage-read-profile-detail": "Credit Usage Read Profile Detail",
  "credit-usage-read-profile-usage": "Credit Usage Read Profile Usage",
  "profile-and-team": "Profile And Team",
  "profile-and-team-invite-team-member": "Profile And Team Invite Team Member",
  "profile-and-team-update-profile": "Profile And Team Update Profile",
  "profile-and-team-delete-team-member": "Profile And Team Delete Team Member",
  "profile-and-team-get-user-secret-token":
    "Profile And Team Get User Secret Token",
  "profile-and-team-update-user-and-password":
    "Profile And Team Update User And Password",
  "profile-and-team-read-profile-users": "Profile And Team Read Profile Users",
  "upgrade-plan-read-subscription": "Upgrade Plan Read Subscription",
  "upgrade-plan-upgrade-subscription": "Upgrade Plan Upgrade Subscription",
  "all-access-access-management-roles": "All Access Access Management Roles",
  "all-access-access-management-teams": "All Access Access Management Teams",
  "all-access-market-intent-signal-page":
    "All Access Market Intent Signal Page",
  "all-access-web-intent-signal-page": "All Access Web Intent Signal Page",
  "all-access-company-search-page": "All Access Company Search Page",
  "all-access-prospects-search-page": "All Access Prospects Search Page",
  "all-access-sequence-page": "All Access Sequence Page",
  "all-access-service-page": "All Access Service Page",
  "all-access-custom-fields-page": "All Access Custom Fields Page",
  "all-access-billing-page": "All Access Billing Page",
  "all-access-credit-usage-page": "All Access Credit Usage Page",
  "all-access-profile-and-team-page": "All Access Profile And Team Page",
  newPermissionProfile: "New Permission Profile",
  enterAName: "Enter a name",
  addUserToRole: "Add user to role",
  changePermission: "Change permission",
  selectPermissionProfiles: "Select permission profiles",
  backToRoles: "Back to roles",
  updateRole: "Update role",
  enterADescription: "Enter a description",
  createANewRole: "Create a new role",
  AreYouSureYouWantToRemoveThisRole:
    "Are you sure you want to remove this role?",
  AreYouSureYouWantToRemoveThisUserFromTheTeam:
    "Are you sure you want to remove this user from the team?",
  ProceedingWithDeletionWillPermanentlyRemoveTheRoleFromTheUser:
    "Proceeding with deletion will permanently remove the role from the user.",
  ProceedingWithDeletionWillPermanentlyRemoveTheUserFromTheTeam:
    "Proceeding with deletion will permanently remove the user from the team.",
  relation: "Relation",
  newTeam: "New Team",
  "full-sharing-market-intent-signal-data":
    "Full Sharing Market Intent Signal Data",
  "full-sharing-web-intent-signal-data": "Full Sharing Web Intent Signal Data",
  "full-sharing-company-search-data": "Full Sharing Company Search Data",
  "full-sharing-prospects-search-data": "Full Sharing Prospects Search Data",
  "full-sharing-sequence-data": "Full Sharing Sequence Data",
  "full-sharing-service-data": "Full Sharing Service Data",
  "share-service-attachments": "Share Service Attachments",
  "share-service-messages": "Share Service Messages",
  RenameSharingRules: "Rename Sharing Rules",
  enterNewSharingRulesNameBelow: "Enter new sharing rules name below:",
  enterNewSharingRulesName: "Enter new sharing rules name",
  enterNewSharingRulesDescriptionBelow:
    "Enter new sharing rules description below:",
  enterNewSharingRulesDescription: "Enter new sharing rules description",
  enterSharingRulesName: "Enter sharing rules name",
  currentUsers: "Current Users",
  pendingUsers: "Pending Users",
  addRole: "Add role",
  addTeam: "Add team",
  addPermission: "Add permission",
  "Re-invite": "Re-invite",
  relations: "Relations",
  "is-parent-of": "Is parent of",
  addRelation: "Add relation",
  deleteRelation: "Delete relation",
  updateRelation: "Update relation",
  API_Keys: "API Keys",
  createNewKey: "Create new key",
  createAPIKey: "Create API Key",
  APIs: "APIs",
  setAsMasterKey: "Set as master key",
  "growth-annual": "Growth-Annual ",
  business: "Business ",
  "website-intent-data": "Website Intent Data",
  "demo-identification": "Demo Identification",

  activateTheNewVersionNow: "Activate the new version now",

  feedbackSendSuccessfully: "feedback sent successfully",
  feedbackSendFailed: "feedback sending failed",

  autoSave: "Auto save",
  serviceDescShowMore: "Show more",
  serviceDescShowLess: "Show less",
  members: "Members",
  shareTo: "Share to...",
  findTeamMembers: "Find team members",
  select: "Select",
  whenBalanceFallsBelow: "When balance falls below",
  defaultPaymentMethod: "Default Payment Method",
  creditCardDetails: "Credit Card Details",
  automaticallyTopUp: "Automatically top up",
  autoTopupWillAutomaticallyChargeYourPaymentMethodT:
    "Auto top-up will automatically charge your payment method. To disable it, leave this box unchecked",
  enableAutoTopups: "Enable auto top-ups",
  validThru: "VALID THRU",
  currentBalance: "Current balance",
  cardHolder: "CARD HOLDER",
  gotIt: "Got it",
  pleaseEnsureYourPaymentMethodIsUptodateAndThatYouA:
    "Please ensure your payment method is up-to-date and that you are comfortable with this automatic process before enabling.",
  thresholdCredits: "Threshold: {creditLimit} credits",
  creditAmountCredits: "Credit Amount: {choosedTopUpCredit} credits",
  enablingAutoTopupsWillAutomaticallyAddCreditsToYou:
    "Enabling auto top-ups will automatically add credits to your account when your balance falls below the specified threshold. This may result in automatic charges to your selected payment method.",
  thisWillAutomaticallyAddCreditsToYourAccountWhenev:
    "This will automatically add {choosedTopUpCredit} credits to your account whenever your balance drops below {creditLimit} credits",
  autoTopupNotice: "Auto top-up notice",
  roleName: "Role Name",
  addUser: "Add User",
  newRole: "New Role",
  userName: "User Name",
  joined: "Joined",
  thisRoleCurrentlyHasNoUsersAssignedToItStartAddingTeamMembersToDefineTheirResponsibilitiesAndStreamlineYourWorkflow:
    "This role currently has no users assigned to it. Start adding team members to define their responsibilities and streamline your workflow.",
  noUsersAssigned: "No users assigned",

  addUserToTeam: "Add user to team",
  user: "User",
  sharingRuleGroup: "Sharing Rule Group",
  updateTeam: "Update Team",
  createANewTeam: "Create a new team",
  backToTeams: "Back to teams",
  listOfSharingRules: "List of sharing rules",
  sharingRules: "Sharing Rules",
  manageSharingRules: "Manage sharing rules",
  CreateAndCustomizeSharingRulesToControlAccessForDifferentUsersClickTheButtonBelowToAddNewSharingRules:
    "Create and customize sharing rules to control access for different users. Click the button below to add new sharing rules.",
  newSharingRules: "New Sharing Rules",

  // price
  exportLimit: "Export Limit",
  customRolePermission: "Custom Role Permission",
  teamManagement: "Team Management",
  API: "API",
  linkedinAccount: "Linkedin Account",
  perPageLimit: "Per Page Limit",
  recordUploadLimit: "Record Upload Limit",
  creditRollover: "Credit Rollover",
  quotaSharing: "Quota Sharing",
  quotaAdjustment: "Quota Adjustment",
  add_On: "Add-On",
  topUpCredits: "Top Up Credits",
  webVisitorIdentification: "Web Visitor Identification",
  dataExportCredit: "Data Export",
  companySearchCredit: "Company Search",
  peopleSearchCredit: "Prospect Search",
  emailRedeemCredit: "Email Search",
  phoneNumberRedeemCredit: "Phone Number Search",
  recordSelection: "Record Selection",
  portal: "Portal",
  queryLimitPerHour: "Query Limit Per Hour",
  queryLimitPerDay: "Query Limit Per Day",
  callLimitPerHour: "Call Limit Per Hour",
  callLimitPerDay: "Call Limit Per Day",
  callLimitPerMonth: "Call Limit Per Month",
  basicSequencing: "Basic Sequencing",
  numberOfSequences: "Number of Sequences",
  numberOfMailboxesPerUser: "Number of Mailboxes Per User",
  numberOfLinkedinAccountPerUser: "Number of Linkedin Account Per User",
  numberOfSlackAccountPerUser: "Number of Slack Account Per User",
  automatedFollowUp: "Automated Follow Ups",
  advancedSequencing: "Advanced Sequencing",
  abTesting: "A/B Testing",
  multipleEmailTemplates: "Multiple Email Templates",
  reporting: "Reporting",
  emailOpenTracking: "Email Open Tracking",
  emailClickTracking: "Email Click Tracking",
  emailReplyTracking: "Email Reply Tracking",
  support: "Support",
  priorityEmailSupport: "Priority Email Support",
  companySelectionLimit: "Company Selection Limit",
  prospectSelectionLimit: "Prospect Selection Limit",
  totalPageLimit: "Total Page Limit",
  pricingYear: "Year",
  "growth-annual": "Growth",
  "business-annual": "Business",
  pricingSearch: "Search",
  pricingSearches: "Searches",
  pricingMonth: "Month",
  pricingYear: "Year",
  topupCredit: "topup credit",
  perUserPerYear: "Per user, per month \n Billed annually",
  perUserPerMonth: "Per user, per month \n Billed monthly",
  customCredits: "Custom Credits",
  customSearches: "Custom Searches",
  queryLimitPerMonth: "Query Limit Per Month",
  customized: "Custom",
  custom: "Custom ",
  free: "Free",
  growth: "Growth",
  business: "Business",
  organization: "Organization",
  search: "Search",

  // setting users
  pleaseSelectTheUserYouWantToAdd: "Please select the users you want to add",
  pleaseSelectTheUserYouWantToDelete:
    "Please select the users you want to delete",
  pleaseSelectTheUserYouWantToAddPermission:
    "Please select the users you want to add permission",

  ...sequence,
  range_separator: "to",
  start_placeholder: "Start Date",
  end_placeholder: "End Date",
  redirectSoon: "redirect soon",
  ...notificationEnglish,

  ...base,
  ...search,
  ...services,
  ...intent,
  ...tableEmptyInfomationEnglish,
  ...globalMessageEn,
  ...settingApiKeysEn,
  ...pricing,
  ...home,
};
