<template>
  <el-tooltip 
    popper-class="nav-tooltip-sty"
    effect="dark" 
    placement="right" 
    style="width: 100%"
    :disabled="disabled"
  >
    <div slot="content" style="width: 100%">
      <div v-if="!navCollapse" style="margin-bottom: 5px">{{ $t(info.name) }}</div>
      <span v-if="info.desc">{{ $t(info.desc) }}</span>
    </div> 
    <div :class="['custom-nav-item', {'mb-10': i !== navItem.length - 1 }]" v-if="custom">
      <slot name="content" />
    </div>
    <div :class="['nav-item', {'active': selectedNav === info.pathName}, {'mb-10': i !== navItem.length - 1 }]" v-else-if="$slots.content" >
      <slot name="content" class="icon-wrapper" />
    </div>
    <div :class="['nav-item', {'active': selectedNav === info.pathName}, {'mb-10': i !== navItem.length - 1 }]" v-else @click="toPage(info.pathName)">
      <div class="icon-wrapper" style="width: 100%">
        <svg-icon :class="['nav-icon',`nav-${info.icon ?? info.pathName}`]" :icon-class="`nav-${info.icon ?? info.pathName}`"></svg-icon>
        <span v-if="navCollapse" class="nav-name">{{ $t(info.name) }}</span>
      </div>
    </div>
  </el-tooltip>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  props: ['navItem', 'info', 'i', 'disabled', 'custom'],
  computed: {
    ...mapState('base', ['navCollapse', 'selectedNav']),
  },
  methods: {
    ...mapMutations('base', ['setSelectedNav']),
    toPage(page) {
      this.$router.push({name: page})
      this.setSelectedNav(page)
    }
  }
}
</script>

<style scoped lang="scss">
.nav-item {
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 9px;
  border-radius: 8px;
  color: $color-black-5;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px; /* 150% */
  letter-spacing: 0.24px;
  cursor: pointer;
  .icon-wrapper {
    display: flex; 
    width: 100%; 
    height: 100%;
    justify-self: center; 
    align-items: center
  }
  .nav-icon {
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    &.nav-home, &.nav-people, &.nav-enrich, &.nav-intent-signals, &.nav-sequence, &.nav-services, &.nav-setting {
      stroke: $color-black-5;
    }
  }
  .nav-name {
    margin-left: 8px;
  }
  &.mb-10 {
    margin-bottom: 8px;
  }
  &.active, &:hover {
    color: $color-blue-6;
    background-color: $color-blue-1;
    .nav-icon {
      &.nav-home, &.nav-people, &.nav-enrich, &.nav-intent-signals, &.nav-sequence, &.nav-services, &.nav-setting {
        stroke: $color-blue-6;
      }
    }
  }
}
</style>

<style lang="scss">
.nav-tooltip-sty {
  padding: 8px;
  max-width: 275px;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5; 
  letter-spacing: 0.24px;
}
.nav-item {
  position: relative;
  > span {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    border-radius: 8px;
    .el-popover__reference-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .icon-wrapper  {
        height: 100%;
        padding: 0 9px;
      }
    }
  }
}
</style>
