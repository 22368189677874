export default {
    // title 
    titleMarketUpdates: '市场动态',
    titleTrendingCompanies: '热门公司',
    titleInvite: "邀请并获得奖励",
    titleOnboarding: "入门工具",


    // market
    tabNews: '新闻',
    tabJobPostings: '职位发布',
    marketSort: "排序",
    marketFilter: "过滤",
    marketReset: '重置',
    marketFilterGalleries: '领域',
    marketFilterCategories: '分类',
    recent: "近期",
    earlier: "更早",
    minuteAgo: "{count} 分钟之前",
    hourAgo: "{count} 小时之前",
    dayAgo: "{count} 天之前",
    weekAgo: "{count} 周之前",
    on: '来自',


    // trending companies

    // invite
    forReferrals: "推荐人：",
    inviteBtn: '邀请',
    copyLink: '复制链接',
    shareYourReferralLink: '分享您的推荐链接或输入电子邮件邀请他人。加入即可获得奖励！',
    forTeamInvites: "对于团队邀请（获得积分）：",
    assignTeamInvites: "指定团队邀请",
    lookingToGrowYourTeam: "想发展你的团队吗？每次有人加入时，分配邀请并开始赚取积分！",
    addMultipleAddresses: "添加多个用逗号分隔的地址并按回车",
    greatProgress: "进步很大！继续邀请解锁全部{count}积分。",
    startInviting: '开始邀请并获得高达 {count} 个积分！',
    allSet: "准备就绪！您已获得 {count} 积分，感谢您的邀请！",


    // custom insight
    addYourOwnCustomInsights: "添加您自己的自定义见解",
    companyKeyMilestones: "公司的关键里程碑",
    listMajorCompetitors: "列出同行业的主要竞争对手",
    analyzeMarketPresence: "分析公司的市场占有率",
    poweredByAi: "由 AI 提供技术支持",


    // onboarding kit
    grantedUponCompletion: '{count} 积分将在完成后获得',

};