<template>
  <div class="icon-wrapper" @click="goSettings">
    <svg-icon icon-class="topNav-setting" />
    <span v-if="navCollapse" class="nav-name">Setting</span>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState('base', ['navCollapse']) 
  },
  methods: {
    goSettings() {
      this.$router.push({ name: "account" });
    },
  }
}
</script>

<style scoped lang="scss">
.icon-wrapper {
  display: flex;
  align-items: center;
  .nav-name {
    margin-left: 8px;
    color: $color-black-5;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.24px;
  }
  .svg-icon {
    width: 16px;
    height: 18px;
  }
}
</style>
