export default {
  intentOverview: "Overview",
  intentAnalytic: "Analytic",
  intentFilter: "Filter",
  searchCompany: "Search companies, website, linkedin...",
  savedCompanyWebsite: "Saved company website",
  companiesFound: "Companies found",
  companyFound: "Company found",
  filterList: {
    technologies: "Technology",
    time: "Time",
    locations: "Account Location",
    verticals: "Industry",
    pageview_greater_than: "Pageview",
    session_greater_than: "Session",
    advertisements: "Advertisement",
    company_groups: "Lists",
    start_date: "Start Date",
    end_date: "End Date",
    search_term: "Search Term",
  },
  employeeFilterList: {
    industry: "Industry",
    technology: "Technology",
    jobTitle: "Job Title",
    company: "Company",
    list: "Lists",
    name: "Name",
    size: "Size",
    location: "Location",
    jobFunction: "Job Function",
  },
  selectLists: "Select Lists...",
  chooseARangeOfTime: "Choose a range of time",
  greaterThan: "Greater than",
  selectAdvertisement: "Select Advertisement",
  // intentIndustry: 'Industry',
  tabActivity: "Activity",
  tabOverview: "Overview",
  tabEmployees: "Employees",
  tabContacts: "Generic Contacts",
  tabTechnologies: "Technologies",
  intentStatistic: "Statistic",
  intentCampaign: "Campaign",
  newVisitors: "New visitors",
  recurringVisitors: "Recurring Visitors",
  mediocreLead: "Mediocre lead",
  sessionsTracked: "Sessions tracked",
  sessionTracked: "Session tracked",
  lastSeenOn: "Last seen on ",
  firstSeenOn: "First seen on ",
  viewTrackedWebSession: "View Tracked Web Session",
  webSession: "Web Session",
  client: "CLIENT",
  timeSpent: "TIME SPENT",
  pageviews: "Page Views",
  pageview: "Page View",
  referrer: "REFERRER",
  sessions: "Sessions",
  session: "Session",
  visited: "Visited",
  AM: "AM",
  PM: "PM",
  companyDetails: "Company Details",
  overviewList: {
    location: "Location",
    industry: "Industry",
    founded: "Founded",
    ranking: "Ranking",
    size: "Size",
    revenue: "Estimated Revenue",
    keywords: "Keywords",
  },
  advancedSearch: "Advanced Search",
  jobFunction: "Job Function",
  employeeLocation: "Location",
  concatLocation: "Contact Location",
  contactEmail: "Email",
  categoryAndTechnology: "Category and Technology",
  firstDetected: "First Detected",
  lastDetected: "Last Detected",
  saved: "Saved",
  addToContactList: "Add to Contact List",
  noCampaignIdentified: "No Campaign Identified",
  copyEmail: "Copy Email",
  willBeSavedToTheFollowingSelectedLists:
    "will be saved to the following selected lists:",
  addToLists: "Add to Lists",
  noDataToDisplay: "No Data to Display",
  intentFounded: "FOUNDED",
  intentIndustry: "INDUSTRY",
  intentRevenue: "REVENUE",
  intentSize: "SIZE",
  applyFilters: "Apply Filters",
  youCanEditTheseFilters:
    "You can edit these filters anytime to refine your results",
  installB2BDirectLeadFinder: "Install B2B Direct Lead Finder on your site",
  addSite: "Add Site",
  areYouSureYouWantToRemoveThisWebsite:
    "Are you sure you want to remove this website?",
  Cancel: "Cancel",
  installB2BLeadFinder: "Install B2B Lead Finder",
  siteUrl: "Site Url",
  copyToClipboard: "Copy to clipboard",
  pleaseSelectAContactFirst: "please select a contact first",
  pasteCodeBelowIntoTheHead: "Paste the code below into the",
  ofEveryPageYouWishToTrackVisitorOn:
    "of every page you wish to track visitors on.",
  forWordpressWeRecommendInstalling:
    "For Wordpress, we recommend installing the",
  pluginFromStefanoLissa: "plugin from Stefano Lissa.",
  afterInstallingAndActivating:
    "After installing and activating the plugin, navigate to",
  inYourWordPressDashboard: "in your WordPress dashboard.",
  youWillSeeANumberOfTabs:
    "You’ll see a number of tabs in the plugin’s interface. Make sure you're in the default 'Head and footer' tab, then copy & paste the following code snippet:",
  settingHeaderAndFooter: "Settings → Header and Footer",
  dontForgetToClearYourCache: "Don't forget to clear your cache",
  ifYouAreUsingAnyCaChing: "If you are using any caching plugins such as",
  orSimilar:
    "or similar, make sure you clear the cache after installing the code snippet!",
  GTMshouldAlreadyBeInstalled:
    "Google Tag Manager should already be installed on your site before you follow these instructions",
  ifYouDontHaveGTM:
    "If you don't have Google Tag Manager installed yet you can find out how in the ",
  GTMsupportArticleAboutInstall:
    "Google Tag Manager support article about installing it",
  signInToGTMAndClick:
    "Sign in to Google Tag Manager, and click on the Add a New Tag button. Next,",
  clickWhereItSays: "click where it says",
  chooseATagTypeToBeginSetup: " Choose a tag type to begin setup",
  andSelect: " and select ",
  fromTheList:
    "from the list. Then, Copy & Paste the code following code into the text field:",
  nextClick: "Next, click ",
  giveTheTagAName: ". Give the tag a name and then click the Save button.",
  dontForgetToPublishYourChange: "Don't forget to publish your changes",
  afterCreateTheCustom: "After creating the Custom HTML tag, don't forget to ",
  yourChanges: " your changes!",
  submitAndPublish: "Submit and Publish",
  copySuccessfully: "Copied successfully",
  addToCompanyList: "Add to company list",
  addToList: "Add to list",
  enterOrCreateList: "Enter or create lists",
  installCode: "Install Code",
  wordpress: "Wordpress",
  googleTagManager: "Google Tag Manager",
  noVisitorsYet: "No Visitors Yet",
  onceTheInsightsRollIn:
    "Once the insights roll in, you'll unlock valuable information about your visitors and more",
  noTechAreFound: "No technologies are found",
  updateSuccessfully: "Updated successfully",
  enterJobFunction: "Enter Job Function",
  pinSuccessfully: "Pinned successfully",
  cancelPinSuccessfully: "Unpin successfully",
  adsName: "ads name",
  utmTerm: "utm term",
  utmCampaign: "utm campaign",
  utmSource: "utm source",
  utmMedium: "utm medium",
  enterNumber: "Enter Number",
  enterName: "Enter Name",
  enterJobTitle: "Enter Job Title",
  areYouSureYouWantToRemovePeopleOfThisGroup:
    "Are you sure you want to remove people of this group",
  areYouSureYouWantToRemoveCompanyOfThisGroup:
    "Are you sure you want to remove company of this group",
  byCancelingYouEnsureThatNoPeopleWillBeRemoved:
    "By canceling, you ensure that no people will be removed",
  byCancelingYouEnsureThatNoCompanyWillBeRemoved:
    "By canceling, you ensure that no company will be removed",
  howToGetStarted: "How to get started",
  Data: "Data",
  deactivateWebsite: "Deactivate Website",
  areYouCertainAboutDeactivatinYourWebsite:
    "Are you certain about deactivating your website? Keep in mind that this would result in not receiving any potential company activities.",
  byCancelingYouWillContinueToActivateAndUse:
    "By canceling, you will continue to activate and use this tool",
  yourTrialHasEnded: "Your trial has ended",
  reactivateNow: "Reactivate Now",
  yourWebsiteHasBeenAutomaticallyInactivated:
    "Your website has been automatically inactivated. Would you like to reactivate this tool to continue monitoring the data?",
  Inactivated: "Inactivated",
  onTrial: "on trial",
  Active: "Active",
  youAreOnTrail: "You are on a 7-day trial",
  yourTrailHasEnded: "Your trial has ended",
  createNewWebIntent: "Create New Web Intent",
  clickOnTheButton: "1. Click on the (+) button",
  typeInUrlOrWebAddress: "2. Type in URL or web address",
  theTrialWillActivate: "The 7-day trial will activate upon checking this box",
  pleaseEnterASiteUrl: "Please enter a site url",
  noMoreData: "no more data",
  pleaseEnterANewName: "please enter a new name",
  pleaseSelectASearchName: "please select a seach name",
  clickToGetEmail: "click to get email",
  emailIsNotAvailable: "email is not available",
  enterOrCreateLists: "Enter or create lists",
  viewMore: "view more",
  removeFromContactList: "Remove from Contact List",
  willBeRemovedFromTheFollowingLists:
    "will be removed from the following lists",
  removeFromLists: "Remove from Lists",
  pleaseSelectOrCreateAList: "please select or create a list",
  chooseATriggerToMakeThisTagFire: "Choose a trigger to make this tag fire...",
  youWontBeChargedYet: "You won’t be charged yet",
  youDontHaveEnoughCredits: "You don't have enough credits",
  ThisEmailRequire: "This email requires",
  TheseEmailsRequires: "These emails require",
  intentCredit: "credit",
  intentCredits: "credits",
  companyOverview: 'Company Overview'
};