<template>
  <div class="dashboard" >
    <Nav />
    <div class="main-content">
      <NavTop />
      <div class="page-content">
        <keep-alive exclude="intent">
          <router-view />
        </keep-alive>
      </div>
    </div>
    <MaximumQueryPopup />
    <NotEnoughCredits />
    <PaymentSecurityPopup />
    <AddCard />
    <RestrictedAccess />
  </div>
</template>

<script>
import Nav from './components/nav/Nav.vue';
import NavTop from './components/nav/NavTop.vue';
import MaximumQueryPopup from './components/popup/MaximumQueryPopup.vue';
import NotEnoughCredits from './components/popup/NotEnoughCredits.vue';
import PaymentSecurityPopup from './components/popup/PaymentSecurityPopup.vue';
import AddCard from './components/popup/AddCard.vue';
import RestrictedAccess from './components/popup/RestrictedAccess.vue';

export default {
  name: "Dashboard",
  components:{
    Nav,
    NavTop,
    MaximumQueryPopup,
    NotEnoughCredits,
    PaymentSecurityPopup,
    AddCard,
    RestrictedAccess,
  },
  data(){
    return{
      isDashboard: true,
    }
  },
  methods:{

  }
}

</script>

<style scoped lang="scss">
.dashboard {
  position: relative;
  display: flex;
  .main-content {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    .page-content {
      height: calc(100vh - $nav-height); 
      margin-top: $nav-height; 
      /* overflow: auto; */
      background-color: $color-gray-6;
      /* scrollbar-gutter: stable; */
    }
  }
}
</style>



