export default {
  //search-company
  people: "潛在客戶",
  companies: "公司",
  clearAll: "清除所有",
  searchProspects: "搜索潛在客戶、關鍵字...",
  //actions
  isFoundIn: "在 ",
  isNotFoundWithinAnyLists: "未在任何列表中找到",
  l: " 列表",
  remove: "移除",
  saveToLists: "保存到列表",
  DeleteFailed: "刪除失敗",
  //delete dialog
  areYouSureYouWantToDeleteThisRecord: "您確定要刪除此記錄嗎？",
  proceedingWithDeletionWillResultInPermanentRemovalOfTheList:
    "進行刪除將導致該列表永久移除。",
  delete: "刪除",
  byCancelingYouEnsureThatNoListsWillBeRemoved:
    "通過取消，您可以確保不會移除任何列表。",
  //export dialog
  holdOnWereGeneratingYourExport: "請稍候，我們正在生成您的導出文件。",
  UseThisButtonOnlyIfYouWishToCancelYourAction:
    "僅在您希望取消操作時使用此按鈕。",
  //save search pop
  saveSearch: "保存搜索",
  newSaveSearch: "新保存搜索",
  UpdateSavedSearch: "更新已保存搜索",
  enterNewSearchName: "輸入新搜索名稱",
  visibility: "可見性",
  manageWhoCanSeeYourSavedSearch: "管理誰可以查看您的已保存搜索",
  private: "私有",
  onlyYouCanSeeYourSavedSearch: "只有您可以查看您的已保存搜索",
  everyoneCanSeeYourSavedSearch: "每個人都可以查看您的已保存搜索",
  pinSavedSearch: "釘選已保存搜索",
  findSavedSearch: "查找已保存的搜索",
  enterSavedSearchName: "輸入已保存搜索名稱",
  duplicate: "重複",
  update: "更新",
  RemoveCreditCard: "移除信用卡",
  enterATitleForYourCard: "為您的卡片輸入標題",
  enterYourCardNumber: "輸入您的卡號",
  digit: "3位數",
  provideYourCompleteAddress: "提供您的完整地址",
  selectYourCountry: "選擇您的國家",
  //add credits pop
  ClickTheButtonBelowToAddMoreCredits: "點擊下面的按鈕以添加更多信用點數！",
  PleaseEnterTheAmountYouLikeToTopUpToYourCredit: "請輸入您希望充值的金額",
  //companyLink\contactBtn\functionBtn
  more: "更多",
  verified: "已驗證",
  GetEmailSuccessfully: "成功獲取電子郵件",
  CopySuccessfully: "成功複製",
  recorded: "已記錄",
  implement: "實施",
  sequence: "工作流程",
  of: "  總條數：",
  toAssistYouEffectivelyWeRequireFurtherDetailsAbout:
    "為了有效協助您，我們需要進一步了解您的搜索偏好。請利用左側面板中的篩選選項來指定您所需的標準，以便我們能夠精煉搜索結果，並為您提供符合要求的目標。",
  //filter
  all: "所有",
  my: "我的",
  team: "團隊",
  lists: "列表",
  employees: "員工",
  technologies: "技術",
  socialmedia: "社交媒體",
  enterSocialMedia: "輸入社交媒體",
  noResultsFound: "未找到結果",
  searchCompanies: "搜索公司",
  filters: "篩選器",
  enterCompanies: "輸入公司名稱",
  accountHqLocation: "帳戶總部位置",
  CityStateCountryZIP: "城市/州/國家/郵政編碼",
  enterLocations: "輸入地點",
  revenue: "收入",
  chooseARangeOfRevenue: "選擇收入範圍",
  ofRevenueIsUnknown: "收入未知",
  technology: "技術",
  category: "類別",
  categories: "類別",
  enterCategories: "輸入類別",
  enterTechnologies: "輸入技術",
  enterIndustries: "輸入行業",
  employee: "員工",
  predefinedRange: "預定義範圍",
  ofEmployeesIsUnknown: "員工數量未知",
  enterNames: "輸入姓名",
  jobTitle: "職位名稱",
  searchForAJobTitle: "搜索職位名稱",
  founded: "成立時間",
  chooseARangeOfFoundedTime: "選擇成立時間範圍",
  from: "從",
  to: "到",
  specialties: "專業領域",
  enterSpecialties: "輸入專業領域",
  location: "地點",
  total: "總計",
  department: "部門",
  EnterDepartments: "輸入部門",
  JobTitle: "職位名稱",
  EnterJobTitles: "輸入職位名稱",
  ManagementLevel: "管理層級別",
  EnterManagementLevels: "輸入管理層級別",
  AccountHQ: "帳戶總部",
  ContactHQ: "聯絡總部",
  departmentFunction: "部門功能",
  EnterJobFunctions: "輸入職務功能",
  enterNamesAndPressTheEnterKey: "輸入姓名並按Enter鍵...",
  EnterJobTitlesAndPressTheEnterLKey: "輸入職位並用“,”分隔，然後按Enter鍵...",
  pleaseSelectRange: "請選擇範圍",

  //table
  selectAllCompanies: "選擇所有公司",
  selected: "已選擇",
  export: "導出",
  industry: "行業",
  keywords: "關鍵字",
  companyLocation: "公司位置",
  action: "操作",
  contact: "聯絡人",
  Title: "職稱",
  clearSelection: "清除選擇",
  selectNumberOfCompanies: "選擇公司數量",
  maxCompanies: "最多公司數量",
  youCanOnlySelectAMaximumOf25Companies: "您最多只能選擇25家公司。",
  UpgradeYourPlanNow: "立即升級您的計劃！",
  viewPlans: "查看計劃",
  applySelection: "應用選擇",
  selectNumberOfProspects: "選擇潛在客戶數量",
  maxProspects: "最多潛在客戶數量",
  youCanOnlySelectAMaximumOf25Prospects: "您最多只能選擇25個潛在客戶。",
  PleaseSelectTheDataToBeExported: "請選擇要導出的數據",
  overview: "概覽",
  genericContacts: "通用聯絡人",
  size: "規模",
  contactLocation: "聯絡人位置",


  // 新UI
  prospectsSearch: "潛在客戶蒐索",
  companiesSearch: "公司蒐索",
  savedSearches: "保存的蒐索",
  savedLists: "已保存清單",

  // saved list and saved searches
  sortAndFilter: "排序和篩選",
  sortBy: "排序方式",
  filterAll: '所有',
  filterPrivate: '私人的',
  filterTeam: '團隊',
  searchSavedSearches: "蒐索已保存的蒐索",
  searchSavedLists: "蒐索已保存的清單"
};