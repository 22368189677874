<template>
  <div class="nav-title">
    <span class="nav-logo" v-show="navCollapse">
      <img src="@/assets/logo/pubrio-logo.png" class="logo" @click="toHome"/>
      <BetaLogo />
    </span>
    <NavCollapseIcon v-show="navCollapse" @toggleCollapse="toggleCollapse" />
    <img src="@/assets/logo/pubrio-square-logo.png" v-show="!navCollapse" class="simple-logo" @click="toHome"/>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import BetaLogo from '@/components/BetaLogo.vue';
import NavCollapseIcon from './NavCollapseIcon.vue';

export default {
  components: {
    BetaLogo,
    NavCollapseIcon,
  },
  computed: {
    ...mapState('base', ['navCollapse']),
  },
  methods: {
    ...mapMutations('base', ['setNavCollapse']),
    toHome() {
      this.$router.push({ name: 'home' })
    },
    toggleCollapse() {
      this.setNavCollapse(!this.navCollapse);
    }
  }
}
</script>


<style scoped lang="scss">
.nav-title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-gray-0;
  .nav-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    .logo {
      height: 24px;
    }
  }
}
.simple-logo {
  width: 24px;
  height: 24px;
  margin: 12px;
  cursor: pointer;
}
</style>