import { template_en } from "./template";
export default {
  /** tabs */
  sequence_tab_overview: "Overview",
  sequence_tab_templates: "Templates",
  sequence_tab_analytics: "Analytics",
  sequence_tab_meetings: "Meetings",
  sequence_tab_settings: "Settings",

  /** aside */
  sequence_overview_aside_create_new_label: "Create new label",
  sequence_overview_labels_total:
    "no Sequences | one Sequence | {count} Sequences",

  /** label form */
  sequence_overview_label_create_dialog_name: "New Label",
  sequence_overview_label_edit_dialog_name: "Rename Label",
  sequence_overview_label_create_dialog_input_label:
    "Please enter a new label name",
  sequence_overview_label_edit_dialog_input_label: "Please enter a label name",
  sequence_overview_label_create_dialog_input_oplaceholder:
    "Enter a new label name",
  sequence_overview_label_edit_dialog_input_placeholder: "Enter a label name",
  create_sequence_label_success: "A label created",
  create_sequence_label_failed: "Failed to create",
  update_sequence_label_success: "Updated successfully",
  update_sequence_label_failed: "Failed to update",

  /** filters */
  sequence_overview_filters_creator: "Creator",
  sequence_overview_filters_label: "Labels",
  sequence_overview_filters_starred: "Starred",
  sequence_overview_filters_state: "State",

  /** table */
  sequence_overview_table_label_panel_empty:
    "You don't have any labels yet. Click the '⊕' button to create your first label and start organizing!",
  sequence_overview_label_panel_search_placeholder: "Search labels",
  add_label_to_sequence_successfully: "Added successfully",
  add_label_to_sequence_failed: "Failed to add",
  remove_label_from_sequence_successfully: "Removed successfully",
  remove_label_from_sequence_failed: "Failed to remove",

  sequence_overview_table_name_sequence_name: "Sequence name",
  sequence_overview_table_name_people: "People",
  sequence_overview_table_name_click_rate: "Click rate",
  sequence_overview_table_name_open_rate: "Open rate",
  sequence_overview_table_name_reply_rate: "Reply rate",
  sequence_overview_unsave_count:
    "no of selected sequences | one of selected sequences | {count} of selected sequences",

  /** create sequence */
  sequence_overview_create_sequence_dialog_name: "New Sequence",
  sequence_overview_create_sequence_dialog_input_label:
    "Please enter a sequence name",
  sequence_overview_create_sequence_dialog_input_placeholder: "Sequence name",

  /** detail empty */
  sequence_detail_error_title: "Oops! We couldn’t fetch the data.",
  sequence_detail_error_desc:
    "the link might be incorrect or the data is unavailable. Please check the URL or changed the selected one and try again.",
  sequence_detail_error_back: "Return to Sequence List",

  /** detail header */
  sequence_detail_delete: "Delete this sequence",
  sequence_detail_delete_dialog_title: "Delete this sequence?",
  sequence_detail_delete_dialog_desc:
    "Deleting this sequence will remove all its settings and data. This can’t be undone.",

  /** detail nav */
  sequence_detail_nav_inbox: "Inbox",
  sequence_detail_nav_steps: "Steps",
  sequence_detail_nav_analytics: "Analytics",
  sequence_detail_nav_prospects: "Prospects",
  sequence_detail_nav_settings: "Settings",
  sequence_detail_nav_logs: "Logs",

  /** detail step canvas */
  sequence_detail_workflow_time_delay: "Delay",
  sequence_detail_workflow_time_monitor: "Monitor",
  sequence_detail_workflow_time_run_immediately: "Set to run immediately",
  sequence_detail_steps_add_first_step: "Add first step...",

  /** detail step canvas create node */
  sequence_detail_workflow_create_node_level1_title: "What happens next?",
  sequence_detail_workflow_create_node_level2_title: "Choose channel",
  sequence_detail_workflow_create_node_level3_title:
    "Choose condition/action type",

  /** detail step setting panel credential */
  sequence_detail_setting_credential_label: "Credential:",
  sequence_detail_setting_credential_primary_flag: "Primary",
  sequence_detail_setting_credential_primary_opeator: "Set as primary",

  ...template_en,

  /** ----------------------- */
  /** starred */
  sequence_filter_status_starred: "Starred",
  starredOnly: "Starred Only",
  starredFirst: "Starred First",

  /** name owner */
  sequence_filter_status_ownedby: "Owned by",
  sequence_name_owner_place: "Name Owner",

  /** status */
  sequence_filter_status: "Status",
  sequence_filter_status_active_inactive: "Active & Inactive",
  sequence_filter_status_active: "Active",
  sequence_filter_status_inactive: "Inactive",
  sequence_filter_status_archived: "Archived",

  /** delete message */
  popconfirm_delete_title: "Are you sure you want to delete this?",
  popconfirm_delete_yes: "Yes",
  popconfirm_delete_no: "No",

  /** create label */
  "sequence-new-label-title": "New Label",
  "sequence-new-label-desc": "Please enter a new label name:",
  "sequence-new-label-placeholder": "Enter a new label name",
  "sequence-new-label-button": "Create",

  /** edit label */
  "sequence-edit-label-title": "Rename Label",
  "sequence-edit-label-desc": "Please enter a label name:",
  "sequence-edit-label-placeholder": "Enter a label name",
  "sequence-edit-label-button": "Save",

  /** detail inbox */
  "sequence-detail-inbox-enter-email": "Please select emails.",

  /** detail inbox steps */
  "sequence-inbox-steps-version-label": "Versions",
  "sequence-inbox-steps-version-placeholder": "please select versions",
  "sequence-inbox-steps-step-label": "Steps",
  "sequence-inbox-steps-step-placeholder": "please select steps",

  /** prospects */
  industry_verticals: "Industry:",
  industry_vertical_categories: "Industry Categories:",
};
