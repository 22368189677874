<template>
  <div :class="['side-nav', { 'side-nav-small': !navCollapse }]">
    <div class="nav-main">
      <div class="side-top">
        <Logo />
        <div class="nav-list">
          <NavListSkeleton v-if="filterList.length === 0" />
          <div v-else>
            <div
              v-for="(navItem, i) in filterList"
              :key="i"
              class="nav-item-wrapper first"
            >
              <NavListItem
                v-for="(child, childIndex) in navItem"
                :key="child.name"
                :nav-item="navItem"
                :info="child"
                :i="childIndex"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="side-bottom">
        <div class="nav-list">
          <div
            v-for="(navItem, i) in settingList"
            :key="i"
            class="nav-item-wrapper"
          >
            <NavListItem
              v-for="(child, childIndex) in navItem"
              :key="child.name"
              :nav-item="navItem"
              :info="child"
              :i="childIndex"
              :disabled="child.tooltipDisabled"
            >
              <template #content>
                <component
                  v-if="child.component"
                  :is="child.component"
                ></component>
              </template>
            </NavListItem>
          </div>
        </div>
      </div>
      <div class="nav-list">
        <div v-for="(navItem, i) in userInfoList" :key="i">
          <NavListItem
            v-for="(child, childIndex) in navItem"
            :key="child.name"
            :nav-item="navItem"
            :info="child"
            :i="childIndex"
            :disabled="child.tooltipDisabled"
            :custom="child.custom"
          >
            <template #content>
              <component
                v-if="child.component"
                :is="child.component"
              ></component>
            </template>
          </NavListItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import NavListItem from "./components/NavListItem";
import NavListSkeleton from "./components/NavListSkeleton";
import Logo from "./components/Logo.vue";
import Wallet from "./components/Wallet.vue";
import Setting from "./components/Setting.vue";
import Profile from "./components/User.vue";

export default {
  components: {
    Logo,
    NavListItem,
    NavListSkeleton,
    Wallet,
    Setting,
    Profile,
  },
  mounted() {
    this.getProfile({ profile_id: this.currentId.profile_id });
    this.getConfig({ profile_id: this.currentId.profile_id });
    !localStorage.getItem("pubrio-profile") && this.getUserInfo(false);
  },
  computed: {
    ...mapState("user", ["systemConfig", "currentId"]),
    ...mapState("base", ["navCollapse"]),
  },
  watch: {
    "systemConfig.disabled_navigation_display": {
      handler(val) {
        if (val) {
          this.filterList = this.list.filter((item) =>
            item.some((child) => !val.includes(child.dataKey))
          );
        }
      },
    },
    $route: {
      handler: function (val) {
        if (val.name) this.setSelectedNav(val?.meta?.module || val.name);
      },
      deep: true,
      immediate: true,
    },
    "currentId.profile_id": {
      handler(val) {
        this.getConfig({ profile_id: val });
        this.getUserList({ profile_id: val, is_current: true }); //获得当前用户信息
        this.getProfile({ profile_id: val }); //获得account信息
      },
    },
  },
  data() {
    return {
      filterList: [],
      list: [
        [
          {
            dataKey: "home",
            name: "navHome",
            pathName: "home",
            desc: "instantlyEnhanceYourData",
          },
        ],
        [
          {
            dataKey: "companies",
            name: "navCompanies",
            pathName: "company",
            desc: "filterYourSearchToFindPreciselyTargeted",
          },
          {
            dataKey: "search",
            name: "navProspect",
            pathName: "people",
            desc: "filterYourSearchToFindHighlySpecific",
          },
          {
            dataKey: "enrich",
            name: "navEnrich",
            pathName: "enrich",
            desc: "instantlyEnhanceYourData",
          },
        ],
        [
          {
            dataKey: "intent_signals",
            name: "navIntent",
            pathName: "intent-signals",
            desc: "transformAnonymousWebsiteTrafficInto",
          },
        ],
        [
          {
            dataKey: "sequences",
            name: "navSequence",
            pathName: "sequence",
            desc: "personalizeYourOutreach",
          },
        ],
        [
          {
            dataKey: "services",
            name: "navService",
            pathName: "services",
            desc: "comprehensiveBusinessSolutions",
          },
        ],
      ],
      settingList: [
        [
          {
            dataKey: "credit",
            name: "navAccountCredit",
            desc: "Account credit",
            component: Wallet,
            tooltipDisabled: true,
          },
          {
            dataKey: "setting",
            name: "navSetting",
            icon: "setting",
            pathName: "account",
            tooltipDisabled: true,
          },
        ],
      ],
      userInfoList: [
        [
          {
            dataKey: "sdfs",
            name: "dfdf",
            desc: "111",
            component: Profile,
            tooltipDisabled: true,
            custom: true,
          },
        ],
      ],
    };
  },
  methods: {
    ...mapMutations("base", ["setSelectedNav"]),
    ...mapActions("user", ["getConfig", "getUserInfo"]),
    ...mapActions("account", ["getUserList", "getProfile"]),
  },
};
</script>

<style scoped lang="scss">
.side-nav {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 209px;
  border-right: 1px solid $color-gray-0;
  &-small {
    width: 49px;
  }
  .nav-main {
    display: flex;
    flex-direction: column;
    flex: 1;
    .nav-item-wrapper {
      padding: 8px;
      border-bottom: 1px solid $color-gray-4;

      &.first {
        &:nth-child(1) {
          height: 52px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .side-top {
      flex: 1;
      .nav-list {
      }
    }
    .side-bottom {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
  }
}
</style>
