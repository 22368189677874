import { template_zh_tw } from "./template";

export default {
  /** tabs */
  sequence_tab_overview: "總覽",
  sequence_tab_templates: "範本",
  sequence_tab_analytics: "分析",
  sequence_tab_meetings: "會議",
  sequence_tab_settings: "設定",

  /** label */
  sequence_filter_label: "標籤",
  sequence_name_label_place: "請選擇標籤",
  /** starred */
  sequence_filter_status_starred: "星標",
  starredOnly: "僅顯示星標",
  starredFirst: "星標順序在前",

  /** name owner */
  sequence_filter_status_ownedby: "擁有者",
  sequence_name_owner_place: "名稱擁有者",

  /** status */
  sequence_filter_status: "狀態",
  sequence_filter_status_active_inactive: "活躍和不活躍",
  sequence_filter_status_active: "活躍",
  sequence_filter_status_inactive: "不活躍",
  sequence_filter_status_archived: "已歸檔",

  /** delete message */
  popconfirm_delete_title: "您確認刪除此條？",
  popconfirm_delete_yes: "確認",
  popconfirm_delete_no: "取消",

  /** create label */
  "sequence-new-label-title": "新標籤",
  "sequence-new-label-desc": "請輸入新標籤名稱：",
  "sequence-new-label-placeholder": "請輸入新標籤名稱：",
  "sequence-new-label-button": "創建",

  /** edit label */
  "sequence-edit-label-title": "重新命名標籤",
  "sequence-edit-label-desc": "請輸入名稱",
  "sequence-edit-label-placeholder": "請輸入名稱",
  "sequence-edit-label-button": "保存",
  /** detail inbox */
  "sequence-detail-inbox-enter-email": "請選擇郵箱",

  /** detail inbox steps */
  "sequence-inbox-steps-version-label": "版本",
  "sequence-inbox-steps-version-placeholder": "請選擇版本",
  "sequence-inbox-steps-step-label": "步驟",
  "sequence-inbox-steps-step-placeholder": "請選擇步驟",


  ...template_zh_tw
};
