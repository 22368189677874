import { template_zh_cn } from "./template";

export default {
  /** tabs */
  sequence_tab_overview: "概览",
  sequence_tab_templates: "模板",
  sequence_tab_analytics: "分析",
  sequence_tab_meetings: "会议",
  sequence_tab_settings: "设置",

  /** label */
  sequence_filter_label: "标签",
  sequence_name_label_place: "请选择标签",
  /** starred */
  sequence_filter_status_starred: "星标",
  starredOnly: "仅显示星标",
  starredFirst: "星标排序在前",

  /** name owner */
  sequence_filter_status_ownedby: "拥有者",
  sequence_name_owner_place: "名称拥有者",

  /** status */
  sequence_filter_status: "状态",
  sequence_filter_status_active_inactive: "活跃和不活跃",
  sequence_filter_status_active: "活跃",
  sequence_filter_status_inactive: "不活跃",
  sequence_filter_status_archived: "已归档",

  /** delete message */
  popconfirm_delete_title: "您确认删除此条？",
  popconfirm_delete_yes: "确认",
  popconfirm_delete_no: "取消",

  /** create label */
  "sequence-new-label-title": "新标签",
  "sequence-new-label-desc": "请输入新标签名称：",
  "sequence-new-label-placeholder": "请输入新标签名称：",
  "sequence-new-label-button": "创建",

  /** edit label */
  "sequence-edit-label-title": "重新命名标签",
  "sequence-edit-label-desc": "请输入名称",
  "sequence-edit-label-placeholder": "请输入名称",
  "sequence-edit-label-button": "保存",

  /** detail inbox */
  "sequence-detail-inbox-enter-email": "请选择邮箱",

  /** detail inbox steps */
  "sequence-inbox-steps-version-label": "版本",
  "sequence-inbox-steps-version-placeholder": "请选择版本",
  "sequence-inbox-steps-step-label": "步骤",
  "sequence-inbox-steps-step-placeholder": "请选择步骤",

  ...template_zh_cn,
};
