<template>
  <el-popover
    placement="right"
    trigger="hover"
    popper-class="profile-popper-sty"
    v-model="visible"
  >
    <div class="menu">
      <div class="user-name">
        {{ $t("hello") }}, {{ name | bigFirstLetter }}
      </div>
      <div class="currentId">
        <span class="organ" @click="handleShow2">
          <div>{{ organization | bigFirstLetter }}</div>
          <div :class="['change-organ', isShow2 ? 'active' : null]">
            <span>{{ $t("change") }}</span>
            <svg-icon icon-class="nav-arrow" />
          </div>
        </span>

        <transition name="fade">
          <div class="menu" v-if="isShow2">
            <div
              @click="chooseProfile(item)"
              v-for="(item, index) in profile"
              :key="index"
              :class="[
                'dropItem',
                item.name === organization ? 'active' : '',
              ]"
            >
              <span>{{ item.name | bigFirstLetter }}</span>
              <svg-icon icon-class="profile-checked" />
            </div>
          </div>
        </transition>
      </div>
      <div class="list-wrapper">
        <div class="list-item">
          <router-link :to="{ name: 'account' }">
            <svg-icon icon-class="profile" />
            {{ $t("yourProfile") }}
          </router-link>
        </div>
        <div class="list-item">
          <router-link :to="{ name: 'currentUsage' }">
            <svg-icon icon-class="Frame 6543" />
            {{ $t("currentUsage") }}
          </router-link>
        </div>
        <div class="list-item">
          <router-link :to="{ name: 'billing' }">
            <svg-icon iconClass="Frame 6542"></svg-icon>
            {{ $t("billing") }}
          </router-link>
        </div>
        <div class="list-item feature">
          <a href="https://www.pubrio.com/contact-us">
            <svg-icon iconClass="Request"></svg-icon>
            {{ $t("featureRequest") }}
          </a>
        </div>
      </div>
      <div class="plan-wrapper">
        <div class="plan-info">
          <div>
            <div class="plan-title">{{ subscription?.name }}</div>
            <div class="usage-info">
              <svg-icon icon-class="plan-money" />
              <div class="credit">
                Credit usage :
                {{
                  account.subscription.credit_refill.toLocaleString() +
                  " " +
                  (subscription.charge_period === "12"
                    ? $t("credits_year")
                    : $t("credits_mo"))
                }}
              </div>
            </div>
          </div>
          <router-link :to="{ name: 'upgrade' }" class="upgrade"
            >Upgrade</router-link
          >
        </div>
        <div class="refresh">
          {{ $t("yourCreditsWillRefreshOn") }}
          {{ getDateInfo(subscription.next_charge_date).year() }}/{{
            getDateInfo(subscription.next_charge_date).format("MM")
          }}/{{ getDateInfo(subscription.next_charge_date).date() }}
          <span>{{ $t("过期") }}</span>
        </div>
      </div>
      <div class="logout" @click="logout">
        <svg-icon icon-class="exit" />
        <span>{{ $t("logout") }}</span>
      </div>
    </div>
    <div :class="['icon-wrapper', {active: visible}, {'expand': navCollapse}]" slot="reference">
      <span :class="['avatar', {active: visible}]">{{ displayName }}</span>
      <span v-if="navCollapse" class="user-name">{{ name }}</span>
    </div>
  </el-popover>
</template>

<script>
import { mapState } from 'vuex';
import { getDateInfo } from "@/utils";

export default {
  computed: {
    ...mapState("user", ["user", 'profile']),
    ...mapState('base', ['navCollapse']),
    ...mapState("account", ["account"]),
    subscription() {
      return this.account.subscription;
    },
    name() {
      return this.user.nickname;
    },
    organization() {
      if (this.$store.state.user.currentId) {
        return this.$store.state.user.currentId.name;
      }
    },
    displayName() {
      if (this.name) {
        let arr = this.name.split(" ");
        if (arr.length === 1) {
          return this.name.charAt(0).toUpperCase();
        } else {
          return (
            this.name.split(" ")[0].charAt(0).toUpperCase() +
            this.name.split(" ")[1].charAt(0).toUpperCase()
          );
        }
      }
    },
  },
  filters: {
    bigFirstLetter(str) {
      if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
    },
  },
  data() {
    return {
      visible: false,
      isShow2: false,
    }
  },
  methods: {
    getDateInfo,
    async chooseProfile(item) {
      this.isShow2 = false;
      await this.$store.commit("user/setCurrentId", item);
    },
    async logout() {
      let companySelected = this.$store.state.company.selected;
      localStorage.setItem("companySelected", JSON.stringify(companySelected));
      let peopleSelected = this.$store.state.people.selected;
      localStorage.setItem("peopleSelected", JSON.stringify(peopleSelected));

      await this.$store.dispatch("user/logout");
      await this.$router.push(
        "/login?returnUrl=" + encodeURIComponent(this.$route.fullPath)
      );
      let mes = this.$t("LoginOut");
      this.$message.success(mes);
    },
    handleShow2() {
      this.isShow2 = !this.isShow2;
    },
  }
}
</script>

<style scoped lang="scss">
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 12px;
  cursor: pointer;
  &.expand {
    justify-content: flex-start;
  }
  &:hover, &.active {
    .user-name {
      color: $color-blue-6;
    }
    .avatar {
      border: 1px solid $color-blue-3;
      background: $color-blue-2;
      transition: background border .3s;
    }
  }
  .avatar {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid $color-blue-2;
    background: $color-blue-1;
    color: $color-blue-6;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.24px;
    cursor: pointer;
    &:hover, &.active {
      border: 1px solid $color-blue-3;
      background: $color-blue-2;
      transition: background border .3s;
    }
  }
  .user-name {
    padding: 0 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.28px;
    color: $color-black-4;
  }
}
.menu {
  width: 384px;
  padding: 16px 0 0;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 #2636990f;
  z-index: 99;
  cursor: pointer;
  .user-name {
    padding: 0 16px;
    color: $color-blue-6;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.32px;
  }
  .feature {
    a {
      border: 0;
    }
  }
  .btn-sub {
    width: 314px;
    margin: 26px auto 0;
  }
  .list-wrapper {
    padding: 16px 0;
    border-bottom: 1px solid #f0f0f0;
    .list-item {
      margin: 0 16px;
      padding: 12px 8px;
      color: #343434;
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
      letter-spacing: 0.42px;
      border-radius: 8px;
      .svg-icon {
        margin-right: 8px;
      }
      &:hover {
        background-color: $color-gray-3;
      }
    }
  }
  .plan-wrapper {
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
    .plan-info {
      display: flex;
      justify-content: space-between;
      .plan-title {
        margin-bottom: 8px;
        color: #343434;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0.42px;
      }
      .usage-info {
        display: flex;
        align-items: center;
        color: #475467;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        .svg-icon {
          margin-right: 8px;
          width: 14px;
          height: 14px;
        }
        .credit {
          white-space: nowrap;
        }
      }
      .upgrade {
        width: 79px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 15px;
        color: $color-blue-6;
        font-size: 13px;
        font-weight: 500;
        border-radius: 8px;
        border: 1px solid $color-blue-1;
        background: #f0f4ff;
        cursor: pointer;
        &:hover {
          border: 1px solid $color-blue-3;
          background-color: $color-blue-2;
        }
      }
    }
    .refresh {
      margin-top: 9px;
      color: $color-gray-1;
      font-size: 13px;
      font-weight: 500;
    }
  }
  .logout {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.42px;
    cursor: pointer;
    .svg-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      stroke: #475467;
    }
    &:hover {
      color: $color-blue-6;
      .svg-icon {
        stroke: $color-blue-6;
      }
    }
  }
  .currentId {
    position: relative;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: $color-black;
    padding: 0 16px;
    .organ {
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f0f0f0;
      color: $color-black-4;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.28px;
    }
    .menu {
      position: absolute;
      top: 40px;
      right: 16px;
      width: 314px;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid $color-gray-4;
      background-color: $color-gray-6;
      box-shadow: 0px 0px 8px 0px rgba(24, 35, 106, 0.08);
      .dropItem {
        display: flex;
        justify-content: space-between;
        padding: 8px;
        font-weight: 600;
        line-height: 48px;
        color: $color-black-2;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        .svg-icon {
          display: none;
          stroke: $color-gray-1;
        }
        &.active {
          color: $color-blue-7;
          .svg-icon {
            display: block;
            stroke: $color-blue-7;
          }
        }
        &:hover {
          .svg-icon {
            display: block;
            stroke: $color-gray-1;
          }
        }
      }
    }
  }
  .change-organ {
    margin-top: -12px;
    height: 15px;
    line-height: 15px;
    font-size: 12px;
    color: $color-black-2;
    font-weight: 500;
    letter-spacing: 0.24px;
    cursor: pointer;
    .svg-icon {
      margin-left: 4px;
      stroke: $color-black;
    }
    &:hover,
    &.active {
      color: $color-blue-5;
      .svg-icon {
        transform: rotate(180deg);
        stroke: $color-blue-5;
      }
    }
  }
}

</style>

<style lang="scss">
.profile-popper-sty {
  padding: 0;
  width: 360px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(38, 54, 153, 0.04);
  &.ml-100 {
    margin-left: 100px;
  }
}
</style>