export default {
    // title 
    titleMarketUpdates: 'Market Updates',
    titleTrendingCompanies: 'Trending Companies',
    titleInvite: "Invite & earn rewards",
    titleOnboarding: "Onboarding kit",


    // market
    tabNews: 'News',
    tabJobPostings: 'Job postings',
    marketSort: "Sort",
    marketFilter: "Filter",
    marketReset: 'Reset',
    marketFilterGalleries: 'Galleries',
    marketFilterCategories: 'Categories',
    recent: "Recent",
    earlier: "Earlier",
    minuteAgo: "{count} minute ago | {count} minutes ago",
    hourAgo: "{count} hour ago | {count} hours ago",
    dayAgo: "{count} day ago | {count} days ago",
    weekAgo: "{count} week ago | {count} weeks ago",
    on: 'On',

    exportingLeadsToACSV: "Exporting leads to a CSV will use {count} credit. | Exporting leads to a CSV will use {count} credits.",
    

    // trending companies

    // invite
    forReferrals: "For referrals:",
    inviteBtn: 'Invite',
    copyLink: 'Copy link',
    shareYourReferralLink: 'Share your referral link or enter an email to invite others. Earn rewards when they join!',
    forTeamInvites: "For team invites (to earn credits):",
    assignTeamInvites: "Assign team invites",
    lookingToGrowYourTeam: "Looking to grow your team? Assign invites and start earning credits every time someone joins!",
    addMultipleAddresses: "Add multiple addresses separated by commas",
    greatProgress: "Great progress! Keep inviting to unlock the full {count} credits.",
    startInviting: 'Start inviting and earn up to {count} credits!',
    allSet: "All set! You've earned your {count} credits—thanks for inviting!",


    // custom insight
    addYourOwnCustomInsights: "Add your own custom insights",
    companyKeyMilestones: "Company’s key milestones",
    listMajorCompetitors: "List major competitors in the same industry",
    analyzeMarketPresence: "Analyze the company’s market presence",
    poweredByAi: "Powered by Ai",

    // onboarding kit
    grantedUponCompletion: '{count} Granted upon completion',

};