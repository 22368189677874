export default {
    //top-nav
    navHome: "首页",
    navProspect: "潜在客户",
    navCompanies: '公司',
    navEnrich: '数据丰富',
    navIntent: "网站意图",
    navSequence: "工作流程",
    navService: "企业服务",
    navAccountCredit: '账户信用点',
    navSetting: "设置",
    navSequenceDetail: "工作流程详情",


    // nav desc 
    filterYourSearchToFindPreciselyTargeted: "筛选您的搜索，以找到目标明确的公司列表",
    filterYourSearchToFindHighlySpecific: "筛选您的搜索，以找到高度特定的目标潜在客户群",
    instantlyEnhanceYourData: '通过添加来自各种来源的有价值的见解和关键细节，立即增强您的数据',
    transformAnonymousWebsiteTrafficInto: "通过实时识别将匿名网站流量转化为B2B潜在客户",
    personalizeYourOutreach: '自动化和个性化您的外展活动，以节省时间并吸引潜在客户',
    comprehensiveBusinessSolutions: '满足您需求的全面业务解决方案和支持服务',

    discoverAndConnectWithPotentialBuyers: "发现并与潜在客户联系",
    activelySeekingYourSolutionToday: "今天积极寻求您的解决方案",
    Market_Intent: "市场意图",
    Tools: "工具",
    technologyLookup: "技术查询",
    domainLookup: "域名查询",
    emailVerification: "电子邮件验证",
    upgrade: "升级",
    helpCenter: "帮助中心",
    contactUs: "联系我们",
    feedback: "反馈",
    hello: "你好",
    clickToChange: "点击更改",
    yourProfile: "您的个人资料",
    currentUsage: "当前使用情况",
    upgradePlan: "升级计划",
    integrations: "集成",
    featureRequests: "功能请求",
    change: "更改",
    en: "英文",
    cn: "中文（简）",
    tw: "中文（繁）",
    logout: "登出",
    switchLanguage: "成功切换语言",
};