export default {
    //search-company
    people: "潜在客户",
    companies: "公司",
    clearAll: "清除所有",
    searchProspects: "搜索潜在客户、关键字...",
  
    //actions
    isFoundIn: "在 ",
    isNotFoundWithinAnyLists: "未在任何列表中找到",
    l: " 列表",
    remove: "移除",
    saveToLists: "保存到列表",
    DeleteFailed: "删除失败",
  
    //delete dialog
    areYouSureYouWantToDeleteThisRecord: "您确定要删除此记录吗？",
    proceedingWithDeletionWillResultInPermanentRemovalOfTheList:
      "进行删除将导致该列表永久移除。",
    delete: "删除",
    byCancelingYouEnsureThatNoListsWillBeRemoved:
      "通过取消，您可以确保不会移除任何列表。",
  
    //export dialog
    holdOnWereGeneratingYourExport: "请稍候，我们正在生成您的导出文件。",
    UseThisButtonOnlyIfYouWishToCancelYourAction:
      "仅在您希望取消操作时使用此按钮。",
  
    //save search pop
    saveSearch: "保存搜索",
    newSaveSearch: "新保存搜索",
    UpdateSavedSearch: "更新已保存搜索",
    enterNewSearchName: "输入新搜索名称",
    visibility: "可见性",
    manageWhoCanSeeYourSavedSearch: "管理谁可以查看您的已保存搜索",
    private: "私有",
    onlyYouCanSeeYourSavedSearch: "只有您可以查看您的已保存搜索",
    everyoneCanSeeYourSavedSearch: "每个人都可以查看您的已保存搜索",
    pinSavedSearch: "固定已保存搜索",
    findSavedSearch: "查找已保存的搜索",
    enterSavedSearchName: "输入已保存搜索名称",
  
    duplicate: "重复",
    update: "更新",
    RemoveCreditCard: "移除信用卡",
    enterATitleForYourCard: "为您的卡片输入标题",
    enterYourCardNumber: "输入您的卡号",
    digit: "3位数",
    provideYourCompleteAddress: "提供您的完整地址",
    selectYourCountry: "选择您的国家",
  
    //add credits pop
    ClickTheButtonBelowToAddMoreCredits: "点击下面的按钮以添加更多信用点数！",
    PleaseEnterTheAmountYouLikeToTopUpToYourCredit: "请输入您希望充值的金额",
  
    //companyLink\contactBtn\functionBtn
    more: "更多",
    verified: "已验证",
    GetEmailSuccessfully: "成功获取电子邮件",
    CopySuccessfully: "成功复制",
    recorded: "已记录",
    implement: "实施",
    sequence: "工作流程",
    of: " 总条数：",
    toAssistYouEffectivelyWeRequireFurtherDetailsAbout:
      "为了有效协助您，我们需要进一步了解您的搜索偏好。请利用左侧面板中的筛选选项来指定您所需的标准，以便我们能够精炼搜索结果，并为您提供符合要求的目标。",
  
    //filter
    all: "所有",
    my: "我的",
    team: "团队",
    lists: "列表",
    employees: "员工",
    technologies: "技术",
    socialmedia: "社交媒体",
    enterSocialMedia: "输入社交媒体",
    noResultsFound: "未找到结果",
    searchCompanies: "搜索公司",
    filters: "筛选器",
    enterCompanies: "输入公司名称",
    accountHqLocation: "帐户总部位置",
    CityStateCountryZIP: "城市/州/国家/邮政编码",
    enterLocations: "输入地点",
    revenue: "收入",
    chooseARangeOfRevenue: "选择收入范围",
    ofRevenueIsUnknown: "收入未知",
    technology: "技术",
    category: "类别",
    categories: "类别",
    enterCategories: "输入类别",
    enterTechnologies: "输入技术",
    enterIndustries: "输入行业",
    employee: "员工",
    predefinedRange: "预定义范围",
    ofEmployeesIsUnknown: "员工数量未知",
    enterNames: "输入姓名",
    jobTitle: "职位名称",
    searchForAJobTitle: "搜索职位名称",
    founded: "成立时间",
    chooseARangeOfFoundedTime: "选择成立时间范围",
    from: "从",
    to: "到",
    specialties: "专业领域",
    enterSpecialties: "输入专业领域",
    location: "地点",
    total: "总计",
    department: "部门",
    EnterDepartments: "输入部门",
    JobTitle: "职位名称",
    EnterJobTitles: "输入职位名称",
    ManagementLevel: "管理层级别",
    EnterManagementLevels: "输入管理层级别",
    AccountHQ: "帐户总部",
    ContactHQ: "联系总部",
    departmentFunction: "部门功能",
    EnterJobFunctions: "输入职务功能",
    enterNamesAndPressTheEnterKey: "输入姓名并按Enter键...",
    EnterJobTitlesAndPressTheEnterLKey: "输入职位并用“,”分隔，然后按Enter键...",
    pleaseSelectRange: "请选择范围",
  
    //table
    selectAllCompanies: "选择所有公司",
    selected: "已选择",
    export: "导出",
    industry: "行业",
    keywords: "关键字",
    companyLocation: "公司位置",
    action: "操作",
    contact: "联系人",
    Title: "职称",
    clearSelection: "清除选择",
    selectNumberOfCompanies: "选择公司数量",
    maxCompanies: "最多公司数量",
    youCanOnlySelectAMaximumOf25Companies: "您最多只能选择25家公司。",
    UpgradeYourPlanNow: "立即升级您的计划！",
    viewPlans: "查看计划",
    applySelection: "应用选择",
    selectNumberOfProspects: "选择潜在客户数量",
    maxProspects: "最多潜在客户数量",
    youCanOnlySelectAMaximumOf25Prospects: "您最多只能选择25个潜在客户。",
    PleaseSelectTheDataToBeExported: "请选择要导出的数据",
    overview: "概览",
    genericContacts: "通用联系人",
    size: "规模",
    contactLocation: "联系人位置",


    // 新UI
    prospectsSearch: "潜在客户搜索",
    companiesSearch: "公司搜索",
    savedSearches: "保存的搜索",
    savedLists: "已保存列表",

    // saved list and saved searches
    sortAndFilter: "排序和筛选",
    sortBy: "排序方式",
    filterAll: '所有',
    filterPrivate: '私人的',
    filterTeam: '团队',
    searchSavedSearches: "搜索已保存的搜索",
    searchSavedLists: "搜索已保存的列表"


};