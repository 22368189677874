const settingApiKeysEn = {
  settings_api_keys_api_docs: "More Info - API",
  settings_apikeys_create_dialog_title: "Create New API Key",
  settings_apikeys_create_dialog_desc:
    "Create a new API key to enable external systems to access your account.",
  settings_apikeys_form_name_placeholder: "Enter a name for the API key",
  settings_apikeys_form_description_placeholder:
    "Enter a short description of the key's purpose",
  settings_apikeys_form_services_placeholder:
    "Select a service to associate with this key",
  settings_api_keys_api_tip:
    "If you don't select any APIs for this new key, we will assume that this new API key is a 'master' key",
  settings_api_keys_api_set_master_key: "Set as master key",
  "API Keys": "API Keys",
  API_Service: "API Service",

  usage_total_this_month: "Total calls this month:",
  usage_remaining_quota: "Remaining quota:",
  usage_reset_on: "Reset on:",
  call: "call | call | calls",
  _usage_sub_title: " usage",
  portal_usage_sub_title: " Query Limit",
  hourly: "hourly",
  daily: "daily",
  monthly: "monthly",
  usage_portal_title: "Portal Limit",
  usage_api_title: "API",
  settings_apikeys_name: "API Key Name",
};

export default settingApiKeysEn;
