export default {
  intentOverview: "概覽",
  intentAnalytic: "分析",
  intentFilter: "篩選",
  searchCompany: "搜索公司、網站、LinkedIn...",
  savedCompanyWebsite: "已保存的公司網站",
  companiesFound: "間找到的公司",
  companyFound: "間找到的公司",
  filterList: {
    technologies: "技術",
    time: "時間",
    locations: "帳戶位置",
    verticals: "行業",
    pageview_greater_than: "頁面瀏覽量",
    session_greater_than: "會話數",
    advertisements: "廣告",
    company_groups: "列表",
    start_date: "開始日期",
    end_date: "結束日期",
    search_term: "搜索詞",
  },
  employeeFilterList: {
    industry: "行業",
    technology: "技術",
    jobTitle: "職位名稱",
    company: "公司",
    list: "列表",
    name: "姓名",
    size: "規模",
    location: "地點",
    jobFunction: "職務功能",
  },
  selectLists: "選擇列表...",
  chooseARangeOfTime: "選擇時間範圍",
  greaterThan: "大於",
  selectAdvertisement: "選擇廣告",
  // intentIndustry: '行業',
  tabActivity: "活動",
  tabOverview: "概覽",
  tabEmployees: "員工",
  tabContacts: "通用聯絡人",
  tabTechnologies: "技術",
  intentStatistic: "統計",
  intentCampaign: "活動",
  newVisitors: "新訪客",
  recurringVisitors: "回訪訪客",
  mediocreLead: "一般潛在客戶",
  sessionsTracked: "已追蹤會話",
  sessionTracked: "已追蹤會話",
  lastSeenOn: "最後一次出現於 ",
  firstSeenOn: "第一次出現於 ",
  viewTrackedWebSession: "查看已追蹤的網絡會話",
  webSession: "網絡會話",
  client: "客戶",
  timeSpent: "花費時間",
  pageviews: "頁面瀏覽量",
  pageview: "頁面瀏覽量",
  referrer: "來源",
  sessions: "會話數",
  session: "會話",
  visited: "已訪問",
  // Time indicators
  AM: "上午",
  PM: "下午",
  companyDetails: "公司詳細信息",
  overviewList: {
    location: "地點",
    industry: "行業",
    founded: "成立於",
    ranking: "排名",
    size: "規模",
    revenue: "預估收入",
    keywords: "關鍵字",
  },
  advancedSearch: "進階搜索",
  jobFunction: "職務功能",
  employeeLocation: "位置",
  concatLocation: "聯絡位置",
  contactEmail: "電子郵件",
  categoryAndTechnology: "類別與技術",
  firstDetected: "首次檢測",
  lastDetected: "最後檢測",
  saved: "已保存",
  addToContactList: "添加到聯絡人列表",
  noCampaignIdentified: "未識別任何活動",
  copyEmail: "複製電子郵件",
  willBeSavedToTheFollowingSelectedLists: "將保存到以下選定列表：",
  addToLists: "添加到列表",
  noDataToDisplay: "無數據可顯示",
  intentFounded: "成立於",
  intentIndustry: "行業",
  intentRevenue: "收入",
  intentSize: "規模",
  applyFilters: "應用篩選器",
  youCanEditTheseFilters: "您可以隨時編輯這些篩選器以精煉結果",
  installB2BDirectLeadFinder: "在您的網站上安裝B2B直接潛在客戶查找器",
  addSite: "添加網站",
  areYouSureYouWantToRemoveThisWebsite: "您確定要移除此網站嗎？",
  Cancel: "取消",
  installB2BLeadFinder: "安裝B2B潛在客戶查找器",
  siteUrl: "網站網址",
  copyToClipboard: "複製到剪貼板",
  pleaseSelectAContactFirst: "請先選擇一個聯絡人",
  pasteCodeBelowIntoTheHead: "將以下代碼粘貼到每個您希望追蹤訪問者的頁面",
  ofEveryPageYouWishToTrackVisitorOn: "的每個頁面。",
  forWordpressWeRecommendInstalling:
    "對於WordPress，我們建議安裝來自Stefano Lissa的",
  pluginFromStefanoLissa: "插件。",
  afterInstallingAndActivating: "安裝並啟用插件後，請導航至",
  inYourWordPressDashboard: "在您的WordPress儀表板中。",
  youWillSeeANumberOfTabs:
    "您將在插件界面中看到多個標籤。確保您在默認的“標頭和頁腳”標籤中，然後複製並粘貼以下代碼片段：",
  settingHeaderAndFooter: "設置 → 標頭和頁腳",
  dontForgetToClearYourCache: "不要忘記清除您的緩存",
  ifYouAreUsingAnyCaChing: "如果您使用任何緩存插件，例如",
  orSimilar: "或類似的，請確保在安裝代碼片段後清除緩存！",
  GTMshouldAlreadyBeInstalled:
    "Google Tag Manager 應該已經安裝在您的網站上，然後再按照這些說明操作。",
  ifYouDontHaveGTM:
    "如果您尚未安裝Google Tag Manager，您可以在以下位置了解如何安裝：",
  GTMsupportArticleAboutInstall: "Google Tag Manager 支持文章有關安裝的內容。",
  signInToGTMAndClick:
    "登錄Google Tag Manager，然後單擊“添加新標籤”按鈕。接下來，",
  clickWhereItSays: "單擊顯示的地方，",
  chooseATagTypeToBeginSetup: "選擇一個標籤類型以開始設置",
  andSelect: "並選擇 ",
  fromTheList: "從列表中。然後，將以下代碼複製並粘貼到文本字段中：",
  nextClick: "接下來，單擊 ",
  giveTheTagAName: ". 給標籤命名，然後單擊保存按鈕。",
  dontForgetToPublishYourChange: "不要忘記發布您的更改",
  afterCreateTheCustom: "創建自定義HTML標籤後，不要忘記 ",
  yourChanges: " 您的更改！",
  submitAndPublish: "提交並發布",
  copySuccessfully: "成功複製",
  addToCompanyList: "添加到公司列表",
  addToList: "添加到列表",
  enterOrCreateList: "輸入或創建列表",
  installCode: "安裝代碼",
  wordpress: "WordPress",
  googleTagManager: "Google Tag Manager",
  noVisitorsYet: "尚無訪問者",
  onceTheInsightsRollIn: "一旦獲得見解，您將解鎖有關訪問者及更多的寶貴信息。",
  noTechAreFound: "未找到技術信息",
  updateSuccessfully: "成功更新",
  enterJobFunction: "輸入職務功能",
  pinSuccessfully: "釘選成功",
  cancelPinSuccessfully: "取消釘選成功",
  adsName: "廣告名稱",
  utmTerm: "utmTerm",
  utmCampaign: "utmCampaign",
  utmSource: "utmSource",
  utmMedium: "utmMedium",
  enterNumber: "輸入數字",
  enterName: "輸入姓名",
  enterJobTitle: "輸入職位名稱",
  areYouSureYouWantToRemovePeopleOfThisGroup: "您確定要移除此組中的聯絡人嗎？",
  areYouSureYouWantToRemoveCompanyOfThisGroup: "您確定要移除此組中的公司嗎？",
  byCancelingYouEnsureThatNoPeopleWillBeRemoved:
    "通過取消，您可以確保不會移除任何聯絡人。",
  byCancelingYouEnsureThatNoCompanyWillBeRemoved:
    "通過取消，您可以確保不會移除任何公司。",
  howToGetStarted: "如何開始",
  Data: "條數據",
  deactivateWebsite: "停用網站",
  areYouCertainAboutDeactivatinYourWebsite:
    "您確定要停用您的網站嗎？請注意，這將導致不接收任何潛在公司的活動。",
  byCancelingYouWillContinueToActivateAndUse:
    "通過取消，您將繼續啟用和使用此工具。",
  yourTrialHasEnded: "您的試用已結束。",
  reactivateNow: "立即重新啟用",
  yourWebsiteHasBeenAutomaticallyInactivated:
    "您的網站已自動停用。您是否希望重新啟用此工具以繼續監控數據？",
  Inactivated: "已停用",
  onTrial: "試用中",
  Active: "啟用設定",
  youAreOnTrail: "您正在進行7天的試用",
  yourTrailHasEnded: "您的試用已結束",
  createNewWebIntent: "創建新的網絡意圖",
  clickOnTheButton: "1. 點擊（+）按鈕",
  typeInUrlOrWebAddress: "2. 輸入網址或網站地址",
  theTrialWillActivate: "勾選此框將啟動7天的試用",
  pleaseEnterASiteUrl: "請輸入網站網址",
  noMoreData: "沒有更多數據",
  pleaseEnterANewName: "請輸入一個新名稱",
  pleaseSelectASearchName: "請選擇一個搜索名稱",
  clickToGetEmail: "點擊以獲取電子郵件",
  emailIsNotAvailable: "電子郵件不可用",
  enterOrCreateLists: "輸入或創建列表",
  viewMore: "查看更多",
  removeFromContactList: "從聯絡人列表中移除",
  willBeRemovedFromTheFollowingLists: "將從以下列表中移除",
  removeFromLists: "從列表中移除",
  pleaseSelectOrCreateAList: "請選擇或創建一個列表",
  chooseATriggerToMakeThisTagFire: "選擇一個觸發器以使此標籤觸發...",
  youWontBeChargedYet: "您尚未被收費",
  youDontHaveEnoughCredits: "您沒有足夠的信用點數",
  ThisEmailRequire: "此電子郵件需要",
  TheseEmailsRequires: "這些電子郵件需要",
  intentCredit: "信用點數",
  intentCredits: "信用點數",
  companyOverview: '公司概況'
};