const state = { 
  navCollapse: false,
  selectedNav: '',
}

const mutations = {
  setNavCollapse(state) {
    state.navCollapse = !state.navCollapse;
  },
  setSelectedNav(state, payload) {
    state.selectedNav = payload;
  },
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}