const settingApiKeysCNTraditional = {
  settings_api_keys_api_docs: "更多資訊 - API",
  settings_apikeys_create_dialog_title: "創建新的 API 金鑰",
  settings_apikeys_create_dialog_desc:
    "創建一個新的 API 金鑰以便外部系統訪問您的帳戶。",
  settings_apikeys_form_name_placeholder: "輸入 API 金鑰名稱",
  settings_apikeys_form_description_placeholder: "輸入金鑰用途的簡短描述",
  settings_apikeys_form_services_placeholder: "選擇與此金鑰關聯的服務",
  settings_api_keys_api_tip:
    "如果您沒有為此新金鑰選擇任何 API，我們將假設此新金鑰為‘主金鑰’",
  settings_api_keys_api_set_master_key: "設置為主金鑰",
  "API Keys": "API 金鑰",
  API_Service: "服務",
  usage_total_this_month: "本月總調用次數：",
  usage_remaining_quota: "剩餘配額：",
  usage_reset_on: "重置於：",
  call: "次調用 | 次調用 | 次調用",
  _usage_sub_title: "使用量",
  portal_usage_sub_title: "查詢上限",
  hourly: "每小時",
  daily: "每日",
  monthly: "每月",
  usage_portal_title: "門戶限額",
  usage_api_title: "API",
  settings_apikeys_name: "API 金鑰名稱",
};
export default settingApiKeysCNTraditional;
