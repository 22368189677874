<template>
  <el-dropdown trigger="click" @command="changeLanguage">
    <div class="lang">
      <svg-icon icon-class="nav-language" />
      <span class="name">
        {{ $t(currentLng) }}
      </span>
      <svg-icon icon-class="nav-language-arrow" />
    </div>
    <el-dropdown-menu
      slot="dropdown"
      :visible-arrow="false"
      :append-to-body="false"
    >
      <el-dropdown-item
        v-for="lang in languageList"
        :key="lang.code"
        :command="lang"
      >
        <span>{{ lang.name }}</span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
  mounted() {
    this.getLanguage();
  },
  computed: {
    ...mapState("user", ["user", "language", "languageList"]),
    ...mapActions("common", ["getAllSelectList"]),
    currentLng() {
      return this.language?.name;
    },
  },
  data() {
    return {
      localeMap: new Map([
        ["en", "en"],
        ["zh_cn", "zh-cn"],
        ["zh_hk", "zh-tw"],
      ]),
    }
  },
  methods: {
    ...mapMutations("user", ["setLanguage", "setLanguageList", 'setLanguageUpdatedFlag']),
    ...mapActions("account", ["updateUser"]),
    ...mapActions('user', ['getLanguages']),
    async changeLanguage(lang) {
      try {
        const { name, code } = lang;
        this.$i18n.locale = this.localeMap.get(code); // 设置给本地的i18n插件
        this.setLanguage(lang);
        this.$message.success(this.$t("switchLanguage"));

        const { nickname, timezone_id, raw_phone_number } = this.user;

        let familyName, givenName;
        if (nickname) {
          let length = nickname.split(" ").length;
          familyName = length > 1 ? nickname.split(" ")[length - 1] : nickname;
          givenName = length > 1 ? nickname.split(" ")[0] : "";
        }
        await this.updateUser({
          given_name: givenName,
          family_name: familyName,
          nickname,
          timezone_id,
          raw_phone_number,
          language: lang.code,
        });
        this.setLanguageUpdatedFlag();
        this.getAllSelectList();
      } catch(err) {

      } finally {
      }
    },
    getLanguage() {
      if (localStorage.getItem("languageList")) {
        this.setLanguageList(JSON.parse(localStorage.getItem("languageList")));
      } else {
        this.getLanguages();
      }
      if (localStorage.getItem("language")) {
        const lang = JSON.parse(localStorage.getItem("language"));
        this.setLanguage(lang);
        this.$i18n.locale = this.localeMap.get(lang.code);
      }
    },
  }
}
</script>

<style scoped lang="scss">
.lang {
  padding: 6px 8px;
  border-radius: 6px;
  background: $color-gray-3;
  color: $color-black-4;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.28px;
  cursor: pointer;
  .name {
    margin: 0 4px;
  }
}
::v-deep .el-dropdown-menu {
  width: 155px !important;
  padding-bottom: 12px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 #2636990f;
  .el-dropdown-menu__item {
    span {
      display: flex;
      align-items: center;
      height: 44px;
      line-height: 40px;
      padding: 0 10px;
      font-weight: 600;
      font-size: 16px;
      color: $color-black;
      border-bottom: 0.4px solid #e1e2e6;
    }
  }
}
</style>
