export const globalMessageEn = {
  search_placeholder: "Search ...",
  delete_success: "Deleted successfully",
  delete_failed: "Failed to delete",
  duplicate_success: "Duplicated successfully",
  duplicate_failed: "Failed to duplicate",
  create_success: "Created successfully",
  create_failed: "Failed to create",
  update_success: "Updated successfully",
  update_failed: "Failed to update",
  upload_success: "Uploaded successfully",
  upload_failed: "Failed to upload",
  unsave: "Unsave",
};

export default globalMessageEn;
